<template>
    <PegasusHeading>Rendezvényeim</PegasusHeading>

    <PegasusPanel class="mb-2" header="Aktív rendezvények">
        <PegasusTable
            :data="active" :per-page="10"
            :row-variant="rowClass"
            empty-text="Nincsen aktív rendezvényed"
        >
            <Column :field="(item: Event) => '#' + item.id" header="ID"></Column>

            <Column field="Név" header="Név"></Column>

            <Column :field="event => locationStore.getDisplayLocations(event)" header="Helyszín"></Column>

            <Column field="date" header="Dátum"></Column>

            <Column header="Státusz">
                <template #body="{data}">
                    <span v-if="checks[data.id]">
                        <EventChecksBar :checks="checks[data.id]"></EventChecksBar>
                    </span>

                    <span v-else>
                        <i class="fas fa-spin fa-spinner"></i>
                    </span>
                </template>
            </Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton :params="{event: data}" to="events.edit" variant="success">
                        <i class="fas fa-eye"></i> Megnyitás
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>

    <PegasusPanel header="Archív rendezvények">
        <PegasusTable
            :data="archive" :per-page="5"
            :row-variant="rowClass"
            empty-text="Nincsen archív rendezvényed"
        >
            <Column :field="(item: Event) => '#' + item.id" header="ID"></Column>

            <Column field="name" header="Név" sortable></Column>

            <Column :field="event => locationStore.getDisplayLocations(event)" header="Helyszín"></Column>

            <Column field="date" header="Dátum" sortable></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton :params="{event: data}" to="events.edit" variant="success">
                        <i class="fas fa-eye"></i> Megnyitás
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<title>Saját rendezvényeim</title>

<script lang="ts" setup>
import {Checks} from "@Models/events/Checks";
import Event from "@Models/events/Event";
import {each, partition} from "lodash-es";
import {useAuthStore} from "@/stores/auth";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import EventChecksBar from "@Components/events/EventChecksBar.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';

const user = useAuthStore().requireUser();

const locationStore = await useEventLocations().promise.value;

const events = await Event.getAllUserEvents(user.value.id);

const [active, archive] = partition(events, event => event.isActive());

const checks: Record<number, Checks> = {};

each(active, event => {
    Checks.getForEvent(event.id).then(response => {
        checks[event.id] = response;
    });
});

function rowClass(event: Event) {
    if (!event) {
        return null;
    }

    if (event.decision) {
        return 'success';
    }
    if (event.finalized) {
        return 'warning';
    }

    return null;
}
</script>
