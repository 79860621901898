<template>
    <h3 class="m-2 text-center">Bejelentés visszaigazolásra váró rendezvények</h3>

    <PegasusTable :data="waitingAck">
        <Column :field="item => '#' + item.id" header="ID"></Column>

        <Column header="Név" sortable>
            <template #body="{data}">
                <a href="javascript:void(0)" @click="dialog.open(ShortEventDialog, {eventData: data})">
                    {{ data.name }}
                </a>
            </template>
        </Column>

        <Column field="date" header="Időpont"></Column>

        <Column field="participants" header="Létszám"></Column>

        <Column field="outsiders" header="Külsősök száma"></Column>

        <Column :field="event => locationStore.getDisplayLocations(event)" header="Terem"></Column>

        <Column :field="event => closureStore.displayClosures(event)" header="Lezáratás"></Column>

        <Column :field="item => item.hasBar() ? 'Igen' : 'Nem'" header="Pult"></Column>

        <Column field="billing" header="Számlázás">
            <template #body="{data}">
                <SelectField
                    v-model="data.billing"
                    :options="billingOptions"
                    :schema="EventsEventUpdateSchema.billing"
                    without-group
                ></SelectField>
            </template>
        </Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton variant="success" @click="reportAck(data)">
                    Visszaigazolás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import SelectField from "@Components/base/form/types/SelectField.vue";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {EventsEventUpdateSchema} from '@/api/models';
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';
import {useEventClosureTypes} from '@/queries/events/useEventClosureTypes';

const {waitingAck} = defineProps<{
    waitingAck: Record<number, Event>
}>();

const dialog = useDialog();

const locationStore = await useEventLocations().promise.value;

const closureStore = await useEventClosureTypes().promise.value;

const billingOptions = [
    {label: '-', value: ''},
    {label: 'SVIE', value: 'SVIE'},
    {label: 'MŰHASZ', value: 'MŰHASZ'},
];

function reportAck(event: Event) {
    let request = event.reportAck().then(() => {
        delete waitingAck[event.id];
    });

    toast.loading(request, 'Visszaigazolás mentése');
}
</script>
