<template>
    <CheckboxMultipleField
        v-model="value"
        :inline="inline"
        :options="options"
        :schema="schema"
    ></CheckboxMultipleField>
</template>

<script lang="ts" setup>
import {useEventLocations} from '@/queries/events/useEventLocations';
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import CheckboxMultipleField from '@Components/base/form/types/CheckboxMultipleField.vue';
import {map} from 'lodash-es';

const value = defineModel<number[]>({required: true});

const {schema, inline = false} = defineProps<{
    schema: FieldSchema,
    inline?: boolean
}>();

const locations = await useEventLocations().promise.value;

const options = map(locations.getEnabledLocations(), location => ({
    value: location.id,
    label: location.longName,
}));
</script>
