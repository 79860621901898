<template>
    <PegasusPanel header="Tételek cimkék szerint">
        <PegasusTable :data="tagCounts" :row-variant="row => tags[row.tagId].primeSeverity">
            <Column header="Cimke">
                <template #body="{data}: {data: TagCount}">
                    <PegasusOverlay
                        :content="tags[data.tagId].description"
                        :header="tags[data.tagId].name"
                    >
                        <div class="d-flex p-1 align-items-center">
                            <i :class="tags[data.tagId].icon"></i>

                            <div class="ms-1">{{ tags[data.tagId].name }}</div>
                        </div>
                    </PegasusOverlay>
                </template>
            </Column>

            <Column :field="tagCount => tagCount.itemCount + ' tétel'" header="Tételek száma"></Column>

            <Column :field="tagCount => tagCount.sumPrice.huf()" header="Összérték"></Column>

            <Column header="Műveletek">
                <template #body="{data}: {data: TagCount}">
                    <PegasusButton
                        :hash="{...objectWithoutNulls(filter), tag: data.tagId}"
                        popover="Tételek"
                        size="sm"
                        to="inventory.items.filter"
                        variant="info"
                    >
                        <i class="fa fa-list"></i>
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import type {TagCount} from '@Models/inventory/InventoryGroupStatistics';
import type {ItemFilter} from '@Models/inventory/Item';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {objectWithoutNulls} from '@Utils/misc';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {useInventoryTags} from '@/queries/inventory/useInventoryTags';

const {tagCounts, filter} = defineProps<{
    tagCounts: Record<number, TagCount>,
    filter: ItemFilter
}>();

const {tags} = await useInventoryTags().promise.value;
</script>
