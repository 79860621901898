<template>
    <PegasusHeading>VIKÖ - {{ tender.displayName }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-3">
            <PegasusPanel header="Pályázat">
                <DateField
                    v-model="tender.begin"
                    :schema="VikoTenderUpdateSchema.begin"
                ></DateField>

                <DateField
                    v-model="tender.end"
                    :schema="VikoTenderUpdateSchema.end"
                ></DateField>

                <DatetimeField
                    v-model="tender.beginDrafting"
                    :schema="VikoTenderUpdateSchema.beginDrafting"
                ></DatetimeField>

                <DatetimeField
                    v-model="tender.endDrafting"
                    :schema="VikoTenderUpdateSchema.endDrafting"
                ></DatetimeField>

                <DatetimeField
                    v-model="tender.endAccepting"
                    :schema="VikoTenderUpdateSchema.endAccepting"
                ></DatetimeField>

                <hr>

                <SelectField
                    v-model="tender.status"
                    :options="dictNumberEnum(VikoTenderStatusValues)"
                    :schema="VikoTenderUpdateSchema.status"
                ></SelectField>

                <SwitchField
                    v-model="tender.isPublic"
                    :schema="VikoTenderUpdateSchema.isPublic"
                    inline
                ></SwitchField>

                <PegasusButton variant="success" @click="save">
                    Mentés
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-5">
            <PegasusPanel v-for="group in tender.reviewerGroups" :key="group.id" :header="group.name + ' bírálói csoport'" class="mb-2">
                <template #header>
                    {{ group.name }} bírálói csoport

                    <span class="float-end">
                        <PegasusButton size="xs" variant="danger" @click="removeReviewerGroup(group)">
                            <i class="fa fa-times"></i>
                        </PegasusButton>
                    </span>
                </template>

                <SelectField
                    v-model="group.reviewStage"
                    :options="dictNumberEnum(VikoReviewStageValues)"
                    :schema="VikoReviewerGroupUpdateSchema.reviewStage"
                    inline
                    label="Bírálási szakasz"
                    @change="updateReviewStage(group)"
                ></SelectField>

                <b>Bírálók:</b>
                <ul class="simple">
                    <li v-for="user in group.reviewers" :key="user.id">
                        {{ user.name }}
                        <PegasusButton size="xs" variant="danger" @click="removeUserFromGroup(group, user)">
                            <i class="fas fa-times"></i>
                        </PegasusButton>
                    </li>
                    <li>
                        <PegasusButton size="xs" variant="success" @click="addUserToGroup(group)">
                            <i class="fas fa-plus"></i>
                        </PegasusButton>
                    </li>
                </ul>
            </PegasusPanel>

            <PegasusButton variant="success" @click="newReviewerGroup">
                Új bírálói csoport
            </PegasusButton>
        </div>

        <div class="col-lg-4">
            <PegasusPanel header="Bírálás">
                <PegasusButton :params="{tender}" to="viko.admin.problems">
                    Problémás pályázatok
                </PegasusButton>

                <PegasusButton :params="{tender}" to="viko.admin.statistics">
                    Bírálói statisztika
                </PegasusButton>

                <hr>

                <h5>Moderátorok</h5>
                <ul class="simple">
                    <li v-for="user in tender.moderators" :key="user.id">{{ user.name }}
                        <PegasusButton size="xs" variant="danger" @click="removeModerator(user)">
                            <i class="fas fa-times"></i>
                        </PegasusButton>
                    </li>
                </ul>

                <PegasusButton variant="success" @click="addModerator">
                    Új moderátor
                </PegasusButton>
                <br/>

                <hr>

                <PegasusButton :params="{tender}" to="viko.admin.ignore">
                    KSZK pályázatok
                </PegasusButton>

                <PegasusButton variant="primary" @click="exportIgnoredResults">
                    KSZK eredmények exportálása
                </PegasusButton>

                <br>

                <PegasusButton variant="primary" @click="exportResults">
                    Eredmények exportálása
                </PegasusButton>
                <PegasusButton variant="primary" @click="exportApplications">
                    Pályázatok exportálása
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>

    <PegasusButton to="viko.admin.index" variant="primary">
        <i class="fas fa-fast-backward"></i> Vissza
    </PegasusButton>
</template>

<title>VIKÖ pályázat adminisztráció</title>

<script lang="ts" setup>
import {Tender} from "@Models/viko/Tender";
import {User} from "@Models/base/User";
import {ReviewerGroup} from "@Models/viko/ReviewerGroup";
import {ref} from "vue";
import toast from "@Utils/toast";
import {
    VikoReviewerGroupUpdateSchema,
    VikoReviewStageValues,
    VikoTenderStatusValues,
    VikoTenderUpdateSchema
} from "@/api/models";
import DateField from "@Components/base/form/types/DateField.vue";
import DatetimeField from "@Components/base/form/types/DatetimeField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useDialog} from '@Utils/dialog';
import ReviewerAddingDialog from '@Components/viko/tender/ReviewerAddingDialog.vue';
import ReviewerGroupAddDialog from '@Components/viko/tender/ReviewerGroupAddDialog.vue';
import {useAuthStore} from '@/stores/auth';
import ModeratorAddingDialog from '@Components/viko/tender/ModeratorAddingDialog.vue';
import SwitchField from '@Components/base/form/types/SwitchField.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {dictNumberEnum} from '@Models/Utils';

const {tenderId} = defineProps<{
    tenderId: number
}>();

useAuthStore().requireUser();

const dialog = useDialog();

const tender = ref(await Tender.getById(tenderId));

function save() {
    let request = tender.value.update();

    toast.loading(request);
}

function exportResults() {
    let request = tender.value.exportResults().then(file => file.download());

    toast.loading(request, 'Exportálás');
}

function exportIgnoredResults() {
    let request = tender.value.exportIgnoredResults().then(file => file.download());

    toast.loading(request, 'Exportálás');
}

function exportApplications() {
    let request = tender.value.exportApplications().then(file => file.download());

    toast.loading(request, 'Exportálás');
}

function addModerator() {
    dialog.open(ModeratorAddingDialog, {tender});
}

async function removeModerator(user: User) {
    if (!await toast.confirm('Biztosan ki akarod törölni ezt a moderátort?')) {
        return;
    }

    const request = tender.value.removeModerator(user.id);

    toast.loading(request, 'Eltávolítás');
}

function addUserToGroup(group: ReviewerGroup) {
    dialog.open(ReviewerAddingDialog, {group});
}

async function removeUserFromGroup(group: ReviewerGroup, user: User) {
    if (!await toast.confirm('Biztosan ki akarod törölni ezt a bírálót?')) {
        return;
    }

    let request = group.removeUser(user.id);

    toast.loading(request, 'Eltávolítás');
}

function newReviewerGroup() {
    dialog.open(ReviewerGroupAddDialog, {tender});
}

function updateReviewStage(group: ReviewerGroup) {
    const request = group.update();

    toast.loading(request);
}

async function removeReviewerGroup(group: ReviewerGroup) {
    if (!await toast.confirm('Biztosan ki akarod törölni ezt a bírálói csoportot?')) {
        return;
    }

    let request = group.delete().then(() => {
        if (tender.value.reviewerGroups === null) {
            throw new Error('Something is wrong');
        }

        delete tender.value.reviewerGroups[group.id];
    });

    toast.loading(request, 'Törlés');
}
</script>
