import {useQuery} from '@tanstack/vue-query';
import {MainApi} from '@/api/apis/index';
import {Group} from '@Models/base/Group';
import {filter, map, pickBy, sortBy} from 'lodash-es';

export interface GroupHolder {
    group: Group;

    children?: GroupHolder[];
}

class GroupsStore {
    constructor(
        public readonly groups: Record<number, Group>,
    ) {
    }

    public getResorts() {
        return filter(this.groups, group => group.isResort() && group.active);
    }

    public getByResort(resort: Group): Record<number, Group> {
        return pickBy(this.groups, group => group.resort === resort.id);
    }

    public getCalculated(): GroupHolder[] {
        const resorts = this.getResorts();

        const others = new Group();
        others.id = 15;
        others.name = 'Egyéb körök';
        others.shortName = 'Egyéb';
        others.active = true;
        others.resort = null;
        resorts.push(others);

        const result = map(resorts, resort => {
            const groups = [{group: resort}].concat(
                map(filter(this.getByResort(resort), group => group.active), group => {
                    return {
                        group: group,
                    };
                }),
            );

            return {
                group: resort,
                children: sortBy(groups, group => group.group.name),
            };
        });

        return sortBy(result, holder => holder.group.name);
    }
}

export function useGroups() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['mapBuilding'],
        queryFn: async () => {
            const {data} = await MainApi.groupsIndex();

            return new GroupsStore(
                data.groups
                    .map(group => Group.newSingle(group, Group.parseResponse))
                    .keyBy(group => group.id),
            );
        },
    });
}
