<template>
    <PegasusHeading>Rendezvények admin</PegasusHeading>

    <div class="m-1 text-center">
        <PegasusButton to="events.admin.todo" variant="success">
            TODO
        </PegasusButton>

        <PegasusButton :params="{year}" to="events.admin.calendar" variant="primary">
            Rendezvény naptár
        </PegasusButton>

        <PegasusButton to="events.admin.emails" variant="info">
            E-mail sablonok
        </PegasusButton>

        <PegasusButton
            v-if="user.hasPermission(BaseGlobalPermission.ADMIN_ACL)"
            to="events.admin.closureTypes.index"
            variant="primary"
        >
            Lezáratás típusok
        </PegasusButton>
    </div>

    <div class="row mb-3">
        <div class="col-sm-6">
            <PegasusPanel header="Beállítások">
                <h4 class="m-2">Rendezvényhelyszínek</h4>

                <PegasusTable :data="locationStore.locations">
                    <Column field="name" header="Név"></Column>

                    <Column :field="item => item.enabled ? 'Igen' : 'Nem'" header="Aktív"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Location}">
                            <PegasusButton v-if="data.enabled" variant="danger" @click="disableLocation(data)">
                                Letiltás
                            </PegasusButton>

                            <PegasusButton v-else variant="success" @click="enableLocation(data)">
                                Engedélyezés
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-sm-6">
            <PegasusPanel header="Aktuális főrendezői tanfolyamok">
                <PegasusTable :data="trainings">
                    <Column :field="item => item.date.datetime()" header="Dátum"></Column>

                    <Column field="location.name" header="Helyszín"></Column>

                    <Column :field="item => item.active ? 'Igen' : 'Nem'" header="Aktív"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Training}">
                            <PegasusButton :params="{training: data}" to="events.trainings.edit" variant="primary">
                                Szerkesztés
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton variant="success" @click="createTraining">
                    Új létrehozása
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <PegasusPanel header="Következő 30 nap rendezvényei">
                <PegasusTable :data="next" :no-wrap="false" search>
                    <Column field="name" header="Név" sortable>
                        <template #body="{data}: {data: Event}">
                            <a @click="dialog.open(ShortEventDialog, {eventData: data})">{{ data.name }}</a>
                        </template>
                    </Column>

                    <Column body-class="text-nowrap" field="reservationDate" header="Foglalás" sortable></Column>

                    <Column body-class="text-nowrap" field="date" header="Dátum" sortable></Column>

                    <Column :field="item => item.permitNeeded ? 'Igen' : 'Nem'" header="ÜZO"></Column>

                    <Column :field="event => locationStore.getDisplayLocations(event)" header="Helyszín"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Event}">
                            <PegasusButton :params="{event: data}" to="events.edit" variant="primary">
                                Szerkesztés
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Rendezvények admin</title>

<script lang="ts" setup>
import Event from '@Models/events/Event';
import {Training} from '@Models/events/Training';
import {Location} from '@Models/events/Location';
import {DateTime} from '@Utils/DateTime';
import {promiseAll} from '@Utils/misc';
import {ref} from 'vue';
import toast from '@Utils/toast';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import {useAuthStore} from '@/stores/auth';
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import TrainingEditorDialog from '@Components/events/training/TrainingEditorDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {BaseGlobalPermission} from '@/api/models';
import {useEventLocations} from '@/queries/events/useEventLocations';

const user = useAuthStore().requireUser();

const locationStore = await useEventLocations().promise.value;

const fetched = await promiseAll({
    trainings: Training.getAll(),
    next: Event.getNext(),
});

const trainings = ref(fetched.trainings);
const next = ref(fetched.next);

const dialog = useDialog();

const year = DateTime.now().year;

function createTraining() {
    const training = Training.createNew(locationStore.getDefaultLocation());

    dialog.open(TrainingEditorDialog, {training}).then(result => {
        if (result) {
            trainings.value[training.id] = training;
        }
    });
}

function enableLocation(location: Location) {
    location.enabled = true;
    const request = location.update();
    toast.loading(request);
}

function disableLocation(location: Location) {
    location.enabled = false;
    const request = location.update();
    toast.loading(request);
}
</script>
