import {Base} from "@Models/Base";
import {Tender} from "@Models/viko/Tender";
import type {VikoTenderSummaryResponse} from "@/api/api";
import {VikoApi} from "@/api/api";

export class TenderSummary extends Base {
    tender: Tender;

    applicationsCount: number;

    reportsCount: number;

    avgCharacterCount: number;

    static getAll(): Promise<TenderSummary[]> {
        return VikoApi.statisticsOverviewStatistics().then(response => {
            return response.data.tenders.map(data => TenderSummary.newSingle(data, TenderSummary.parseResponse));
        });
    }

    static getById(id: number): Promise<TenderSummary> {
        return VikoApi.tendersSummary(id).then(response => {
            return TenderSummary.newSingle(response.data, TenderSummary.parseResponse);
        });
    }

    static parseResponse(summary: TenderSummary, data: VikoTenderSummaryResponse): TenderSummary {
        summary.tender = Tender.newSingle(data.tender, Tender.parseExtendedResponse);
        summary.applicationsCount = data.applicationsCount;
        summary.reportsCount = data.reportsCount;
        summary.avgCharacterCount = data.avgCharacterCount;

        return summary;
    }
}
