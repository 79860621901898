<template>
    <span class="fw-bold me-2">Cimkék</span>
    <PegasusHelp title="Cimkék" wide>
        <p v-for="flag in data" :key="flag.id">
            <b>{{ flag.name }}</b>
            {{ flag.description }}
        </p>
    </PegasusHelp>

    <MultiSelect
        v-model="value"
        :disabled="disabled"
        :loading="isLoading"
        :options="data"
        class="my-2 w-100"
        data-key="id"
        display="chip"
        option-label="name"
        option-value="id"
        @change="update"
    ></MultiSelect>
</template>

<script lang="ts" setup>
import {some} from 'lodash-es';
import type {MultiSelectChangeEvent} from 'primevue/multiselect';
import MultiSelect from 'primevue/multiselect';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import {useMoneyFlags} from '@/queries/money/useMoneyFlags';
import type {Item} from '@Models/money/Item';
import {computed} from 'vue';

const value = defineModel<number[]>({required: true});
const isCamp = defineModel<boolean>('isCamp', {required: true});

const {disabled = false, item} = defineProps<{
    disabled?: boolean;
    item: Item,
}>();

function update(value: MultiSelectChangeEvent) {
    if (!campFlag.value) { // TODO should log this
        return;
    }

    isCamp.value = some(value.value, id => id === campFlag.value) && item.type === 'k';
}

const {data, isLoading} = useMoneyFlags();

const campFlag = computed(() => data.value?.find(flag => flag.name === 'szállás')?.id);
</script>
