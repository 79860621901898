<template>
    <PegasusHeading>Költségvetési tétel - {{ item.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Tétel adatai">
                <ItemDetails :form="itemForm" :item="item" :permissions="permissions"></ItemDetails>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Altételek">
                <PegasusTable :data="item.spendingItems">
                    <Column
                        :field="(data: SpendingItem) => MoneyBudgetLocationValues[data.budgetLocation]"
                        header="Gazdasági láb"
                    ></Column>

                    <Column :field="(data: SpendingItem) => data.money.huf()" header="Összeg"></Column>

                    <Column field="statusText" header="Állapot"></Column>

                    <Column header="Létrehozva">
                        <template #body="{data}: {data: SpendingItem}">
                            <PegasusLink :params="{item: data.itemId, spendingItem: data}" to="money.spendingItem.show">
                                {{ data.createdAt.datetime() }}
                            </PegasusLink>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton v-if="canEdit" variant="success" @click="newSpendingItem">
                    <i class="fa fa-plus"></i> Új altétel
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költségvetési tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {Item} from '@Models/money/Item';
import ItemDetails from '@Components/money/item/ItemDetails.vue';
import {computed, ref} from 'vue';
import {useAuthStore} from '@/stores/auth';
import type {KprPermissions} from '@Models/rvt/kpr/Application';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import type {SpendingItem} from '@Models/money/SpendingItem';
import PegasusLink from '@Components/base/PegasusLink.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {BaseGroupSpecificPermission, MoneyBudgetLocationValues} from '@/api/models';
import type {FormRef} from '@/vue';
import {useDialog} from '@Utils/dialog';
import NewSpendingItem from '@Components/money/item/dialogs/NewSpendingItem.vue';

const {itemId} = defineProps<{
    itemId: number
}>();

const dialog = useDialog();

const user = useAuthStore().requireUser();

const item = await Item.getById(itemId);

const itemForm = ref<FormRef>(null);

const canEdit = user.value.hasGroupPermission(item.groupId, BaseGroupSpecificPermission.MANAGE_MONEY);

const permissions = computed((): KprPermissions => {
    return {
        canConfidential: false,
        canFinalize: false,
        canDecide: false,
        canEdit: false,
    };
});

function newSpendingItem() {
    dialog.open(NewSpendingItem, {item});
}
</script>
