import {BaseEntity} from '@Models/BaseEntity';
import {DateTime} from '@Utils/DateTime';
import {MoneyApi, type MoneySpendingPeriodStore, type RvtSpendingPeriodResponse} from '@/api/api';
import type {FormRef} from '@/vue';
import {Budget} from '@Models/money/Budget';

export class SpendingPeriod extends BaseEntity {
    from: DateTime;

    to: DateTime;

    constructor(data: RvtSpendingPeriodResponse) {
        super();

        SpendingPeriod.parseResponse(this, data);
    }

    static parseResponse(period: SpendingPeriod, data: RvtSpendingPeriodResponse): SpendingPeriod {
        period.id = data.id;
        period.from = data.from;
        period.to = data.to;

        return period;
    }

    get name(): string {
        return 'Gazdasági év ' + this.from.year;
    }

    get year(): number {
        return this.from.year;
    }

    get dates(): string {
        return this.from.datetime() + ' - ' + this.to.datetime();
    }

    public isClaimable(): boolean {
        return this.from.isPast() && this.to.isFuture();
    }

    public static store(data: {
        from: DateTime,
        to: DateTime,
    }, form: FormRef): Promise<SpendingPeriod> {
        const request: MoneySpendingPeriodStore = {
            from: data.from,
            to: data.to,
        };

        return MoneyApi.spendingPeriodsStore(request, {form}).then(response => {
            return new SpendingPeriod(response.data);
        });
    }

    public getBudget(): Promise<Budget> {
        return Budget.getBySpendingPeriod(this.id);
    }
}
