/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsLocationResponse } from './EventsLocationResponse';
    import {
    EventsLocationResponseFromJSON,
    EventsLocationResponseFromJSONTyped,
    EventsLocationResponseToJSON,
    EventsLocationResponseCreateEmpty,
    } from './EventsLocationResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsLocationListResponse {
  locations: Array<EventsLocationResponse>;
}

export const EventsLocationListResponseSchema = {
  'locations': {
    name: 'locations',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsLocationListResponse(value: object): value is EventsLocationListResponse {
let isInstance = true;
    isInstance = isInstance && "locations" in value;

return isInstance;
}

export function EventsLocationListResponseFromJSON(json: any): EventsLocationListResponse {
return EventsLocationListResponseFromJSONTyped(json, false);
}

export function EventsLocationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsLocationListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'locations': ((json['locations'] as Array<any>).map(EventsLocationResponseFromJSON)),
    };
  }

  export function EventsLocationListResponseToJSON(value?: EventsLocationListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'locations': ((value.locations as Array<any>).map(EventsLocationResponseToJSON)),
    };
  }

  export function EventsLocationListResponseCreateEmpty(): EventsLocationListResponse {
  return {
      'locations': [],
  };
  }

