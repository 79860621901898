<template>
    <PegasusPanel header="Költési időszakok">
        <ul>
            <PegasusButton
                v-for="period in ordered"
                :key="period.id"
                :params="{period: period}"
                class="my-1"
                to="rvt.spendingPeriod.show"
            >
                {{ period.name }}
            </PegasusButton>
        </ul>
    </PegasusPanel>
</template>

<title>Költési időszakok</title>

<script lang="ts" setup>
import {orderBy} from 'lodash-es';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {useAuthStore} from '@/stores/auth';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

useAuthStore().requireUser();

const {periods} = await useSpendingPeriods().promise.value;

const ordered = orderBy(periods, period => period.from.year, 'desc');
</script>
