<template>
    <nav class="main-header navbar navbar-expand-lg navbar-dark navbar-purple user-select-none">
        <div class="container">
            <slot name="button">
                <PegasusLink class="navbar-brand" to="rvt.index">
                    <span class="brand-text fw-light">SCH Közélet</span>
                </PegasusLink>
            </slot>

            <button
                id="hamburger-icon"
                aria-controls="main-navbar"
                aria-expanded="false"
                aria-label="Menu"
                class="navbar-toggler"
                data-bs-target="#main-navbar"
                data-bs-toggle="collapse"
                type="button"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div id="main-navbar" class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto">
                    <slot name="navbar-extra"></slot>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
                            <i class="nav-icon fas fa-pager"></i>
                            Oldalak
                        </a>
                        <ul class="dropdown-menu border-0 shadow">
                            <li>
                                <PegasusLink class="dropdown-item" to="rvt.index">
                                    <img alt="rvt.sch" height="20px" src="/src/assets/images/logo/link/rvt.svg"/>
                                </PegasusLink>
                            </li>
                            <li>
                                <PegasusLink class="dropdown-item" to="mentors.index">
                                    <img alt="ugyeletes.sch" height="20px" src="/src/assets/images/logo/link/mentors.svg"/>
                                </PegasusLink>
                            </li>
                            <li>
                                <PegasusLink class="dropdown-item" to="viko.index">
                                    <img alt="viko.sch" height="20px" src="/src/assets/images/logo/link/viko.svg"/>
                                </PegasusLink>
                            </li>
                            <li>
                                <PegasusLink class="dropdown-item" to="events.index">
                                    <img alt="bejelentes.sch" height="20px" src="/src/assets/images/logo/link/events.svg"/>
                                </PegasusLink>
                            </li>
                            <li>
                                <PegasusLink class="dropdown-item" to="inventory.index">
                                    <img alt="leltar.sch" height="20px" src="/src/assets/images/logo/link/inventory.svg"/>
                                </PegasusLink>
                            </li>
                        </ul>
                    </li>

                    <li v-if="auth.user && hasAdmin()" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            Admin
                        </a>
                        <ul class="dropdown-menu border-0 shadow">
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.ADMIN_ACL)">
                                <PegasusLink class="dropdown-item" to="rvt.admin.system">
                                    <i class="fas fa-fw fa-gears nav-icon"></i> Rendszer
                                </PegasusLink>
                                <PegasusLink class="dropdown-item" to="rvt.admin.users.index">
                                    <i class="fas fa-fw fa-users nav-icon"></i> Felhasználók
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.KPR_TENDER)">
                                <PegasusLink class="dropdown-item" to="rvt.admin.index">
                                    <i class="fas fa-fw fa-house nav-icon"></i> RVT
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.GROUPS_CAPABILITIES)">
                                <PegasusLink class="dropdown-item" to="rvt.admin.groups">
                                    <i class="fas fa-fw fa-globe nav-icon"></i> Körök
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.ADMIN_ACL)">
                                <PegasusLink class="dropdown-item" to="money.admin.index">
                                    <i class="fas fa-fw fa-money-bill nav-icon"></i> Gazdaság
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.VIKO_TENDER)">
                                <PegasusLink class="dropdown-item" to="viko.admin.index">
                                    <i class="fas fa-fw fa-address-book nav-icon"></i> VIKÖ
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.MENTORS_EDIT)">
                                <PegasusLink class="dropdown-item" to="mentors.admin.index">
                                    <i class="fas fa-fw fa-hands-helping nav-icon"></i> Mentorok
                                </PegasusLink>
                            </li>
                            <li v-if="auth.user.hasPermission(BaseGlobalPermission.EVENTS_VIEW)">
                                <PegasusLink class="dropdown-item" to="events.admin.index">
                                    <i class="fas fa-fw fa-calendar-alt nav-icon"></i> Események
                                </PegasusLink>
                            </li>
                            <li v-if="hasInventoryAdmin()">
                                <PegasusLink class="dropdown-item" to="inventory.admin.index">
                                    <i class="fas fa-fw fa-book nav-icon"></i> Leltár
                                </PegasusLink>
                            </li>
                        </ul>
                    </li>

                    <li v-if="auth.user" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle border rounded" data-bs-toggle="dropdown" href="#">
                            <i class="fa fa-user me-1"></i>
                            {{ auth.user.name }}
                        </a>
                        <ul class="dropdown-menu border-0 shadow">
                            <li>
                                <a class="dropdown-item" @click="dialog.open(UserDialog);">
                                    <i class="fa fa-fw fa-user"></i> Profil
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" @click="auth.logout()">
                                    <i class="fa fa-fw fa-sign-out"></i> Kijelentkezés
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li v-else class="nav-item">
                        <a class="nav-link" @click="auth.redirect()">
                            Bejelentkezés
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import {BaseGlobalPermission} from "@/api/models";
import PegasusLink from "@Components/base/PegasusLink.vue";
import {useDialog} from '@Utils/dialog';
import UserDialog from '@Components/dialogs/UserDialog.vue';

const auth = useAuthStore();

const dialog = useDialog();

function hasAdmin(): boolean {
    return auth.user?.hasPermission([
        BaseGlobalPermission.ADMIN_ACL,
        BaseGlobalPermission.GROUPS_CAPABILITIES,
        BaseGlobalPermission.VIKO_TENDER,
        BaseGlobalPermission.MENTORS_EDIT,
        BaseGlobalPermission.EVENTS_VIEW,
        BaseGlobalPermission.INVENTORY_ADMIN,
    ]) || hasInventoryAdmin();
}

function hasInventoryAdmin(): boolean {
    return auth.user?.hasPermission([
        BaseGlobalPermission.INVENTORY_ADMIN,
        BaseGlobalPermission.INVENTORY_EDIT,
        BaseGlobalPermission.INVENTORY_READ,
    ]) ?? false;
}
</script>
