import {Base} from '@Models/Base';
import type {InventoryOwnerSummaryResponse} from '@/api/api';
import {InventoryApi} from '@/api/api';

export class OwnerSummary extends Base {
    ownerId: number;

    itemCount: number;

    priceTotal: number;

    static getById(ownerId: number): Promise<OwnerSummary> {
        return InventoryApi.ownersSummary(ownerId).then(response => {
            return OwnerSummary.newSingle(response.data, OwnerSummary.parseResponse);
        });
    }

    static parseResponse(summary: OwnerSummary, data: InventoryOwnerSummaryResponse): OwnerSummary {
        summary.ownerId = data.ownerId;
        summary.itemCount = data.itemCount;
        summary.priceTotal = data.priceTotal;

        return summary;
    }
}
