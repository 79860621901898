import {BaseEntity} from '@Models/BaseEntity';
import {Category} from '@Models/rvt/kpr/Category';
import {File} from '@Models/File';
import {
    KprApi,
    KprCategoryStatus,
    type KprTenderResponse,
    type KprTenderStore,
    type KprTenderUpdate,
    type KprUpdateRules,
} from '@/api/api';
import {groupBy, join, map, some} from 'lodash-es';
import type {FormRef} from '@/vue';

export class Tender extends BaseEntity {
    displayName: string;

    spendingPeriodId: number;

    categories: Record<number, Category>;

    claimsBudgetItem: number | null;

    amendable: boolean;

    constructor(data: KprTenderResponse) {
        super();

        Tender.parseResponse(this, data);
    }

    static parseResponse(tender: Tender, data: KprTenderResponse): Tender {
        tender.id = data.id;
        tender.displayName = data.displayName;
        tender.spendingPeriodId = data.spendingPeriodId;
        tender.categories = Category.newRecords(data.categories, Category.parseResponse);
        tender.claimsBudgetItem = data.claimsBudgetItemId;
        tender.amendable = data.amendable;

        return tender;
    }

    public static store(data: {
        spendingPeriodId: number,
        categories: Category[]
    }, form: FormRef): Promise<Tender> {
        const request: KprTenderStore = {
            spendingPeriod: data.spendingPeriodId,
            categories: map(data.categories, category => {
                return {
                    name: category.name,
                    shortName: category.shortName,
                    status: category.status,
                    endDrafting: category.endDrafting,
                    requireContributions: category.requireContributions,
                };
            }),
        };

        return KprApi.tenderStore(request, {form}).then(response => {
            return new Tender(response.data);
        });
    }

    public update(): Promise<void> {
        const data: KprTenderUpdate = {
            claimsBudgetItem: this.claimsBudgetItem,
            categories: map(this.categories, category => {
                return {
                    category: category.id,
                    status: category.status,
                    endDrafting: category.endDrafting,
                    budget: category.budget,
                    budgetItem: category.budgetItemId,
                };
            }),
        };

        return KprApi.tenderUpdate(this.id, data).then(response => {
            Tender.parseResponse(this, response.data);
        });
    }

    public sgrExport(): Promise<File> {
        return KprApi.tenderExportSgr(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public uploadRules(file: File): Promise<void> {
        const data: KprUpdateRules = {
            rules: file.getAsDomFile(),
        };

        return KprApi.tenderEditRules(this.id, data).then();
    }

    get status(): string {
        const categoriesByStatus = groupBy(this.categories, category => category.statusText);

        return join(
            map(categoriesByStatus, (categories, status) => status + ' (' + categories.map(category => category.shortName.toUpperCase()).join(', ') + ')'),
            ', ',
        );
    }

    public isActive(): boolean {
        return some(this.categories, category => category.status !== KprCategoryStatus.ARCHIVED);
    }

    public hasDrafting(): boolean {
        return some(this.categories, (category: Category) => category.isInDrafting());
    }
}
