<template>
    <SelectField
        v-model="value"
        :options="dictObject(owners, owner => owner.name)"
        :schema="InventoryCreateExistingItemSchema.owner"
        clearable
    ></SelectField>
</template>

<script lang="ts" setup>
import {dictObject} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {InventoryCreateExistingItemSchema} from '@/api/models/index';
import type {Owner} from '@Models/inventory/Owner';
import {useInventoryOwners} from '@/queries/inventory/useInventoryOwners';

const value = defineModel<Owner | undefined>({required: true});

const {initialValue} = defineProps<{
    initialValue?: number,
}>();

const owners = await useInventoryOwners().promise.value;

if (initialValue !== undefined) {
    const initialOwner = owners[initialValue];
    if (initialOwner) {
        value.value = initialOwner;
    }
}
</script>
