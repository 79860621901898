<template>
    <PegasusModal :header="serviceConfig.name" :width="wide ? '1100px' : '1000px'">
        <PegasusForm ref="form">
            <div class="row">
                <div :class="wide ? 'col-lg-7' : 'col-lg-6'">
                    <Card>
                        <template #title>
                            Adatok
                        </template>

                        <template #content>
                            <PegasusForm part="data">
                                <component
                                    :is="serviceComponents[service.type]"
                                    v-if="service"
                                    v-model:data="local"
                                    :service="service"
                                ></component>
                            </PegasusForm>
                        </template>
                    </Card>
                </div>

                <div :class="wide ? 'col-lg-5' : 'col-lg-6'">
                    <Card>
                        <template #title>
                            Státusz
                        </template>

                        <template #content>
                            <ServiceStatus :deadline="deadline" :service="service"></ServiceStatus>

                            <TextareaField
                                v-model="comment"
                                :disabled="!service.canEdit"
                                :schema="EventsServiceStoreSchema.comment"
                            ></TextareaField>

                            <div class="d-flex align-content-center">
                                <PegasusButton v-if="service.canEdit" block variant="success" @click="updateService">
                                    <i class="fas fa-save"></i> Mentés
                                </PegasusButton>

                                <PegasusButton v-if="service.id && service.canEdit" block variant="primary" @click="sendService">
                                    <i class="fas fa-paper-plane"></i> Véglegesítés
                                </PegasusButton>

                                <PegasusButton v-if="service.id && service.canEdit" block variant="danger" @click="deleteService">
                                    <i class="fas fa-trash"></i> Törlés
                                </PegasusButton>
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {Service} from '@Models/events/Service';
import Event from '@Models/events/Event';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {type EventsServiceResponseDataSimple, EventsServiceStoreSchema, EventsServiceType} from '@/api/models';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import type {FormRef} from '@/vue';
import {computed, ref} from 'vue';
import toast from '@Utils/toast';
import serviceComponents from "@Components/events/services";
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import ServiceStatus from '@Components/events/services/ServiceStatus.vue';
import Card from "primevue/card";
import type {DateTime} from '@Utils/DateTime';
import {useReturn} from '@Utils/dialog';
import {useEventServiceConfigs} from '@/queries/events/useEventServiceConfigs';

const {event, service, deadlines} = defineProps<{
    service: Service,
    event: Event,
    deadlines: Record<EventsServiceType, DateTime>
}>();

const emit = defineEmits<{
    check: [],
}>();

const services = await useEventServiceConfigs().promise.value;

const serviceConfig = services[service.type];

const comment = ref(service.comment);

const form = ref<FormRef>(null);

const local = ref<any>(service.data);

const deadline = computed(() => deadlines[service.type] ?? null);

const returnValue = useReturn();

const wide = computed(() => {
    return service.type === EventsServiceType.GUEST_LIST
        || service.type === EventsServiceType.WORKER_LIST;
});

function saveService(service: Service, data: EventsServiceResponseDataSimple, comment: string, form: FormRef) {
    if (event.services[service.type]) {
        return;
    }

    let request = service.store(data, comment, form).then(() => {
        event.services[service.type] = service;

        emit('check');
    });

    toast.loading(request);
}

function updateService() {
    if (!service.id) {
        return saveService(service, local.value, comment.value, form.value);
    }

    let request = service.update(local.value, comment.value, form.value)
        .then(() => emit('check'));

    toast.loading(request);
}

async function sendService() {
    if (!await toast.confirm('Biztosan véglegesíteni szeretnéd ezt az igényt?', 'Ezután már nem lesz lehetőséged módosítani')) {
        return;
    }

    let request = service.send(form.value).then(() => {
        emit('check');
    });

    toast.loading(request, 'Véglegesítés');
}

async function deleteService() {
    if (!await toast.confirm('Biztosan törölni szeretnéd ezt az igényt?')) {
        return;
    }

    let request = service.delete().then(() => {
        delete event.services[service.type];

        returnValue(false);
    });

    toast.loading(request, 'Törlés');
}
</script>
