import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {Tender} from "@Models/rvt/kpr/Tender";
import type {BaseExtendedGroupSummaryResponse} from "@/api/api";
import {map} from "lodash-es";
import {Claim} from '@Models/rvt/requests/Claim';
import {Amend} from '@Models/rvt/requests/Amend';
import Event from '@Models/events/Event';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';

export class ExtendedGroupSummary extends Base {
    group: Group;

    periods: {
        period: SpendingPeriod,
        waitingClaims: Record<number, Claim>,
        pendingClaims: Record<number, Claim>,
        tender: Tender | null,
        waitingAmends: Record<number, Amend>,
        pendingAmends: Record<number, Amend>,
    }[];

    viko: {
        name: string,
        waitingReports: number,
        doneReports: number,
    } | null = null;

    events: {
        drafting: Record<number, Event>,
        waiting: Record<number, Event>,
        future: Record<number, Event>,
    } | null = null;

    inventory: InventoryGroupSummary | null;

    static parseResponse(summary: ExtendedGroupSummary, data: BaseExtendedGroupSummaryResponse): ExtendedGroupSummary {
        summary.group = Group.getBySingleId(data.groupId);

        summary.periods = map(data.periods, periodData => {
            return {
                period: new SpendingPeriod(periodData.period),
                waitingClaims: Claim.newRecords(periodData.waitingClaims, Claim.parseResponse),
                pendingClaims: Claim.newRecords(periodData.pendingClaims, Claim.parseResponse),
                tender: periodData.tender ? new Tender(periodData.tender) : null,
                waitingAmends: Amend.newRecords(periodData.waitingAmends, Amend.parseResponse),
                pendingAmends: Amend.newRecords(periodData.pendingAmends, Amend.parseResponse),
            };
        });

        if (data.viko) {
            summary.viko = {
                name: data.viko.tenderName,
                waitingReports: data.viko.waitingReports,
                doneReports: data.viko.doneReports,
            };
        }

        if (data.events) {
            summary.events = {
                drafting: Event.newRecords(data.events.drafting, Event.parseExtendedResponse),
                waiting: Event.newRecords(data.events.waiting, Event.parseExtendedResponse),
                future: Event.newRecords(data.events.future, Event.parseExtendedResponse),
            };
        }

        if (data.inventory) {
            summary.inventory = InventoryGroupSummary.newSingle(data.inventory, InventoryGroupSummary.parseResponse);
        }

        return summary;
    }
}
