import {Settings} from 'luxon';

import {createApp} from 'vue';
import {createPinia} from 'pinia';
import {router} from './router';
import plugin from '@Utils/plugin';
import {Prime} from '@Utils/prime';

import './assets/app.scss';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/collapse.js';
import {ErrorHandler} from '@Utils/errorHandler';
import {initSentry} from '@Utils/sentry';
import {VueQueryPlugin} from '@tanstack/vue-query';
import App from '@/App.vue';

Settings.defaultLocale = 'hu';
Settings.throwOnInvalid = true;

const app = createApp(App);

ErrorHandler.installVue(app);

if (process.env.NODE_ENV === 'production') {
    initSentry(app);
}

const pinia = createPinia();
app.use(pinia);
app.use(VueQueryPlugin, {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                experimental_prefetchInRender: true, // eslint-disable-line camelcase -- TODO rename when possible
            },
        },
    },
});

plugin.install(app, router);

Prime.install(app);

plugin.init().then(() => {
    app.use(router);

    app.mount('#application');
});
