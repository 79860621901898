<template>
    <PegasusForm ref="form">
        <h4>Helyszín</h4>
        <dd>
            <LocationField
                v-model:identifier="training.locationId"
                :schema="EventsTrainingUpdateSchema.location"
            ></LocationField>
        </dd>

        <h4>Időpont</h4>
        <dd>
            <DatetimeField
                v-model="training.date"
                :schema="EventsTrainingUpdateSchema.date"
            ></DatetimeField>
        </dd>

        <h4>
            Érvényes
            <PegasusHelp title="Érvényesség">
                Az érvényes tanfolyamokon részt vevőknak van jogosultsága rendezvényt bejelenteni.
                Tehát egy új félévben az előző féléves tanfolyamoknak ki kell venni az érvényességét
            </PegasusHelp>
        </h4>
        <dd>
            <CheckboxField
                v-model="training.active"
                :schema="EventsTrainingUpdateSchema.active"
            ></CheckboxField>
        </dd>

        <PegasusButton variant="primary" @click="save">
            Mentés
        </PegasusButton>
    </PegasusForm>
</template>

<script lang="ts" setup>
import {Training} from '@Models/events/Training';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import CheckboxField from '@Components/base/form/types/CheckboxField.vue';
import toast from '@Utils/toast';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import {EventsTrainingUpdateSchema} from '@/api/models';
import type {FormRef} from '@/vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import {ref} from 'vue';
import LocationField from '@Components/events/locations/LocationField.vue';

const emit = defineEmits<{
    stored: []
}>();

const {training} = defineProps<{
    training: Training
}>();

const form = ref<FormRef>(null);

function save() {
    if (training.id) {
        const request = training.update(form.value);
        toast.loading(request);
    } else {
        let request = training.store(form.value).then(() => {
            emit('stored');
        });
        toast.loading(request);
    }
}
</script>
