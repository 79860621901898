import {Base} from '@Models/Base';
import {ItemGroup} from '@Models/inventory/ItemGroup';
import {Group} from '@Models/base/Group';
import {type BaseGroupSummaryInventoryResponse, InventoryApi} from '@/api/api';
import {filter, flatMapDeep, keyBy, map, toArray} from 'lodash-es';
import type {TagCount} from '@Models/inventory/InventoryGroupStatistics';

export class InventoryGroupSummary extends Base {
    group: Group;

    itemsWithoutGroup: number;

    tagCounts: Record<number, TagCount>;

    rootGroup: ItemGroup;

    static parseResponse(summary: InventoryGroupSummary, response: BaseGroupSummaryInventoryResponse): InventoryGroupSummary {
        summary.group = Group.getBySingleId(response.groupId);

        const tagCounts = map(response.tagCounts, tagCount => {
            return {
                ...tagCount,
                tagId: tagCount.countedById,
            };
        });
        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.countedById);
        summary.itemsWithoutGroup = response.itemsWithoutGroup;

        summary.rootGroup = ItemGroup.createDummyRoot(
            summary.group,
            ItemGroup.newRecords(response.itemGroups, ItemGroup.parseHierarchyResponse),
            summary.itemsWithoutGroup,
        );

        return summary;
    }

    static getByGroup(groupId: number): Promise<InventoryGroupSummary> {
        return InventoryApi.groupsSummary(groupId).then(response => {
            return InventoryGroupSummary.newSingle(response.data, this.parseResponse);
        });
    }

    public allItemGroups(): Record<number, ItemGroup> {
        return keyBy(
            flatMapDeep(
                this.rootGroup.children,
                itemGroup => toArray(itemGroup.children).concat([itemGroup]),
            ),
            itemGroup => itemGroup.id,
        );
    }

    public filterTagCounts(tagIds: number[]) {
        return filter(this.tagCounts, tagCount => tagIds.includes(tagCount.tagId));
    }
}
