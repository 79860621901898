<template>
    <h3 class="m-2 text-center">Bejelentésre váró rendezvények a következő 30 napban</h3>

    <PegasusTable :data="waitingReport">
        <Column :field="item => '#' + item.id" header="ID"></Column>

        <Column header="Név" sortable>
            <template #body="{data}: {data: Event}">
                <a href="javascript:void(0)" @click="dialog.open(ShortEventDialog, {eventData: data})">
                    {{ data.name }}
                </a>
            </template>
        </Column>

        <Column field="date" header="Időpont"></Column>

        <Column field="participants" header="Létszám"></Column>

        <Column field="outsiders" header="Külsősök száma"></Column>

        <Column :field="event => locationStore.getDisplayLocations(event)" header="Terem"></Column>

        <Column :field="event => closureStore.displayClosures(event)" header="Lezáratás"></Column>

        <Column :field="item => item.hasBar() ? 'Igen' : 'Nem'" header="Pult"></Column>

        <Column field="billing" header="Számlázás">
            <template #body="{data}">
                <SelectField
                    v-model="data.billing"
                    :options="dictEnum(EventsBillingValues)"
                    :schema="EventsEventUpdateSchema.billing"
                    without-group
                ></SelectField>
            </template>
        </Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <CheckboxField
                    v-model="reportingEvents[data.id]"
                    :schema="simpleCheckboxSchema('')"
                    without-group
                ></CheckboxField>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton variant="success" @click="download">
        Bejelentő letöltése
    </PegasusButton>

    <PegasusButton variant="success" @click="reportChecked">
        Kijelöltek készen
    </PegasusButton>

    <PegasusButton class="float-end" variant="primary" @click="checkAll">
        Összes kijelölése
    </PegasusButton>
</template>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import SelectField from "@Components/base/form/types/SelectField.vue";
import {EventReport} from "@Models/events/EventReport";
import {each, forEach} from "lodash-es";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {ref} from "vue";
import {EventsBilling, EventsBillingValues, EventsEventUpdateSchema} from "@/api/models";
import {dictEnum, simpleCheckboxSchema} from '@Models/Utils';
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';
import {useEventClosureTypes} from '@/queries/events/useEventClosureTypes';

const {waitingReport, waitingAck} = defineProps<{
    waitingReport: Record<number, Event>,
    waitingAck: Record<number, Event>
}>();

const dialog = useDialog();

const locationStore = await useEventLocations().promise.value;

const closureStore = await useEventClosureTypes().promise.value;

const reportingEvents = ref<Record<number, boolean>>({});

forEach(waitingReport, event => {
    reportingEvents.value[event.id] = false;
});

async function download() {
    let events: Record<number, EventsBilling> = {};
    forEach(reportingEvents.value, (checked, index) => {
        if (!checked) {
            return;
        }
        const eventId = parseInt(index);

        events[eventId] = waitingReport[eventId].billing ?? EventsBilling.SVIE;
    });

    let request = EventReport.reportEvents(events).then(file => file.download());

    toast.loading(request, 'Generálás');
}

function reportChecked() {
    toast.info('Bejelentések mentése folyamatban');

    forEach(reportingEvents.value, (checked, index) => {
        if (!checked) {
            return;
        }

        const eventId = parseInt(index);
        let event = waitingReport[eventId];

        event.report('').then(() => {
            toast.success('Bejelentés mentve');

            delete waitingReport[eventId];
            waitingAck[eventId] = event;
        });
    });
}

function checkAll() {
    each(waitingReport, event => reportingEvents.value[event.id] = true);
}
</script>
