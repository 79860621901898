/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    AuthAuthschProfileResponse,
    BaseAssignedGroupPermissionResponse,
    BaseAuthResponse,
    BaseAuthschAccountResponse,
    BaseConstantsResponse,
    BaseExtendedUserResponse,
    BaseGlobalPermissionAssignmentResponse,
    BaseGlobalPermissionAssignmentsResponse,
    BaseGlobalPermissionUpdate,
    BaseGroupArchiveResponse,
    BaseGroupListResponse,
    BaseGroupPermissionListResponse,
    BaseGroupPermissionStore,
    BaseGroupPermissionUpdate,
    BaseGroupPostListResponse,
    BaseGroupResponse,
    BaseGroupSummaryResponse,
    BaseJobOutputResponse,
    BaseMembershipListResponse,
    BaseResortSummaryResponse,
    BaseSuccessResponse,
    BaseUserPaginationResponse,
    BaseUserResponse,
    BaseUserSearch,
    BaseUserSummaryResponse,
    BaseUserUpdate,
    BaseValidationBagResponse,
    ExternalMentorListResponse,
    RvtGroupPeriodSummaryResponse,
    RvtGroupUpdate,
    SystemEmailListResponse,
    SystemEmailTemplateResponse,
  } from '../models/index';
    import {
      AuthAuthschProfileResponseFromJSON,
      AuthAuthschProfileResponseToJSON,
      BaseAssignedGroupPermissionResponseFromJSON,
      BaseAssignedGroupPermissionResponseToJSON,
      BaseAuthResponseFromJSON,
      BaseAuthResponseToJSON,
      BaseAuthschAccountResponseFromJSON,
      BaseAuthschAccountResponseToJSON,
      BaseConstantsResponseFromJSON,
      BaseConstantsResponseToJSON,
      BaseExtendedUserResponseFromJSON,
      BaseExtendedUserResponseToJSON,
      BaseGlobalPermissionAssignmentResponseFromJSON,
      BaseGlobalPermissionAssignmentResponseToJSON,
      BaseGlobalPermissionAssignmentsResponseFromJSON,
      BaseGlobalPermissionAssignmentsResponseToJSON,
      BaseGlobalPermissionUpdateFromJSON,
      BaseGlobalPermissionUpdateToJSON,
      BaseGroupArchiveResponseFromJSON,
      BaseGroupArchiveResponseToJSON,
      BaseGroupListResponseFromJSON,
      BaseGroupListResponseToJSON,
      BaseGroupPermissionListResponseFromJSON,
      BaseGroupPermissionListResponseToJSON,
      BaseGroupPermissionStoreFromJSON,
      BaseGroupPermissionStoreToJSON,
      BaseGroupPermissionUpdateFromJSON,
      BaseGroupPermissionUpdateToJSON,
      BaseGroupPostListResponseFromJSON,
      BaseGroupPostListResponseToJSON,
      BaseGroupResponseFromJSON,
      BaseGroupResponseToJSON,
      BaseGroupSummaryResponseFromJSON,
      BaseGroupSummaryResponseToJSON,
      BaseJobOutputResponseFromJSON,
      BaseJobOutputResponseToJSON,
      BaseMembershipListResponseFromJSON,
      BaseMembershipListResponseToJSON,
      BaseResortSummaryResponseFromJSON,
      BaseResortSummaryResponseToJSON,
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseUserPaginationResponseFromJSON,
      BaseUserPaginationResponseToJSON,
      BaseUserResponseFromJSON,
      BaseUserResponseToJSON,
      BaseUserSearchFromJSON,
      BaseUserSearchToJSON,
      BaseUserSummaryResponseFromJSON,
      BaseUserSummaryResponseToJSON,
      BaseUserUpdateFromJSON,
      BaseUserUpdateToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      ExternalMentorListResponseFromJSON,
      ExternalMentorListResponseToJSON,
      RvtGroupPeriodSummaryResponseFromJSON,
      RvtGroupPeriodSummaryResponseToJSON,
      RvtGroupUpdateFromJSON,
      RvtGroupUpdateToJSON,
      SystemEmailListResponseFromJSON,
      SystemEmailListResponseToJSON,
      SystemEmailTemplateResponseFromJSON,
      SystemEmailTemplateResponseToJSON,
    } from '../models/index';

      export interface MainAdminExecuteJobRequest {
        job: string;
      }

      export interface MainAuthenticationImpersonateRequest {
        user: string;
      }

      export interface MainEmailsRenderRequest {
        email: number;
      }

      export interface MainEmailsTemplateRequest {
        template: string;
      }

      export interface MainExternalsBssCallbackRequest {
        event: number;
      }

      export interface MainExternalsSpotCallbackRequest {
        event: number;
      }

      export interface MainGlobalPermissionsByUserRequest {
        user: string;
      }

      export interface MainGlobalPermissionsUpdateRequest {
        user: string;
        baseGlobalPermissionUpdate: BaseGlobalPermissionUpdate;
      }

      export interface MainGroupPermissionsDeleteRequest {
        groupPermission: number;
      }

      export interface MainGroupPermissionsStoreRequest {
        baseGroupPermissionStore: BaseGroupPermissionStore;
      }

      export interface MainGroupPermissionsUpdateRequest {
        groupPermission: number;
        baseGroupPermissionUpdate: BaseGroupPermissionUpdate;
      }

      export interface MainGroupsArchiveRequest {
        group: number;
      }

      export interface MainGroupsPeriodRequest {
        group: number;
        period: number;
      }

      export interface MainGroupsPostsRequest {
        group: number;
      }

      export interface MainGroupsResortSummaryRequest {
        group: number;
      }

      export interface MainGroupsShowRequest {
        group: number;
      }

      export interface MainGroupsSummaryRequest {
        group: number;
      }

      export interface MainGroupsUpdateCapabilitiesRequest {
        group: number;
        rvtGroupUpdate: RvtGroupUpdate;
      }

      export interface MainUsersAuthschRequest {
        user: string;
      }

      export interface MainUsersDebugRequest {
        user: string;
      }

      export interface MainUsersImpersonateRequest {
        user: string;
      }

      export interface MainUsersMembershipsRequest {
        user: string;
      }

      export interface MainUsersRefreshRequest {
        user: string;
      }

      export interface MainUsersSearchRequest {
        baseUserSearch: BaseUserSearch;
      }

      export interface MainUsersShowRequest {
        user: string;
      }

      export interface MainUsersSummaryRequest {
        user: string;
      }

      export interface MainUsersUpdateRequest {
        user: string;
        baseUserUpdate: BaseUserUpdate;
      }

      export class MainApi extends runtime.BaseAPI {

      private async adminExecuteJobRaw(requestParameters: MainAdminExecuteJobRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseJobOutputResponse>> {
          if (requestParameters.job === null || requestParameters.job === undefined) {
          throw new runtime.RequiredError('job','Required parameter requestParameters.job was null or undefined when calling adminExecuteJob.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/admin/system/taskRun/{job}`.replace(`{${"job"}}`, encodeURIComponent(String(requestParameters.job))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseJobOutputResponseFromJSON(jsonValue));
      }

        static async adminExecuteJob(job: string, options?: {form?: FormRef}): Promise<{data: BaseJobOutputResponse, raw: Response}> {
          const api = new this();
          const response = await api.adminExecuteJobRaw({ job: job }, options);
          return await response.value();
        }

      private async adminRedisClearRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/admin/system/redisClear`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async adminRedisClear(options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.adminRedisClearRaw(options);
          return await response.value();
        }

      private async authenticationImpersonateRaw(requestParameters: MainAuthenticationImpersonateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling authenticationImpersonate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/auth/impersonate/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async authenticationImpersonate(user: string, options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.authenticationImpersonateRaw({ user: user }, options);
          return await response.value();
        }

      private async authenticationLogoutRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/auth/logout`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async authenticationLogout(options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.authenticationLogoutRaw(options);
          return await response.value();
        }

      private async authenticationRefreshRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseExtendedUserResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/auth/refresh`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseExtendedUserResponseFromJSON(jsonValue));
      }

        static async authenticationRefresh(options?: {form?: FormRef}): Promise<{data: BaseExtendedUserResponse, raw: Response}> {
          const api = new this();
          const response = await api.authenticationRefreshRaw(options);
          return await response.value();
        }

      private async authenticationStopImpersonatingRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/auth/impersonate`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async authenticationStopImpersonating(options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.authenticationStopImpersonatingRaw(options);
          return await response.value();
        }

      private async authenticationUserRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/auth/user`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async authenticationUser(options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.authenticationUserRaw(options);
          return await response.value();
        }

      private async emailsGetRecentRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<SystemEmailListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/emails/recent`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => SystemEmailListResponseFromJSON(jsonValue));
      }

        static async emailsGetRecent(options?: {form?: FormRef}): Promise<{data: SystemEmailListResponse, raw: Response}> {
          const api = new this();
          const response = await api.emailsGetRecentRaw(options);
          return await response.value();
        }

      private async emailsRenderRaw(requestParameters: MainEmailsRenderRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<SystemEmailTemplateResponse>> {
          if (requestParameters.email === null || requestParameters.email === undefined) {
          throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling emailsRender.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/emails/{email}/render`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => SystemEmailTemplateResponseFromJSON(jsonValue));
      }

        static async emailsRender(email: number, options?: {form?: FormRef}): Promise<{data: SystemEmailTemplateResponse, raw: Response}> {
          const api = new this();
          const response = await api.emailsRenderRaw({ email: email }, options);
          return await response.value();
        }

      private async emailsTemplateRaw(requestParameters: MainEmailsTemplateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<SystemEmailTemplateResponse>> {
          if (requestParameters.template === null || requestParameters.template === undefined) {
          throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling emailsTemplate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/emails/template/{template}`.replace(`{${"template"}}`, encodeURIComponent(String(requestParameters.template))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => SystemEmailTemplateResponseFromJSON(jsonValue));
      }

        static async emailsTemplate(template: string, options?: {form?: FormRef}): Promise<{data: SystemEmailTemplateResponse, raw: Response}> {
          const api = new this();
          const response = await api.emailsTemplateRaw({ template: template }, options);
          return await response.value();
        }

      private async externalsAdminSchMentorsRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<ExternalMentorListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/external/adminsch/mentors`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => ExternalMentorListResponseFromJSON(jsonValue));
      }

        static async externalsAdminSchMentors(options?: {form?: FormRef}): Promise<{data: ExternalMentorListResponse, raw: Response}> {
          const api = new this();
          const response = await api.externalsAdminSchMentorsRaw(options);
          return await response.value();
        }

      private async externalsBssCallbackRaw(requestParameters: MainExternalsBssCallbackRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling externalsBssCallback.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/external/events/{event}/bss`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async externalsBssCallback(event: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.externalsBssCallbackRaw({ event: event }, options);
          return await response.value();
        }

      private async externalsIcalRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/external/calendars/ical/sch.ics`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async externalsIcal(options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.externalsIcalRaw(options);
          return await response.value();
        }

      private async externalsSpotCallbackRaw(requestParameters: MainExternalsSpotCallbackRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling externalsSpotCallback.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/external/events/{event}/spot`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async externalsSpotCallback(event: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.externalsSpotCallbackRaw({ event: event }, options);
          return await response.value();
        }

      private async globalPermissionsByUserRaw(requestParameters: MainGlobalPermissionsByUserRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGlobalPermissionAssignmentResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling globalPermissionsByUser.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/globalPermissions/user/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGlobalPermissionAssignmentResponseFromJSON(jsonValue));
      }

        static async globalPermissionsByUser(user: string, options?: {form?: FormRef}): Promise<{data: BaseGlobalPermissionAssignmentResponse, raw: Response}> {
          const api = new this();
          const response = await api.globalPermissionsByUserRaw({ user: user }, options);
          return await response.value();
        }

      private async globalPermissionsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGlobalPermissionAssignmentsResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/globalPermissions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGlobalPermissionAssignmentsResponseFromJSON(jsonValue));
      }

        static async globalPermissionsIndex(options?: {form?: FormRef}): Promise<{data: BaseGlobalPermissionAssignmentsResponse, raw: Response}> {
          const api = new this();
          const response = await api.globalPermissionsIndexRaw(options);
          return await response.value();
        }

      private async globalPermissionsUpdateRaw(requestParameters: MainGlobalPermissionsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGlobalPermissionAssignmentResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling globalPermissionsUpdate.');
          }

          if (requestParameters.baseGlobalPermissionUpdate === null || requestParameters.baseGlobalPermissionUpdate === undefined) {
          throw new runtime.RequiredError('baseGlobalPermissionUpdate','Required parameter requestParameters.baseGlobalPermissionUpdate was null or undefined when calling globalPermissionsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/globalPermissions/user/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: BaseGlobalPermissionUpdateToJSON(requestParameters.baseGlobalPermissionUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGlobalPermissionAssignmentResponseFromJSON(jsonValue));
      }

        static async globalPermissionsUpdate(user: string, baseGlobalPermissionUpdate: BaseGlobalPermissionUpdate, options?: {form?: FormRef}): Promise<{data: BaseGlobalPermissionAssignmentResponse, raw: Response}> {
          const api = new this();
          const response = await api.globalPermissionsUpdateRaw({ user: user, baseGlobalPermissionUpdate: baseGlobalPermissionUpdate }, options);
          return await response.value();
        }

      private async groupPermissionsDeleteRaw(requestParameters: MainGroupPermissionsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.groupPermission === null || requestParameters.groupPermission === undefined) {
          throw new runtime.RequiredError('groupPermission','Required parameter requestParameters.groupPermission was null or undefined when calling groupPermissionsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groupPermissions/{groupPermission}`.replace(`{${"groupPermission"}}`, encodeURIComponent(String(requestParameters.groupPermission))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async groupPermissionsDelete(groupPermission: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupPermissionsDeleteRaw({ groupPermission: groupPermission }, options);
          return await response.value();
        }

      private async groupPermissionsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupPermissionListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groupPermissions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupPermissionListResponseFromJSON(jsonValue));
      }

        static async groupPermissionsIndex(options?: {form?: FormRef}): Promise<{data: BaseGroupPermissionListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupPermissionsIndexRaw(options);
          return await response.value();
        }

      private async groupPermissionsStoreRaw(requestParameters: MainGroupPermissionsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAssignedGroupPermissionResponse>> {
          if (requestParameters.baseGroupPermissionStore === null || requestParameters.baseGroupPermissionStore === undefined) {
          throw new runtime.RequiredError('baseGroupPermissionStore','Required parameter requestParameters.baseGroupPermissionStore was null or undefined when calling groupPermissionsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/groupPermissions`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: BaseGroupPermissionStoreToJSON(requestParameters.baseGroupPermissionStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAssignedGroupPermissionResponseFromJSON(jsonValue));
      }

        static async groupPermissionsStore(baseGroupPermissionStore: BaseGroupPermissionStore, options?: {form?: FormRef}): Promise<{data: BaseAssignedGroupPermissionResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupPermissionsStoreRaw({ baseGroupPermissionStore: baseGroupPermissionStore }, options);
          return await response.value();
        }

      private async groupPermissionsUpdateRaw(requestParameters: MainGroupPermissionsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAssignedGroupPermissionResponse>> {
          if (requestParameters.groupPermission === null || requestParameters.groupPermission === undefined) {
          throw new runtime.RequiredError('groupPermission','Required parameter requestParameters.groupPermission was null or undefined when calling groupPermissionsUpdate.');
          }

          if (requestParameters.baseGroupPermissionUpdate === null || requestParameters.baseGroupPermissionUpdate === undefined) {
          throw new runtime.RequiredError('baseGroupPermissionUpdate','Required parameter requestParameters.baseGroupPermissionUpdate was null or undefined when calling groupPermissionsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/groupPermissions/{groupPermission}`.replace(`{${"groupPermission"}}`, encodeURIComponent(String(requestParameters.groupPermission))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: BaseGroupPermissionUpdateToJSON(requestParameters.baseGroupPermissionUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAssignedGroupPermissionResponseFromJSON(jsonValue));
      }

        static async groupPermissionsUpdate(groupPermission: number, baseGroupPermissionUpdate: BaseGroupPermissionUpdate, options?: {form?: FormRef}): Promise<{data: BaseAssignedGroupPermissionResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupPermissionsUpdateRaw({ groupPermission: groupPermission, baseGroupPermissionUpdate: baseGroupPermissionUpdate }, options);
          return await response.value();
        }

      private async groupsArchiveRaw(requestParameters: MainGroupsArchiveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupArchiveResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsArchive.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}/archive`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupArchiveResponseFromJSON(jsonValue));
      }

        static async groupsArchive(group: number, options?: {form?: FormRef}): Promise<{data: BaseGroupArchiveResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsArchiveRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupListResponseFromJSON(jsonValue));
      }

        static async groupsIndex(options?: {form?: FormRef}): Promise<{data: BaseGroupListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsIndexRaw(options);
          return await response.value();
        }

      private async groupsPeriodRaw(requestParameters: MainGroupsPeriodRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtGroupPeriodSummaryResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsPeriod.');
          }

          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling groupsPeriod.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}/period/{period}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtGroupPeriodSummaryResponseFromJSON(jsonValue));
      }

        static async groupsPeriod(group: number, period: number, options?: {form?: FormRef}): Promise<{data: RvtGroupPeriodSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsPeriodRaw({ group: group, period: period }, options);
          return await response.value();
        }

      private async groupsPostsRaw(requestParameters: MainGroupsPostsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupPostListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsPosts.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}/posts`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupPostListResponseFromJSON(jsonValue));
      }

        static async groupsPosts(group: number, options?: {form?: FormRef}): Promise<{data: BaseGroupPostListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsPostsRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsResortSummaryRaw(requestParameters: MainGroupsResortSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseResortSummaryResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsResortSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}/resortSummary`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseResortSummaryResponseFromJSON(jsonValue));
      }

        static async groupsResortSummary(group: number, options?: {form?: FormRef}): Promise<{data: BaseResortSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsResortSummaryRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsShowRaw(requestParameters: MainGroupsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupResponseFromJSON(jsonValue));
      }

        static async groupsShow(group: number, options?: {form?: FormRef}): Promise<{data: BaseGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsShowRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsSummaryRaw(requestParameters: MainGroupsSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupSummaryResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/groups/{group}/summary`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupSummaryResponseFromJSON(jsonValue));
      }

        static async groupsSummary(group: number, options?: {form?: FormRef}): Promise<{data: BaseGroupSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsSummaryRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsUpdateCapabilitiesRaw(requestParameters: MainGroupsUpdateCapabilitiesRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseGroupResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsUpdateCapabilities.');
          }

          if (requestParameters.rvtGroupUpdate === null || requestParameters.rvtGroupUpdate === undefined) {
          throw new runtime.RequiredError('rvtGroupUpdate','Required parameter requestParameters.rvtGroupUpdate was null or undefined when calling groupsUpdateCapabilities.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/groups/{group}/capabilities`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: RvtGroupUpdateToJSON(requestParameters.rvtGroupUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseGroupResponseFromJSON(jsonValue));
      }

        static async groupsUpdateCapabilities(group: number, rvtGroupUpdate: RvtGroupUpdate, options?: {form?: FormRef}): Promise<{data: BaseGroupResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsUpdateCapabilitiesRaw({ group: group, rvtGroupUpdate: rvtGroupUpdate }, options);
          return await response.value();
        }

      private async indexConstantsRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseConstantsResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/constants`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseConstantsResponseFromJSON(jsonValue));
      }

        static async indexConstants(options?: {form?: FormRef}): Promise<{data: BaseConstantsResponse, raw: Response}> {
          const api = new this();
          const response = await api.indexConstantsRaw(options);
          return await response.value();
        }

      private async usersAuthschRaw(requestParameters: MainUsersAuthschRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthschAccountResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersAuthsch.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/authsch`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthschAccountResponseFromJSON(jsonValue));
      }

        static async usersAuthsch(user: string, options?: {form?: FormRef}): Promise<{data: BaseAuthschAccountResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersAuthschRaw({ user: user }, options);
          return await response.value();
        }

      private async usersDebugRaw(requestParameters: MainUsersDebugRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<AuthAuthschProfileResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersDebug.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/debug`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => AuthAuthschProfileResponseFromJSON(jsonValue));
      }

        static async usersDebug(user: string, options?: {form?: FormRef}): Promise<{data: AuthAuthschProfileResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersDebugRaw({ user: user }, options);
          return await response.value();
        }

      private async usersImpersonateRaw(requestParameters: MainUsersImpersonateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersImpersonate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/impersonate`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async usersImpersonate(user: string, options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersImpersonateRaw({ user: user }, options);
          return await response.value();
        }

      private async usersMeRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseAuthResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/me`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseAuthResponseFromJSON(jsonValue));
      }

        static async usersMe(options?: {form?: FormRef}): Promise<{data: BaseAuthResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersMeRaw(options);
          return await response.value();
        }

      private async usersMembershipsRaw(requestParameters: MainUsersMembershipsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseMembershipListResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersMemberships.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/memberships`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseMembershipListResponseFromJSON(jsonValue));
      }

        static async usersMemberships(user: string, options?: {form?: FormRef}): Promise<{data: BaseMembershipListResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersMembershipsRaw({ user: user }, options);
          return await response.value();
        }

      private async usersRefreshRaw(requestParameters: MainUsersRefreshRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersRefresh.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/refresh`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async usersRefresh(user: string, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersRefreshRaw({ user: user }, options);
          return await response.value();
        }

      private async usersSearchRaw(requestParameters: MainUsersSearchRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseUserPaginationResponse>> {
          if (requestParameters.baseUserSearch === null || requestParameters.baseUserSearch === undefined) {
          throw new runtime.RequiredError('baseUserSearch','Required parameter requestParameters.baseUserSearch was null or undefined when calling usersSearch.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/users/search`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: BaseUserSearchToJSON(requestParameters.baseUserSearch),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserPaginationResponseFromJSON(jsonValue));
      }

        static async usersSearch(baseUserSearch: BaseUserSearch, options?: {form?: FormRef}): Promise<{data: BaseUserPaginationResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersSearchRaw({ baseUserSearch: baseUserSearch }, options);
          return await response.value();
        }

      private async usersShowRaw(requestParameters: MainUsersShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseUserResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserResponseFromJSON(jsonValue));
      }

        static async usersShow(user: string, options?: {form?: FormRef}): Promise<{data: BaseUserResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersShowRaw({ user: user }, options);
          return await response.value();
        }

      private async usersSummaryRaw(requestParameters: MainUsersSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseUserSummaryResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/users/{user}/summary`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserSummaryResponseFromJSON(jsonValue));
      }

        static async usersSummary(user: string, options?: {form?: FormRef}): Promise<{data: BaseUserSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersSummaryRaw({ user: user }, options);
          return await response.value();
        }

      private async usersUpdateRaw(requestParameters: MainUsersUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseUserResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersUpdate.');
          }

          if (requestParameters.baseUserUpdate === null || requestParameters.baseUserUpdate === undefined) {
          throw new runtime.RequiredError('baseUserUpdate','Required parameter requestParameters.baseUserUpdate was null or undefined when calling usersUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: BaseUserUpdateToJSON(requestParameters.baseUserUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserResponseFromJSON(jsonValue));
      }

        static async usersUpdate(user: string, baseUserUpdate: BaseUserUpdate, options?: {form?: FormRef}): Promise<{data: BaseUserResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersUpdateRaw({ user: user, baseUserUpdate: baseUserUpdate }, options);
          return await response.value();
        }

    }
