<template>
    <PegasusHeading>{{ tender.displayName }} - {{ flag.name }} címkéjű tételek</PegasusHeading>

    <PegasusPanel
        v-for="group in byGroups"
        :key="group.group.id"
        :header="group.group.name"
        class="mb-3"
    >
        <ItemComponent
            v-for="item in group.items"
            :key="item.id"
            :item="item"
            :permissions="{canEdit: false, canConfidential: false, canDecide: false, canFinalize: false}"
        >
        </ItemComponent>
    </PegasusPanel>
</template>

<title>KPR pályázat</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useConstantsStore} from '@/stores/constants';
import ItemComponent from '@Components/money/item/Item.vue';
import {Item} from '@Models/money/Item';
import {computed} from 'vue';
import {groupBy, map, sortBy} from 'lodash-es';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {useMoneyFlags} from '@/queries/money/useMoneyFlags';
import {useKprTender} from '@/queries/kpr/useKprTenders';

const {tenderId, flagId} = defineProps<{
    tenderId: number,
    flagId: number,
}>();

const constants = useConstantsStore();

const flags = await useMoneyFlags().promise.value;

const flag = flags.find(flag => flag.id === flagId);

if (!flag) {
    throw new Error(`Invalid flag: ${flag}`);
}

const tender = await useKprTender(tenderId).promise.value;

const itemsRaw = await Item.byFlag(tenderId, flagId);

const byGroups = computed(() => {
    const result = map(
        groupBy(itemsRaw, item => item.groupId),
        (items, groupId) => ({group: constants.getGroup(parseInt(groupId)), items: items}),
    );

    return sortBy(result, row => row.group.name);
});
</script>
