/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MentorsMentorStore {
  user: string;
}

export const MentorsMentorStoreSchema = {
  'user': {
    name: 'user',
    required: true,
    nullable: false,
    label: 'Felhasználó',
    type: 'string',
      minLength: 36,
      maxLength: 36,
  },
}
export function instanceOfMentorsMentorStore(value: object): value is MentorsMentorStore {
let isInstance = true;
    isInstance = isInstance && "user" in value;

return isInstance;
}

export function MentorsMentorStoreFromJSON(json: any): MentorsMentorStore {
return MentorsMentorStoreFromJSONTyped(json, false);
}

export function MentorsMentorStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): MentorsMentorStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'user': json['user'],
    };
  }

  export function MentorsMentorStoreToJSON(value?: MentorsMentorStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'user': value.user,
    };
  }

  export function MentorsMentorStoreCreateEmpty(): MentorsMentorStore {
  return {
      'user': '',
  };
  }

