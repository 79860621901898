<template>
    <span v-if="isEmpty(types)">Nincsen egyetlen dokumentum sem feltöltve</span>

    <dl>
        <template v-for="(documents, type) in types" :key="type">
            <dt>
                {{ InventoryDocumentTypeValues[type] }}
                <PegasusBadge>{{ size(documents) }}</PegasusBadge>
            </dt>

            <div v-for="document in documents" :key="document.id" class="d-flex flex-wrap" style="align-items: center">
                <div class="d-inline-block">
                    <span>{{ document.name }}</span>
                </div>

                <div class="d-inline-block" style="margin-left: auto">
                    <span class="text-muted text-sm">
                        <i class="fas fa-clock"></i> {{ document.createdAt.datetime() }}
                    </span>

                    <PegasusButton class="ms-2" size="sm" variant="success" @click="dialog.open(DocumentViewDialog, {document})">
                        <i class="fa fa-eye"></i>
                    </PegasusButton>

                    <PegasusButton class="ms-2" size="sm" variant="primary" @click="download(document)">
                        <i class="fa fa-download"></i>
                    </PegasusButton>

                    <PegasusButton class="ms-2" size="sm" variant="danger" @click="remove(document)">
                        <i class="fa fa-trash"></i>
                    </PegasusButton>
                </div>
            </div>
        </template>
    </dl>
</template>

<script lang="ts" setup>
import {Item} from "@Models/inventory/Item";
import {Document} from "@Models/inventory/Document";
import {InventoryDocumentType, InventoryDocumentTypeValues} from "@/api/models";
import toast from "@Utils/toast";
import {each, isEmpty, size} from "lodash-es";
import {computed} from "vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusBadge from "@Components/base/PegasusBadge.vue";
import {useDialog} from '@Utils/dialog';
import DocumentViewDialog from '@Components/inventory/item/dialogs/DocumentViewDialog.vue';

const {item} = defineProps<{
    item: Item
}>();

const dialog = useDialog();

function download(document: Document) {
    let request = document.download().then(file => file.download());

    toast.loading(request, 'Letöltés');
}

async function remove(document: Document) {
    if (!await toast.confirm('Biztos vagy benne, hogy le akarod törölni ezt a dokumentumot?', 'Visszavonhatatlan művelet')) {
        return;
    }

    const request = document.remove().then(() => {
        delete item.documents[document.id];
    });

    toast.loading(request, 'Törlés');
}

const types = computed(() => {
    const result: Record<InventoryDocumentType, Record<number, Document>> = {} as Record<InventoryDocumentType, Record<number, Document>>;

    each(InventoryDocumentType, type => {
        result[type] = item.getDocumentsByType(type);
        if (isEmpty(result[type])) {
            delete result[type];
        }
    });

    return result;
});
</script>
