<template>
    <h2 class="text-center mt-2">RVT felület</h2>

    <PegasusPanel v-for="summary in tenders" :key="summary.tender.id" :header="summary.tender.displayName" class="mb-3">
        <template #header>
            <PegasusLink :params="{tender: summary.tender}" to="rvt.kpr.tender.show">
                {{ summary.tender.displayName }}
            </PegasusLink>
        </template>

        <div class="row">
            <div class="col-lg-6">
                <dl>
                    <dt>KPR időszak</dt>
                    <dd>{{ summary.tender.status }}</dd>

                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ summary.applicationsCount }} db</dd>

                    <dt>RVT-re váró módosító kérvények</dt>
                    <dd v-for="amend in amends[summary.tender.id]" :key="amend.id">
                        <PegasusLink :params="{amend}" to="rvt.amends.show">
                            #{{ amend.id }} {{ amend.objective }}
                        </PegasusLink>
                    </dd>
                    <dd v-if="isEmpty(amends[summary.tender.id])">
                        Jelenleg egy KPR módosító sem vár elbírálásra
                    </dd>

                    <dt>RVT-re váró keretigények</dt>
                    <dd v-for="claim in claims[summary.tender.id]" :key="claim.id">
                        <PegasusLink :params="{claim}" to="rvt.claims.show">
                            #{{ claim.id }} {{ claim.objective }}
                        </PegasusLink>
                    </dd>
                    <dd v-if="isEmpty(claims[summary.tender.id])">Jelenleg egy keretigény se vár elbírálásra</dd>
                </dl>
            </div>
            <div class="col-lg-6">
                <dl>
                    <dt>RVT keret</dt>
                    <dd>{{ summary.claimsBudget.huf() }}</dd>

                    <dt>KPR módosítások egyenlege</dt>
                    <dd>
                        {{ summary.amendsBalance.huf() }}
                    </dd>

                    <dt>Keretigények összege</dt>
                    <dd>{{ summary.claimsSum.huf() }}</dd>

                    <dt>Elköltetlen keret</dt>
                    <dd>{{ summary.claimsBalance.huf() }}</dd>
                </dl>
            </div>
        </div>
    </PegasusPanel>

    <span v-if="isEmpty(tenders)">Egyetlen KPR pályázat sem aktív</span>
</template>

<title>RVT felület</title>

<script lang="ts" setup>
import {TenderSummary} from '@Models/rvt/kpr/TenderSummary';
import {Claim} from '@Models/rvt/requests/Claim';
import {Amend} from '@Models/rvt/requests/Amend';
import {each, isEmpty} from 'lodash-es';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusLink from '@Components/base/PegasusLink.vue';
import {useAuthStore} from '@/stores/auth';
import {useKprTenders} from '@/queries/kpr/useKprTenders';

useAuthStore().requireUser();

let requests: Promise<any>[] = [];
let data: {
    claims: Record<number, Record<number, Claim>>,
    tenders: Record<number, TenderSummary>,
    amends: Record<number, Record<number, Amend>>
} = {
    claims: {},
    tenders: {},
    amends: {},
};

const tendersStore = await useKprTenders().promise.value;

each(tendersStore.getRelevant(), tender => {
    requests.push(
        TenderSummary.getById(tender.id).then(summary => {
            data.tenders[tender.id] = summary;
        }),
    );
    requests.push(
        Amend.getWaitingInTender(tender.id).then(amends => {
            data.amends[tender.id] = amends;
        }),
    );
    requests.push(
        Claim.getWaitingInPeriod(tender.id).then(claims => {
            data.claims[tender.id] = claims;
        }),
    );
});

await Promise.all(requests);

const {claims, tenders, amends} = data;
</script>
