<template>
    <PegasusOverlay
        v-for="(alert, id) in bag"
        :key="id"
        :content="alert.help"
        :enabled="!!alert.help"
        :header="alert.title"
    >
        <div :class="classObject">
            <h5>
                <i :class="icon"></i>
                {{ alert.title }}
                <i
                    v-if="alert.event"
                    class="fa fa-external-link-alt float-end text-sm cursor-pointer"
                    @click="dialog.open(ShortEventDialog, {id: alert.event})"
                ></i>
                <i
                    v-if="alert.link"
                    class="fa fa-external-link-alt float-end"
                    style="cursor: pointer"
                    @click="click(alert.link)"
                ></i>
            </h5>

            <span v-if="alert.content" class="whitespace">{{ alert.content }}</span>
        </div>
    </PegasusOverlay>
</template>

<script lang="ts" setup>
import type {Check} from '@Models/events/Check';
import {computed} from 'vue';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';

const {bag, variant} = defineProps<{
    bag: Check[],
    variant: 'todo' | 'info' | 'warning' | 'fatal'
}>();

const dialog = useDialog();

function click(link: string) {
    window.open(link, '_blank')?.focus();
}

const classObject = computed(() => {
    switch (variant) {
        case 'todo':
            return 'alert alert-success';
        case 'info':
            return 'alert alert-primary';
        case 'warning':
            return 'alert alert-warning';
        case 'fatal':
            return 'alert alert-danger';
        default:
            throw new Error('Invalid bag variant: ' + variant);
    }
});

const icon = computed(() => {
    switch (variant) {
        case 'todo':
            return 'icon fas fa-exclamation-triangle';
        case 'info':
            return 'icon fas fa-info-circle';
        case 'warning':
            return 'icon fas fa-exclamation-triangle';
        case 'fatal':
            return 'icon fas fa-times';
        default:
            throw new Error('Invalid bag variant: ' + variant);
    }
});
</script>
