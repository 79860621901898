<template>
    <PegasusHeading>Költési időszak - {{ period.year }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Időszak adatai">
                <DatetimeField
                    v-model="period.from"
                    :schema="MoneySpendingPeriodStoreSchema.from"
                ></DatetimeField>

                <DatetimeField
                    v-model="period.to"
                    :schema="MoneySpendingPeriodStoreSchema.to"
                ></DatetimeField>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel>
                <template #header>
                    <PegasusLink :params="{year}" to="money.budget.year.show">
                        Házszintű költségvetés
                    </PegasusLink>
                </template>

                <PegasusTable :data="budget.categories">
                    <Column field="name" header="Név"></Column>

                    <Column field="contentsText" header="Részletek"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költségvetési tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import PegasusLink from '@Components/base/PegasusLink.vue';
import {MoneySpendingPeriodStoreSchema} from '@/api/models';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import {ref} from 'vue';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

const {year} = defineProps<{
    year: number,
}>();

useAuthStore().requireUser();

const periodsStore = await useSpendingPeriods().promise.value;

const periodRaw = periodsStore.getByYear(year);

if (periodRaw === null) {
    throw new NotFoundError('Nincs ilyen költési időszak');
}

const period = ref(periodRaw);

const budget = await periodRaw.getBudget();
</script>
