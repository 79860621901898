import {BaseEntity} from '@Models/BaseEntity';
import type {InventoryOwnerResponse} from '@/api/api';

export class Owner extends BaseEntity {
    name: string;

    description: string;

    static create(): Owner {
        return new Owner();
    }

    static parseResponse(owner: Owner, data: InventoryOwnerResponse): Owner {
        owner.id = data.id;
        owner.name = data.name;
        owner.description = data.description;

        return owner;
    }
}
