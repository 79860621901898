import {useQuery} from '@tanstack/vue-query';
import {InventoryApi} from '@/api/apis/index';
import {Tag} from '@Models/inventory/Tag';
import {BaseSeverity} from '@/api/models/index';
import {filter} from 'lodash-es';

class TagStore {
    constructor(public readonly tags: Record<number, Tag>) {
    }

    public getSevereTags(): Tag[] {
        return this.getTagsBySeverity([
            BaseSeverity.DANGER,
            BaseSeverity.WARNING,
        ]);
    }

    public getTagsBySeverity(severities: BaseSeverity[]): Tag[] {
        return filter(this.tags, tag => severities.includes(tag.severity));
    }

    public getTagIdsBySeverity(severities: BaseSeverity[]): number[] {
        return this.getTagsBySeverity(severities).map(tag => tag.id);
    }
}

export function useInventoryTags() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['inventory.tags'],
        queryFn: async () => {
            const {data} = await InventoryApi.tagsAll();

            return new TagStore(Tag.newRecords(data.tags));
        },
    });
}
