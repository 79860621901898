/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryTagResponse } from './InventoryTagResponse';
    import {
    InventoryTagResponseFromJSON,
    InventoryTagResponseFromJSONTyped,
    InventoryTagResponseToJSON,
    InventoryTagResponseCreateEmpty,
    } from './InventoryTagResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryTagListResponse {
  tags: Array<InventoryTagResponse>;
}

export const InventoryTagListResponseSchema = {
  'tags': {
    name: 'tags',
    required: true,
    nullable: false,
    label: 'Inventory tags',
      type: 'array',
  },
}
export function instanceOfInventoryTagListResponse(value: object): value is InventoryTagListResponse {
let isInstance = true;
    isInstance = isInstance && "tags" in value;

return isInstance;
}

export function InventoryTagListResponseFromJSON(json: any): InventoryTagListResponse {
return InventoryTagListResponseFromJSONTyped(json, false);
}

export function InventoryTagListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryTagListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'tags': ((json['tags'] as Array<any>).map(InventoryTagResponseFromJSON)),
    };
  }

  export function InventoryTagListResponseToJSON(value?: InventoryTagListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'tags': ((value.tags as Array<any>).map(InventoryTagResponseToJSON)),
    };
  }

  export function InventoryTagListResponseCreateEmpty(): InventoryTagListResponse {
  return {
      'tags': [],
  };
  }

