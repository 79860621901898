import {useQuery} from '@tanstack/vue-query';
import {MoneyApi} from '@/api/apis/index';
import {Flag} from '@Models/money/Flag';

export function useMoneyFlags() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['money.flags'],
        queryFn: async () => {
            const {data} = await MoneyApi.flagsAll();

            return data.flags.map(flag => new Flag(flag));
        },
    });
}
