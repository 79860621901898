import {BaseEntity} from '@Models/BaseEntity';
import type {MoneyFlagResponse} from '@/api/models';

export class Flag extends BaseEntity {
    name: string;

    description: string;

    class: string;

    constructor(data: MoneyFlagResponse) {
        super();
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.class = data.cssClass;
    }

    static parseResponse(flag: Flag, data: MoneyFlagResponse): Flag {
        return new Flag(data);
    }
}
