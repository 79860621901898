<template>
    <PegasusPanel header="Tételek tulajdonosok szerint">
        <PegasusTable :data="ownerCounts">
            <Column header="Tulajdonos">
                <template #body="{data}: {data: OwnerCount}">
                    <PegasusOverlay
                        :content="owners[data.ownerId].description"
                        :header="owners[data.ownerId].name"
                    >
                        {{ owners[data.ownerId].name }}
                    </PegasusOverlay>
                </template>
            </Column>

            <Column :field="data => data.itemCount + ' tétel'" header="Tételek száma"></Column>

            <Column :field="data => data.sumPrice.huf()" header="Összérték"></Column>

            <Column header="Műveletek">
                <template #body="{data}: {data: OwnerCount}">
                    <PegasusButton
                        :hash="{...objectWithoutNulls(filter), owner: data.ownerId}"
                        popover="Tételek"
                        size="sm"
                        to="inventory.items.filter"
                        variant="info"
                    >
                        <i class="fa fa-list"></i>
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import type {OwnerCount} from '@Models/inventory/InventoryGroupStatistics';
import type {ItemFilter} from '@Models/inventory/Item';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {objectWithoutNulls} from '@Utils/misc';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {useInventoryOwners} from '@/queries/inventory/useInventoryOwners';

const {ownerCounts, filter} = defineProps<{
    ownerCounts: Record<number, OwnerCount>,
    filter: ItemFilter
}>();

const owners = await useInventoryOwners().promise.value;
</script>
