<template>
    <PegasusHeading>{{ tender.displayName }}</PegasusHeading>

    <div class="mt-2">
        <div class="row">
            <div class="col-lg-6">
                <PegasusPanel header="Adatok" header-bg-variant="info">
                    <BudgetItemSelectorField
                        v-model="tender.claimsBudgetItem"
                        :budget="budget"
                        :schema="KprTenderUpdateSchema.claimsBudgetItem"
                        inline
                    ></BudgetItemSelectorField>
                </PegasusPanel>
            </div>

            <div class="col-lg-6">
                <PegasusPanel :header-bg-variant="dirty ? 'warning' : 'primary'" header="Műveletek">
                    <PegasusButton variant="success" @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>

                    <PegasusButton
                        :href="'/api/kpr/tenders/' + tender.id + '/rules'"
                        target="_blank"
                        variant="primary"
                    >
                        <i class="fa fa-external-link"></i> Pályázati kiírás
                    </PegasusButton>

                    <PegasusButton variant="warning" @click="sgrExport">
                        <i class="fa fa-download"></i> SGR export
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="deleteTender">
                        <i class="fa fa-trash"></i> Pályázat törlése
                    </PegasusButton>
                    <hr>
                    <PegasusButton @click="dialog.open(UnapprovedItems, {tenderId: tenderId})">
                        Elbírálatlan tételek statisztika
                    </PegasusButton>
                </PegasusPanel>
            </div>
        </div>

        <div class="row">
            <div v-for="category in tender.categories" :key="category.id" class="col-sm-4">
                <PegasusPanel :header="category.name">
                    <DatetimeField
                        v-model="category.endDrafting"
                        :schema="KprCategoryUpdateSchema.endDrafting"
                        @update:model-value="dirty = true"
                    ></DatetimeField>

                    <SelectField
                        v-model="category.status"
                        :options="dictNumberEnum(KprCategoryStatusValues)"
                        :schema="KprCategoryUpdateSchema.status"
                        label="Időszak"
                        @update:model-value="dirty = true"
                    ></SelectField>

                    <BudgetItemSelectorField
                        v-model="category.budgetItemId"
                        :budget="budget"
                        :schema="KprCategoryUpdateSchema.budgetItem"
                    ></BudgetItemSelectorField>

                    <PermissionMatrix :data="matrix[category.status]"></PermissionMatrix>
                </PegasusPanel>
            </div>
        </div>
    </div>
</template>

<title>KPR admin</title>

<script lang="ts" setup>
import {ref} from 'vue';
import toast from '@Utils/toast';
import {dictNumberEnum} from '@Models/Utils';
import {KprCategoryStatusValues, KprCategoryUpdateSchema, KprTenderUpdateSchema} from '@/api/models';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {usePreventExit} from '@Utils/composables';
import {useAuthStore} from '@/stores/auth';
import {useConstantsStore} from '@/stores/constants';
import PermissionMatrix from '@Components/kpr/PermissionMatrix.vue';
import {useDialog} from '@Utils/dialog';
import UnapprovedItems from '@Components/kpr/statistics/UnapprovedItems.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import BudgetItemSelectorField from '@Components/base/form/types/custom/BudgetItemSelectorField.vue';
import {Budget} from '@Models/money/Budget';
import {useKprTender} from '@/queries/kpr/useKprTenders';

const {tenderId} = defineProps<{
    tenderId: number;
}>();

useAuthStore().requireUser();

const dialog = useDialog();

const tender = await useKprTender(tenderId).promise.value;

const budget = ref<Budget | null>(null);

Budget.getBySpendingPeriod(tender.spendingPeriodId).then(data => {
    budget.value = data;
});

const matrix = useConstantsStore().constants.kpr.permissionMatrix;

const dirty = ref(false);

usePreventExit(() => dirty.value);

function save() {
    let request = tender.update().then(() => {
        dirty.value = false;
    });

    toast.loading(request);
}

function sgrExport() {
    let request = tender.sgrExport().then(file => {
        file.download();
    });

    toast.loading(request, 'Exportálás');
}

async function deleteTender() {
    if (!await toast.confirm('Biztos vagy benne? Az összes kapcsolódó tételt, módosítókat, MINDENT IS LETÖRÖL!!!')) {
        return;
    }

    if (!await toast.confirm('Tényleg nagyon biztos vagy benne? VISSZAVONHATATLAN!!!')) {
        return;
    }

    toast.info('Törlés nem lehetséges');
    throw new Error('Tried to delete tender');
}
</script>
