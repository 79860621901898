<template>
    <PegasusHeading>Gazdaság - {{ group.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Folyamatban lévő altételek">
                <PegasusTable :data="summary.relevantSpendingItems"></PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Költhető tétel csoportok">
                <h5>KPR</h5>
                <PegasusTable :data="summary.kprCategoryApplications">
                    <Column field="category.name" header="Név"></Column>

                    <Column :field="(category: KprSummary) => category.priceSumDecided.huf()" header="Összeg"></Column>

                    <Column field="itemCount" header="Tétel szám"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: KprSummary}">
                            <PegasusButton :params="{itemGroup: data.itemGroupId}" to="money.itemGroups.main">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <h5>Költségvetések</h5>
                <PegasusTable :data="summary.budgetPrograms">
                    <Column
                        field="name"
                        header="Név"
                    ></Column>

                    <Column
                        :field="(program: BudgetProgram) => program.itemGroup.calculateSumApproved().huf()"
                        header="Összeg"
                    ></Column>

                    <Column
                        :field="(program: BudgetProgram) => program.itemGroup.calculateAllItemCount().toString()"
                        header="Tétel szám"
                    ></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: BudgetProgram}">
                            <PegasusButton :params="{itemGroup: data.itemGroup.id}" to="money.itemGroups.main">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>

            <PegasusPanel class="mt-2" header="Házszintű saját tételek">
                <PegasusTable :data="summary.budgetItems">
                    <Column field="name" header="Név"></Column>

                    <Column :field="item => item.sum.huf()" header="Összeg"></Column>

                    <Column header="Műveletek">
                        <template #body>
                            <PegasusButton variant="success">
                                <i class="fa fa-plus"></i> Költségvetés létrehozása
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Kör gazdasági adatlap</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {Group} from '@Models/base/Group';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {ref} from 'vue';
import {GroupSummary, type KprSummary} from '@Models/money/GroupSummary';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {useAuthStore} from '@/stores/auth';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

const {groupId, year} = defineProps<{
    groupId: number,
    year: number,
}>();

useAuthStore().requireUser();

const group = Group.getBySingleId(groupId);

const periodsStore = await useSpendingPeriods().promise.value;

const period = periodsStore.getByYear(year);
if (!period) {
    throw new Error('Invalid spending period');
}

const summary = ref(await GroupSummary.get(group, period));
</script>
