import {BaseEntity} from '@Models/BaseEntity';
import {Floor} from '@Models/map/Floor';
import {Room} from '@Models/map/Room';
import type {MapBuildingResponse} from '@/api/api';
import {CentralApi} from '@/api/api';
import {map, merge} from 'lodash-es';

export class Building extends BaseEntity {
    name: string;

    floors: Record<number, Floor>;

    static getBuilding(): Promise<Building> {
        return CentralApi.mapBuilding().then(response => {
            return Building.newSingle(response.data, Building.parseResponse);
        });
    }

    static parseResponse(building: Building, data: MapBuildingResponse): Building {
        building.id = data.id;
        building.name = data.name;
        building.floors = Floor.newRecords(data.floors, Floor.parseResponse);

        return building;
    }

    getAllRooms(): Record<number, Room> {
        return merge({}, ...map(this.floors, floor => floor.rooms));
    }
}
