import {useQuery} from '@tanstack/vue-query';
import {EventsApi} from '@/api/apis/index';
import {ClosureType} from '@Models/events/ClosureType';
import {isEmpty, map, values} from 'lodash-es';
import type Event from '@Models/events/Event';

class ClosureTypeStore {
    constructor(
        public readonly closureTypes: Record<number, ClosureType>,
    ) {
    }

    public getDefaultClosureType(): ClosureType {
        return values(this.closureTypes)[0];
    }

    public displayClosures(event: Event) {
        if (isEmpty(event.closures)) {
            return '-';
        }

        return map(event.closures, closure => this.closureTypes[closure.type].name).join(',');
    }
}

export function useEventClosureTypes() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['eventClosureTypes'],
        queryFn: async (): Promise<ClosureTypeStore> => {
            const {data} = await EventsApi.closureTypesIndex();

            return new ClosureTypeStore(
                ClosureType.newRecords(data.types, ClosureType.parseResponse),
            );
        },
    });
}
