<template>
    <PegasusHeading>Teremfogalási naptár - {{ location.longName }}</PegasusHeading>

    <div class="text-center">
        <iframe
            :src="url"
            class="mt-4"
            height="600"
            style="border: 0"
            width="800"
        ></iframe>
    </div>
</template>

<title>SCH teremfoglalások</title>

<script lang="ts" setup>
import {find} from 'lodash-es';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';

const {locationName} = defineProps<{
    locationName: string
}>();

const {locations} = await useEventLocations().promise.value;

const location = find(
    locations,
    location => location.name.toLowerCase() === locationName.toLowerCase(),
);

if (!location) {
    throw new NotFoundError('Érvénytelen helyszín');
}

if (location.calendar === null) {
    throw new NotFoundError('A helyszínhez nem tartozik naptár');
}

const calendarSettings = {
    src: location.calendar,
    ctz: 'Europe/Budapest',
    wkst: '2',
    hl: 'hu',
    showCalendars: '0',
    showTitle: '0',
    showTabs: '0',
    showPrint: '0',
    bgcolor: '#f4f6f9',
};

const url = 'https://calendar.google.com/calendar/embed?' + new URLSearchParams(calendarSettings).toString();
</script>
