import {BaseEntity} from '@Models/BaseEntity';
import {type BaseGroupPostResponse, BaseGroupSpecificPermission} from '@/api/api';
import {filter, map, some} from 'lodash-es';
import {AssignedGroupPermission} from '@Models/base/AssignedGroupPermission';
import {GroupPermission} from '@Models/base/GroupPermission';

export class GroupPost extends BaseEntity {
    name: string;

    groupPermissions: Record<number, AssignedGroupPermission>;

    static parseResponse(post: GroupPost, data: BaseGroupPostResponse): GroupPost {
        post.id = data.id;
        post.name = data.name;
        post.groupPermissions = AssignedGroupPermission.newRecords(data.groupPermissions, AssignedGroupPermission.parseResponse);

        return post;
    }

    public getNotInheritingPermissions(): BaseGroupSpecificPermission[] {
        return map(
            filter(this.groupPermissions, groupPermission => !groupPermission.inheriting),
            groupPermission => groupPermission.permission,
        );
    }

    public getInheritingPermissions(): BaseGroupSpecificPermission[] {
        return map(
            filter(this.groupPermissions, groupPermission => groupPermission.inheriting),
            groupPermission => groupPermission.permission,
        );
    }

    public hasPermission(permission: GroupPermission): boolean {
        return some(this.groupPermissions, groupPermission => groupPermission.permission === permission.id);
    }
}
