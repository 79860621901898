import {Constants} from '@Models/Constants';
import {defineStore} from 'pinia';
import {useLocalStorage} from '@vueuse/core';
import {type BaseConstantsResponse, BaseConstantsResponseFromJSON, BaseConstantsResponseToJSON} from '@/api/models';
import {MainApi} from '@/api/apis';

interface State {
    constants: Constants;
}

export const useConstantsStore = defineStore('constants', {
    state: (): State => <State>({
        constants: {},
    }),
    actions: {
        async init(refresh: boolean = false): Promise<void> {
            const stored = useLocalStorage<string | null>('pegasus-constants-v2', null);

            if (refresh) {
                stored.value = null;
            }

            const handlerCallback = (response: {data: BaseConstantsResponse}) => {
                stored.value = JSON.stringify(BaseConstantsResponseToJSON(response.data));

                this.constants = new Constants(response.data);
            };

            if (stored.value) {
                try {
                    handlerCallback({
                        data: BaseConstantsResponseFromJSON(JSON.parse(stored.value)),
                    });
                } catch (err) {
                    console.error(err);
                    await MainApi.indexConstants().then(handlerCallback);
                    return;
                }

                setTimeout(() => {
                    MainApi.indexConstants().then(handlerCallback);
                }, 500);
            } else {
                await MainApi.indexConstants().then(handlerCallback);
            }
        },

        getGroup(groupId: number) {
            const group = this.constants.groups.all[groupId];
            if (!group) {
                throw new Error('Invalid groupId: ' + groupId);
            }

            return group;
        },
    },
});
