import Event from '@Models/events/Event';
import {DateTime} from '@Utils/DateTime';
import {BaseEntity} from '@Models/BaseEntity';
import {File} from '@Models/File';
import type {EventsClosureResponse, EventsClosureStore, EventsClosureUpdate} from '@/api/api';
import {EventsApi} from '@/api/api';
import type {FormRef} from '@/vue';
import type {ClosureType} from '@Models/events/ClosureType';

export class Closure extends BaseEntity {
    begin: DateTime;

    end: DateTime;

    type: number;

    comment: string;

    event: number;

    static createEmpty(type: number): Closure {
        const closure = new Closure();

        closure.id = 0;
        closure.begin = DateTime.now();
        closure.end = DateTime.now();
        closure.type = type;
        closure.comment = '';
        closure.event = 0;

        return closure;
    }

    static createEmptyFromEvent(event: Event, type: number): Closure {
        const closure = new Closure();

        closure.id = 0;
        closure.begin = event.start;
        closure.end = event.finish;
        closure.type = type;
        closure.comment = '';
        closure.event = event.id;

        return closure;
    }

    static parseResponse(closure: Closure, data: EventsClosureResponse): Closure {
        closure.id = data.id;
        closure.begin = data.begin;
        closure.end = data.end;
        closure.type = data.typeId;
        closure.comment = data.comment;
        closure.event = data.eventId;

        return closure;
    }

    public store(form: FormRef): Promise<Closure> {
        const data: EventsClosureStore = {
            event: this.event,
            type: this.type,
            begin: this.begin,
            end: this.end,
            comment: this.comment,
        };

        return EventsApi.closuresStore(data, {form}).then(response => {
            return Closure.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsClosureUpdate = {
            type: this.type,
            begin: this.begin,
            end: this.end,
            comment: this.comment,
        };

        return EventsApi.closuresUpdate(this.id, data, {form}).then(response => {
            Closure.parseResponse(this, response.data);
        });
    }

    public pr(): Promise<File> {
        return EventsApi.closuresPr(this.id).then(response => File.createFromResponse(response));
    }

    public delete(): Promise<void> {
        return EventsApi.closuresDestroy(this.id).then();
    }

    get hours(): number {
        return Math.ceil(Math.abs(this.begin.diffHours(this.end)));
    }

    public getPrice(type: ClosureType): number {
        return (this.hours + 2) * type.price;
    }
}
