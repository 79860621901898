<template>
    <h3 class="m-2 text-center">Jóváhagyásra váró rendezvények</h3>

    <PegasusTable :data="waitingApprove">
        <Column :field="item => '#' + item.id" header="ID"></Column>

        <Column header="Név" sortable>
            <template #body="{data}">
                <a href="javascript:void(0)" @click="dialog.open(ShortEventDialog, {eventData: data})">
                    {{ data.name }}
                </a>
            </template>
        </Column>

        <Column field="date" header="Időpont" sortable></Column>

        <Column field="participants" header="Létszám"></Column>

        <Column field="outsiders" header="Külsősök száma"></Column>

        <Column :field="event => locationStore.getDisplayLocations(event)" header="Terem"></Column>

        <Column :field="item => item.permitNeeded ? 'Igen' : 'Nem'" header="ÜZO"></Column>

        <Column header="Műveletek">
            <template #body="{data}">
                <PegasusButton :params="{event: data}" to="events.edit" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';

const {waitingApprove} = defineProps<{
    waitingApprove: Record<number, Event>
}>();

const dialog = useDialog();

const locationStore = await useEventLocations().promise.value;
</script>
