<template>
    <PegasusPanel header="Tagság és jogosultságok">
        <b>Felhasználó:</b> {{ user.name }}

        <div v-if="access?.membership">
            <div>
                <b>Tagság állapota:</b> {{ access?.membership.statusName }}
            </div>
            <div v-if="isEmpty(access.membership.posts)">
                Körtagság, poszt nélkül
            </div>
            <div v-else>
                <b>Körben betöltött posztok:</b> {{ access?.membership.postsList }}
            </div>
        </div>

        <div v-else>
            Nincs körtagság
        </div>

        <div>
            <b>Jogosultságok: </b>
            <span v-if="access?.permissions.length === 0">Nincsen egyetlen jogosultság sem</span>
        </div>
        <ul v-if="access" class="simple">
            <li v-for="permission in access.permissions" :key="permission">
                {{ groupPermissions[permission].name }}
                <PegasusHelp :title="groupPermissions[permission].name">
                    {{ groupPermissions[permission].description }}
                </PegasusHelp>
            </li>
        </ul>
    </PegasusPanel>
</template>

<script lang="ts" setup>
import {isEmpty} from 'lodash-es';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import type {User} from '@Models/base/User';
import type {GroupAccess} from '@Models/base/GroupAccess';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import {useGroupPermissions} from '@/queries/auth/useGroupPermissions';

const {user, access} = defineProps<{
    user: User,
    access: GroupAccess | null
}>();

const {groupPermissions} = await useGroupPermissions().promise.value;
</script>
