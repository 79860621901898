import {useQuery} from '@tanstack/vue-query';
import {KprApi} from '@/api/apis/index';
import {Tender} from '@Models/rvt/kpr/Tender';
import {filter, find} from 'lodash-es';

class KprTenderStore {
    constructor(
        public readonly tenders: Record<number, Tender>,
    ) {
    }

    public getBySpendingPeriodId(periodId: number) {
        return find(this.tenders, tender => tender.spendingPeriodId === periodId) ?? null;
    }

    public getRelevant() {
        return filter(this.tenders, tender => tender.isActive());
    }
}

export function useKprTenders() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['kpr.tenders'],
        queryFn: async (): Promise<KprTenderStore> => {
            const {data} = await KprApi.tenderIndex();

            return new KprTenderStore(
                data.tenders
                    .map(tender => new Tender(tender))
                    .keyBy(tender => tender.id),
            );
        },
    });
}

export function useKprTender(tenderId: number) {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['kpr.tenders', tenderId],
        queryFn: async (): Promise<Tender> => {
            const {data} = await KprApi.tenderShow(tenderId);

            return new Tender(data);
        },
    });
}
