<template>
    <PegasusHeading>{{ application.group.name }} - {{ tender.displayName }}</PegasusHeading>

    <div class="row">
        <div v-for="{category, capp} in categories" :key="category.id" class="col-md-4 mb-3">
            <PegasusPanel :header="category.name + ' KPR'">
                <dl>
                    <dt>Pályázati időszak</dt>
                    <dd>{{ category.statusText }}</dd>
                    <dt>Leadási határidő</dt>
                    <dd>{{ category.endDrafting.datetime() }}</dd>
                </dl>

                <dl v-if="capp">
                    <dt>Műveletek</dt>
                    <dd>
                        <PegasusButton
                            :params="{application: application, category: category.shortName}"
                            to="rvt.kpr.edit"
                            variant="primary"
                        >
                            <i class="fas fa-folder-open"></i> Megnyitás
                        </PegasusButton>

                        <PegasusButton v-if="capp.canEdit" variant="danger" @click="deleteCapp(capp)">
                            <i class="fas fa-trash"></i> Törlés
                        </PegasusButton>
                    </dd>
                    <dt>Tételek száma</dt>
                    <dd>{{ capp.itemGroup.calculateAllItemCount() }}</dd>
                </dl>

                <dl v-else>
                    <dt>Pályázat állapota</dt>
                    <dd>Nincs létrehozva</dd>

                    <dd v-if="application.canEditStatement && category.isInDrafting">
                        <PegasusButton variant="success" @click="createCapp(category)">
                            Pályázat létrehozása
                        </PegasusButton>
                    </dd>
                </dl>
            </PegasusPanel>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <PegasusPanel :header="'Beszámoló, aktív tagok: ' + application.number">
                <template #header>
                    Beszámoló, aktív tagok: {{ application.number }}
                    <span v-if="application.canEditStatement" class="float-end">
                        <PegasusButton size="sm" variant="primary" @click="edit">
                            Szerkesztés
                        </PegasusButton>
                    </span>
                </template>

                <span style="white-space: pre-line">{{ application.statement }}</span>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>KPR pályázat</title>

<script lang="ts" setup>
import {Application} from '@Models/rvt/kpr/Application';
import {Category} from '@Models/rvt/kpr/Category';
import {CategoryApplication} from '@Models/rvt/kpr/CategoryApplication';
import {computed, ref} from 'vue';
import toast from '@Utils/toast';
import {redirect} from '@/router';
import {map} from 'lodash-es';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {useAuthStore} from '@/stores/auth';
import {useDialog} from '@Utils/dialog';
import ApplicationStatementDialog from '@Components/kpr/ApplicationStatementDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useKprTender} from '@/queries/kpr/useKprTenders';

const {applicationId} = defineProps<{
    applicationId: number
}>();

useAuthStore().requireUser();

const dialog = useDialog();

const application = ref(await Application.getById(applicationId));
// eslint-disable-next-line vue/no-ref-object-reactivity-loss
const tender = await useKprTender(application.value.tenderId).promise.value;

const categories = computed(() => {
    return map(tender.categories, category => {
        return {
            category: category,
            capp: application.value.getCappByCategory(category),
        };
    });
});

async function createCapp(category: Category) {
    if (!await toast.confirm('Biztosan létre akarsz hozni ' + category.name + ' pályázatot?')) {
        return;
    }

    toast.info('Létrehozás folyamatban');
    CategoryApplication.create(application.value, category).store().then(() => {
        toast.success('Pályázat sikeresen létrehozva, átirányítás');

        redirect({
            name: 'rvt.kpr.edit',
            params: {
                application: '' + application.value.id,
                category: category.shortName,
            },
        });
    });
}

async function deleteCapp(capp: CategoryApplication) {
    if (!await toast.confirm('Biztosan ki akarod törölni a pályázatot és az összes benne foglalt tételt?')) {
        return;
    }

    let request = capp.delete().then(() => {
        delete application.value.categories[capp.id];
    });

    toast.loading(request, 'Pályázat törlése');
}

function edit() {
    dialog.open(ApplicationStatementDialog, {application});
}
</script>
