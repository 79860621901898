import {Group} from '@Models/base/Group';
import {filter, sortBy} from 'lodash-es';
import type {BaseConstantsResponse} from '@/api/api';

export class Constants {
    kpr: {
        permissionMatrix: Record<number, Record<number, Record<number, number>>>
    };

    groups: {
        all: Record<number, Group>,
        resorts: Record<number, Group>,
        calculated: Group[]
    };

    events: {
        calendar: string,
    };

    constructor(data: BaseConstantsResponse) {
        this.kpr = {
            permissionMatrix: data.kpr.permissionMatrix,
        };

        this.groups = {
            all: Group.newRecords(data.groups.all, Group.parseResponse),
            resorts: {},
            calculated: [],
        };
        this.groups.resorts = filter(this.groups.all, group => group.isResort() && group.active);
        this.groups.calculated = sortBy(this.groups.resorts, resort => resort.name);

        const others = new Group();
        others.id = 15;
        others.name = 'Egyéb körök';
        others.shortName = 'Egyéb';
        others.active = true;
        others.resort = null;
        this.groups.calculated.push(others);

        this.events = {
            calendar: data.events.calendar,
        };
    }
}
