/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyFlagResponse } from './MoneyFlagResponse';
    import {
    MoneyFlagResponseFromJSON,
    MoneyFlagResponseFromJSONTyped,
    MoneyFlagResponseToJSON,
    MoneyFlagResponseCreateEmpty,
    } from './MoneyFlagResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyFlagListResponse {
  flags: Array<MoneyFlagResponse>;
}

export const MoneyFlagListResponseSchema = {
  'flags': {
    name: 'flags',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMoneyFlagListResponse(value: object): value is MoneyFlagListResponse {
let isInstance = true;
    isInstance = isInstance && "flags" in value;

return isInstance;
}

export function MoneyFlagListResponseFromJSON(json: any): MoneyFlagListResponse {
return MoneyFlagListResponseFromJSONTyped(json, false);
}

export function MoneyFlagListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyFlagListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'flags': ((json['flags'] as Array<any>).map(MoneyFlagResponseFromJSON)),
    };
  }

  export function MoneyFlagListResponseToJSON(value?: MoneyFlagListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'flags': ((value.flags as Array<any>).map(MoneyFlagResponseToJSON)),
    };
  }

  export function MoneyFlagListResponseCreateEmpty(): MoneyFlagListResponse {
  return {
      'flags': [],
  };
  }

