<template>
    <dt>Név</dt>
    <dd>{{ event.name }}</dd>

    <dt>Helyszín</dt>
    <dd>{{ locationStore.getDisplayLongLocations(event) }}</dd>

    <dt>Időpont</dt>
    <dd>{{ event.datetime }}</dd>

    <dt>Várható résztvevőszám</dt>
    <dd>{{ event.participants }}</dd>

    <dt>Főrendező</dt>
    <dd>{{ event.responsible.name }}
        (<a :href="'mailto:' + event.responsible.email" target="_blank">
            {{ event.responsible.email }}
        </a> |
        <a v-if="event.responsible.phoneLink" :href="event.responsible.phoneLink">
            {{ event.responsible.phone }}
        </a> | SCH {{ event.responsible.room?.displayNumber }})
    </dd>

    <dt>Rendezvény bejelentési állapot</dt>
    <dd>{{ event.status }}</dd>

    <PegasusButton v-if="event.canEdit" :params="{event: event}" to="events.edit" variant="primary">
        Ugrás szerkesztéshez
    </PegasusButton>

    <PegasusButton v-else-if="event.canRead" :params="{event: event}" to="events.edit" variant="primary">
        Ugrás részletekhez
    </PegasusButton>
</template>

<script lang="ts" setup>
import PublicEvent from "@Models/events/PublicEvent";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useEventLocations} from '@/queries/events/useEventLocations';

const {event} = defineProps<{
    event: PublicEvent,
}>();

const locationStore = await useEventLocations().promise.value;
</script>
