<template>
    <dl>
        <dt>Akutális állapot</dt>
        <dd>{{ service.getStatus(config) }}</dd>

        <template v-if="service.finalized === null && deadline">
            <dt>Határidő a véglegesítésre</dt>
            <dd>{{ deadline.datetime() }}</dd>
        </template>
    </dl>

    <dt>Történet</dt>
    <div class="timeline">
        <div v-if="service.createdAt">
            <i class="fas fa-plus-circle bg-primary text-white"></i>
            <div class="timeline-item">
                <span class="time">
                    <i class="fas fa-clock"></i> {{ service.createdAt.datetime() }}
                </span>
                <h3 class="timeline-header">Létrehozva</h3>
            </div>
        </div>

        <div v-if="service.finalized">
            <i class="fas fa-save bg-primary text-white"></i>
            <div class="timeline-item">
                <span class="time">
                    <i class="fas fa-clock"></i> {{ service.finalized.datetime() }}
                </span>
                <h3 class="timeline-header">Véglegesítve</h3>
            </div>
        </div>

        <div v-if="service.decisionTime && service.decision">
            <i class="fas fa-check bg-success text-white"></i>
            <div class="timeline-item">
                <span class="time">
                    <i class="fas fa-clock"></i> {{ service.decisionTime.datetime() }}
                </span>

                <h3 class="timeline-header">
                    {{ config.approvedText }}
                </h3>
            </div>
        </div>

        <div v-if="service.decisionTime && !service.decision">
            <i class="fas fa-check bg-danger text-white"></i>
            <div class="timeline-item">
                <span class="time">
                    <i class="fas fa-clock"></i> {{ service.decisionTime.datetime() }}
                </span>

                <h3 class="timeline-header">
                    {{ config.rejectedText }}
                </h3>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type {Service} from '@Models/events/Service';
import type {DateTime} from '@Utils/DateTime';
import {useEventServiceConfigs} from '@/queries/events/useEventServiceConfigs';

const {service, deadline} = defineProps<{
    service: Service,
    deadline: DateTime | null
}>();

const services = await useEventServiceConfigs().promise.value;

const config = services[service.type];
</script>
