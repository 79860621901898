/* tslint:disable */
/* eslint-disable */

export enum MapRoomType {
    CORRIDOR = 'CORRIDOR',
    LIVING = 'LIVING',
    COMMON = 'COMMON',
    BME = 'BME',
    HEROSZ = 'HEROSZ',
    SPECIAL = 'SPECIAL',
    LIFT = 'LIFT'
}

export const MapRoomTypeValues: Record<MapRoomType, string> = {
    'CORRIDOR': 'Folyosó',
    'LIVING': 'Lakószoba',
    'COMMON': 'Közössségi helyiség',
    'BME': 'Egyetemi helyiség',
    'HEROSZ': 'Üzemeltetési helyiség',
    'SPECIAL': 'Különleges helyiség',
    'LIFT': 'Lift'
}


export function MapRoomTypeFromJSON(json: any): MapRoomType {
    return MapRoomTypeFromJSONTyped(json, false);
}

export function MapRoomTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapRoomType {
    return json as MapRoomType;
}

export function MapRoomTypeToJSON(value?: MapRoomType | null): any {
    return value as any;
}

export function MapRoomTypeCreateEmpty(): MapRoomType {
    return MapRoomType.CORRIDOR;
}

