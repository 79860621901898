<template>
    <PegasusHeading>{{ tender.displayName }} Statisztikák</PegasusHeading>

    <PegasusPanel header="Statisztikák">
        <table class="table text-nowrap">
            <thead>
                <tr>
                    <th></th>
                    <th>Tételek száma</th>
                    <th>Leadott összeg</th>
                    <th>Elvi bírált összeg</th>
                    <th>Végleges összeg</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="flag in statistics" :key="flag.id">
                    <th>
                        <PegasusLink :params="{tender: tenderId, flag: flag.id}" size="sm" to="rvt.kpr.tender.itemsByFlag">
                            {{ flag.name }}
                        </PegasusLink>
                    </th>
                    <td>{{ flag.itemCount }}</td>
                    <td>{{ flag.priceSum.huf() }}</td>
                    <td>{{ flag.approvedPriceSum.huf() }}</td>
                    <td>{{ flag.finalPriceSum.huf() }}</td>
                </tr>
            </tbody>
        </table>
    </PegasusPanel>
</template>

<title>KPR pályázat</title>

<script lang="ts" setup>
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {KprApi} from '@/api/apis';
import {promiseAll} from '@Utils/misc';
import PegasusLink from '@Components/base/PegasusLink.vue';
import {useKprTender} from '@/queries/kpr/useKprTenders';

const {tenderId} = defineProps<{
    tenderId: number
}>();

const {tender, statisticsRaw: {data: {flags: statistics}}} = await promiseAll({
    tender: useKprTender(tenderId).promise.value,
    statisticsRaw: KprApi.statisticsFlags(tenderId),
});
</script>
