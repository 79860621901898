import {DateTime as Luxon, Info, Interval} from 'luxon';

interface Locale {
    monthNames: string[];
    weekDayNames: string[];
}

export class DateTime {
    private static locale: Locale = {
        monthNames: Info.months('long', {locale: 'hu'}),
        weekDayNames: Info.weekdays('long', {locale: 'hu'}),
    };

    /**
     * @internal
     */
    luxon: Luxon;

    constructor(luxon: Luxon) {
        this.luxon = luxon;
    }

    static now(): DateTime {
        return new this(Luxon.now().startOf('second'));
    }

    static fromISO(text: string): DateTime {
        return new this(Luxon.fromISO(text));
    }

    static fromJSDate(date: Date): DateTime {
        return new this(Luxon.fromJSDate(date));
    }

    static from(year: number, month: number = 1, day: number = 1, hour: number = 0, minute: number = 0, second: number = 0, millisecond: number = 0): DateTime {
        return new this(Luxon.fromObject({year, month, day, hour, minute, second, millisecond}));
    }

    public toJSDate(): Date {
        return this.luxon.toJSDate();
    }

    public toISO(): string {
        return this.luxon.toISO();
    }

    public toISODate(): string {
        return this.luxon.toISODate();
    }

    public date(): string {
        return this.luxon.toFormat('y-LL-dd');
    }

    public dateShort(): string {
        return this.luxon.toFormat('LLLL dd.');
    }

    public datetime(): string {
        return this.luxon.toFormat('y-LL-dd HH:mm:ss');
    }

    public datetimeShort(): string {
        return this.luxon.toFormat('y-LL-dd HH:mm');
    }

    public timeShort(): string {
        return this.luxon.toFormat('HH:mm');
    }

    public until(other: DateTime): Interval {
        return this.luxon.until(other.luxon);
    }

    public diffNow(): string {
        return this.diff(DateTime.now());
    }

    public diffBigNow(): string {
        return this.diffBig(DateTime.now());
    }

    public diffBig(other: DateTime): string {
        let {months, days, hours} = this.luxon.diff(other.luxon, ['months', 'days', 'hours']);

        if (this.isBefore(other)) {
            months *= -1;
            days *= -1;
            hours *= -1;

            if (months > 1) {
                return '' + months + ' hónappal ezelőtt';
            }

            if (days > 1) {
                return '' + days + ' nappal ezelőtt';
            }

            if (hours > 1) {
                return '' + hours + ' órával ezelőtt';
            }

            return 'Percekkel ezelőtt';
        }

        if (months > 1) {
            return '' + months + ' hónap múlva';
        }

        if (days > 1) {
            return '' + days + ' nap múlva';
        }

        if (hours > 1) {
            return '' + hours + ' óra múlva';
        }

        return 'Perceken belül';
    }

    public diff(other: DateTime): string {
        return this.luxon.toRelative({
            base: other.luxon,
        });
    }

    public diffHours(other: DateTime): number {
        return this.luxon.diff(other.luxon).as('hours');
    }

    public addDay(days: number): DateTime {
        return new DateTime(this.luxon.plus({days}));
    }

    public addMonth(months: number = 1): DateTime {
        return new DateTime(this.luxon.plus({months}));
    }

    public addYear(year: number = 1): DateTime {
        return new DateTime(this.luxon.plus({year}));
    }

    public setDay(day: number): DateTime {
        return new DateTime(this.luxon.set({day}));
    }

    public setHour(hour: number): DateTime {
        return new DateTime(this.luxon.set({hour}));
    }

    public setMinute(minute: number): DateTime {
        return new DateTime(this.luxon.set({minute}));
    }

    public setSeconds(second: number): DateTime {
        return new DateTime(this.luxon.set({second}));
    }

    public resetSeconds(): DateTime {
        return new DateTime(this.luxon.set({second: 0, millisecond: 0}));
    }

    public daysInMonth(): number {
        return this.luxon.daysInMonth;
    }

    public startOfYear(): DateTime {
        return new DateTime(this.luxon.startOf('year'));
    }

    public startOfMonth(): DateTime {
        return new DateTime(this.luxon.startOf('month'));
    }

    public startOfWeek(): DateTime {
        return new DateTime(this.luxon.startOf('week'));
    }

    public endOfDay(): DateTime {
        return new DateTime(this.luxon.endOf('day'));
    }

    public endOfMonth(): DateTime {
        return new DateTime(this.luxon.endOf('month'));
    }

    public endOfYear(): DateTime {
        return new DateTime(this.luxon.endOf('year'));
    }

    public isSameDay(other: DateTime) {
        return this.luxon.hasSame(other.luxon, 'day');
    }

    public isFuture(): boolean {
        return this.luxon.diff(Luxon.now()).toMillis() > 0;
    }

    public isPast(): boolean {
        return !this.isFuture();
    }

    public isBefore(other: DateTime) {
        return this.luxon < other.luxon;
    }

    public isAfter(other: DateTime) {
        return this.luxon > other.luxon;
    }

    get hour(): number {
        return this.luxon.hour;
    }

    get day(): number {
        return this.luxon.day;
    }

    get weekdayLong(): string {
        return DateTime.locale.weekDayNames[this.luxon.weekday - 1];
    }

    get month(): number {
        return this.luxon.month;
    }

    get monthLong(): string {
        return DateTime.locale.monthNames[this.luxon.month - 1];
    }

    get year(): number {
        return this.luxon.year;
    }

    get weekOfYear(): number {
        return this.luxon.weekNumber;
    }
}
