import {User} from '../base/User';
import {DateTime} from '@Utils/DateTime';
import {BaseEntity} from '@Models/BaseEntity';
import type {EventsMinimalEventResponse} from '@/api/api';
import {EventsApi} from '@/api/api';
import {Group} from '@Models/base/Group';

export default class PublicEvent extends BaseEntity {
    name: string;

    description: string;

    locations: number[];

    responsible: User;

    group: Group | null;

    start: DateTime;

    finish: DateTime;

    roomStart: DateTime;

    roomFinish: DateTime;

    participants: number;

    finalized: DateTime | null;

    cancelled: DateTime | null;

    decision: boolean | null;

    canRead: boolean;

    canEdit: boolean;

    static getById(id: number): Promise<PublicEvent> {
        return EventsApi.eventsShort(id).then(response => {
            return PublicEvent.newSingle(response.data, PublicEvent.parseResponse);
        });
    }

    static parseResponse(event: PublicEvent, data: EventsMinimalEventResponse): PublicEvent {
        event.id = data.id;
        event.name = data.name;
        event.description = data.description;
        event.locations = data.locations;
        event.responsible = User.newSingle(data.responsible, User.parseBaseResponse);
        event.group = data.groupId ? Group.getBySingleId(data.groupId) : null;
        event.start = data.start;
        event.finish = data.finish;
        event.participants = data.participants;
        event.finalized = data.finalized;
        event.cancelled = data.cancelled;
        event.decision = data.decision;
        event.canRead = data.canRead;
        event.canEdit = data.canEdit;

        return event;
    }

    get status(): string {
        if (this.cancelled !== null) {
            return 'Visszamondva';
        }

        if (!this.finalized) {
            return 'Szerkesztés alatt';
        }

        if (this.decision !== null) {
            return this.decision ? 'Elfogadott rendezvény' : 'Elutasított rendezvény';
        }

        return 'Bírálás alatt';
    }

    get date(): string {
        if (this.start.isSameDay(this.finish)) {
            return this.start.date();
        }

        if (this.finish.hour < 12 && this.start.until(this.finish).length('days') <= 1) {
            return this.start.date();
        }

        return this.start.date() + '-' + this.finish.date();
    }

    get datetime(): string {
        if (this.start.isSameDay(this.finish)) {
            return this.start.datetimeShort() + ' - ' + this.finish.timeShort();
        }

        return this.start.datetimeShort() + ' - ' + this.finish.datetimeShort();
    }

    get reservationDate(): string {
        if (this.roomStart.isSameDay(this.roomFinish)) {
            return this.roomStart.date();
        }

        if (this.roomFinish.hour < 12 && this.roomStart.until(this.roomFinish).length('days') <= 1) {
            return this.roomStart.date();
        }

        return this.roomStart.date() + '-' + this.roomFinish.date();
    }
}
