<template>
    <span v-if="dangerCounts.length + warningCounts.length === 0">
        Minden rendben
    </span>

    <PegasusOverlay
        v-for="tagCount in dangerCounts"
        :key="tagCount.tagId"
        :content="tagCount.itemCount + ' tétel'"
        :header="tagStore.tags[tagCount.tagId].name"
    >
        <i class="fa fa-exclamation-circle text-danger me-2"></i>
    </PegasusOverlay>

    <PegasusOverlay
        v-for="tagCount in warningCounts"
        :key="tagCount.tagId"
        :content="tagCount.itemCount + ' tétel'"
        :header="tagStore.tags[tagCount.tagId].name"
    >
        <i class="fa fa-warning text-warning me-2"></i>
    </PegasusOverlay>
</template>

<script lang="ts" setup>
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import {useInventoryTags} from '@/queries/inventory/useInventoryTags';
import {BaseSeverity} from '@/api/models/index';

const {groupSummary} = defineProps<{
    groupSummary: InventoryGroupSummary
}>();

const tagStore = await useInventoryTags().promise.value;

const dangerCounts = groupSummary.filterTagCounts(tagStore.getTagIdsBySeverity([BaseSeverity.DANGER]));
const warningCounts = groupSummary.filterTagCounts(tagStore.getTagIdsBySeverity([BaseSeverity.DANGER]));
</script>
