import {Base} from '@Models/Base';
import {Tender} from '@Models/rvt/kpr/Tender';
import type {KprTenderSummaryResponse} from '@/api/api';
import {KprApi} from '@/api/api';

export class TenderSummary extends Base {
    tender: Tender;

    applicationsCount: number;

    declinedAmends: number;

    successfulAmends: number;

    amendsBalance: number;

    claimsSum: number;

    claimsBudget: number;

    claimsBalance: number;

    declinedClaims: number;

    successfulClaims: number;

    static getById(id: number): Promise<TenderSummary> {
        return KprApi.tenderSummary(id).then(response => {
            return TenderSummary.newSingle(response.data, TenderSummary.parseResponse);
        });
    }

    static parseResponse(summary: TenderSummary, data: KprTenderSummaryResponse): TenderSummary {
        summary.tender = new Tender(data.tender);
        summary.applicationsCount = data.applicationsCount;
        summary.declinedAmends = data.declinedAmends;
        summary.successfulAmends = data.successfulAmends;
        summary.amendsBalance = data.amendsBalance;
        summary.claimsSum = data.claimsSum;
        summary.claimsBudget = data.claimsBudget;
        summary.claimsBalance = data.claimsBalance;
        summary.declinedClaims = data.declinedClaims;
        summary.successfulClaims = data.successfulClaims;

        return summary;
    }
}
