import {createApp, h, inject, type InjectionKey, reactive} from 'vue';
import {Prime} from '@Utils/prime';
import {QueryClient, useQueryClient, VueQueryPlugin} from '@tanstack/vue-query';
import DialogHolder from '@Components/DialogHolder.vue';

export const PegasusDialogPromiseSymbol = Symbol() as InjectionKey<(value: any) => void>;

export function useReturn(): (value: boolean | null) => void {
    const PegasusPromise = inject(PegasusDialogPromiseSymbol);
    if (!PegasusPromise) {
        throw new Error('Cannot be called outside of setup or lifecycle hooks');
    }

    return PegasusPromise;
}

export function useDialog(): Dialog {
    return new Dialog(useQueryClient());
}

export class Dialog {
    constructor(
        private readonly queryClient: QueryClient,
    ) {
    }

    public open(component: any, props: any = {}): Promise<boolean | null> {
        const app = createApp({
            render: () => h(DialogHolder, {comp: component, props: reactive(props)}),
            expose: [],
        });

        Prime.install(app);

        app.use(VueQueryPlugin, {
            queryClient: this.queryClient,
            queryClientConfig: {
                defaultOptions: {
                    queries: {
                        experimental_prefetchInRender: true, // eslint-disable-line camelcase -- TODO
                    },
                },
            },
        });

        const promise = new Promise<boolean | null>(resolve => {
            app.provide(PegasusDialogPromiseSymbol, resolve);

            app.mount(document.createElement('div'));
        });

        promise.then(value => {
            app.unmount();

            return value;
        });

        return promise;
    }
}
