/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseSuccessResponse,
    BaseValidationBagResponse,
    MoneyAccountCheck,
    MoneyAccountCheckResponse,
    MoneyBudgetCategoryListResponse,
    MoneyBudgetCategoryResponse,
    MoneyBudgetCategoryStore,
    MoneyBudgetCategoryUpdate,
    MoneyBudgetItemResponse,
    MoneyBudgetItemStore,
    MoneyBudgetItemUpdate,
    MoneyBudgetProgramListResponse,
    MoneyBudgetProgramMilestoneAdd,
    MoneyBudgetProgramResponse,
    MoneyBudgetProgramStore,
    MoneyBudgetProgramUpdate,
    MoneyExtendedBudgetCategoryResponse,
    MoneyFlagListResponse,
    MoneyItemAssetResponse,
    MoneyItemAssetType,
    MoneyItemCommentResponse,
    MoneyItemCommentStore,
    MoneyItemCommentUpdate,
    MoneyItemDecide,
    MoneyItemFinalize,
    MoneyItemGroupStore,
    MoneyItemGroupUpdate,
    MoneyItemGroupWithItemsResponse,
    MoneyItemMove,
    MoneyItemPaginationResponse,
    MoneyItemResponse,
    MoneyItemStore,
    MoneyItemUpdate,
    MoneyItemsUpdateDefaultResponse,
    MoneyMoneyGroupSummary,
    MoneyOpenTenderListResponse,
    MoneyOpenTenderResponse,
    MoneyOpenTenderStore,
    MoneyOpenTenderUpdate,
    MoneySpendingItemEvent,
    MoneySpendingItemEventResponse,
    MoneySpendingItemResponse,
    MoneySpendingItemStore,
    MoneySpendingItemUpdate,
    MoneySpendingPeriodStore,
    RvtSpendingPeriodResponse,
    RvtSpendingPeriodSummaryResponse,
    RvtSpendingPeriodsResponse,
  } from '../models/index';
    import {
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      MoneyAccountCheckFromJSON,
      MoneyAccountCheckToJSON,
      MoneyAccountCheckResponseFromJSON,
      MoneyAccountCheckResponseToJSON,
      MoneyBudgetCategoryListResponseFromJSON,
      MoneyBudgetCategoryListResponseToJSON,
      MoneyBudgetCategoryResponseFromJSON,
      MoneyBudgetCategoryResponseToJSON,
      MoneyBudgetCategoryStoreFromJSON,
      MoneyBudgetCategoryStoreToJSON,
      MoneyBudgetCategoryUpdateFromJSON,
      MoneyBudgetCategoryUpdateToJSON,
      MoneyBudgetItemResponseFromJSON,
      MoneyBudgetItemResponseToJSON,
      MoneyBudgetItemStoreFromJSON,
      MoneyBudgetItemStoreToJSON,
      MoneyBudgetItemUpdateFromJSON,
      MoneyBudgetItemUpdateToJSON,
      MoneyBudgetProgramListResponseFromJSON,
      MoneyBudgetProgramListResponseToJSON,
      MoneyBudgetProgramMilestoneAddFromJSON,
      MoneyBudgetProgramMilestoneAddToJSON,
      MoneyBudgetProgramResponseFromJSON,
      MoneyBudgetProgramResponseToJSON,
      MoneyBudgetProgramStoreFromJSON,
      MoneyBudgetProgramStoreToJSON,
      MoneyBudgetProgramUpdateFromJSON,
      MoneyBudgetProgramUpdateToJSON,
      MoneyExtendedBudgetCategoryResponseFromJSON,
      MoneyExtendedBudgetCategoryResponseToJSON,
      MoneyFlagListResponseFromJSON,
      MoneyFlagListResponseToJSON,
      MoneyItemAssetResponseFromJSON,
      MoneyItemAssetResponseToJSON,
      MoneyItemAssetTypeFromJSON,
      MoneyItemAssetTypeToJSON,
      MoneyItemCommentResponseFromJSON,
      MoneyItemCommentResponseToJSON,
      MoneyItemCommentStoreFromJSON,
      MoneyItemCommentStoreToJSON,
      MoneyItemCommentUpdateFromJSON,
      MoneyItemCommentUpdateToJSON,
      MoneyItemDecideFromJSON,
      MoneyItemDecideToJSON,
      MoneyItemFinalizeFromJSON,
      MoneyItemFinalizeToJSON,
      MoneyItemGroupStoreFromJSON,
      MoneyItemGroupStoreToJSON,
      MoneyItemGroupUpdateFromJSON,
      MoneyItemGroupUpdateToJSON,
      MoneyItemGroupWithItemsResponseFromJSON,
      MoneyItemGroupWithItemsResponseToJSON,
      MoneyItemMoveFromJSON,
      MoneyItemMoveToJSON,
      MoneyItemPaginationResponseFromJSON,
      MoneyItemPaginationResponseToJSON,
      MoneyItemResponseFromJSON,
      MoneyItemResponseToJSON,
      MoneyItemStoreFromJSON,
      MoneyItemStoreToJSON,
      MoneyItemUpdateFromJSON,
      MoneyItemUpdateToJSON,
      MoneyItemsUpdateDefaultResponseFromJSON,
      MoneyItemsUpdateDefaultResponseToJSON,
      MoneyMoneyGroupSummaryFromJSON,
      MoneyMoneyGroupSummaryToJSON,
      MoneyOpenTenderListResponseFromJSON,
      MoneyOpenTenderListResponseToJSON,
      MoneyOpenTenderResponseFromJSON,
      MoneyOpenTenderResponseToJSON,
      MoneyOpenTenderStoreFromJSON,
      MoneyOpenTenderStoreToJSON,
      MoneyOpenTenderUpdateFromJSON,
      MoneyOpenTenderUpdateToJSON,
      MoneySpendingItemEventFromJSON,
      MoneySpendingItemEventToJSON,
      MoneySpendingItemEventResponseFromJSON,
      MoneySpendingItemEventResponseToJSON,
      MoneySpendingItemResponseFromJSON,
      MoneySpendingItemResponseToJSON,
      MoneySpendingItemStoreFromJSON,
      MoneySpendingItemStoreToJSON,
      MoneySpendingItemUpdateFromJSON,
      MoneySpendingItemUpdateToJSON,
      MoneySpendingPeriodStoreFromJSON,
      MoneySpendingPeriodStoreToJSON,
      RvtSpendingPeriodResponseFromJSON,
      RvtSpendingPeriodResponseToJSON,
      RvtSpendingPeriodSummaryResponseFromJSON,
      RvtSpendingPeriodSummaryResponseToJSON,
      RvtSpendingPeriodsResponseFromJSON,
      RvtSpendingPeriodsResponseToJSON,
    } from '../models/index';

      export interface MoneyBudgetCategoriesDeleteRequest {
        category: number;
      }

      export interface MoneyBudgetCategoriesShowRequest {
        category: number;
      }

      export interface MoneyBudgetCategoriesStoreRequest {
        moneyBudgetCategoryStore: MoneyBudgetCategoryStore;
      }

      export interface MoneyBudgetCategoriesUpdateRequest {
        category: number;
        moneyBudgetCategoryUpdate: MoneyBudgetCategoryUpdate;
      }

      export interface MoneyBudgetItemsDeleteRequest {
        item: number;
      }

      export interface MoneyBudgetItemsGetOpenTendersRequest {
        item: number;
      }

      export interface MoneyBudgetItemsGetProgramsRequest {
        item: number;
      }

      export interface MoneyBudgetItemsShowRequest {
        item: number;
      }

      export interface MoneyBudgetItemsStoreRequest {
        moneyBudgetItemStore: MoneyBudgetItemStore;
      }

      export interface MoneyBudgetItemsUpdateRequest {
        item: number;
        moneyBudgetItemUpdate: MoneyBudgetItemUpdate;
      }

      export interface MoneyBudgetProgramsAddMilestoneRequest {
        program: number;
        moneyBudgetProgramMilestoneAdd: MoneyBudgetProgramMilestoneAdd;
      }

      export interface MoneyBudgetProgramsCreateItemGroupRequest {
        program: number;
      }

      export interface MoneyBudgetProgramsDeleteRequest {
        program: number;
      }

      export interface MoneyBudgetProgramsShowRequest {
        program: number;
      }

      export interface MoneyBudgetProgramsStoreRequest {
        moneyBudgetProgramStore: MoneyBudgetProgramStore;
      }

      export interface MoneyBudgetProgramsUpdateRequest {
        program: number;
        moneyBudgetProgramUpdate: MoneyBudgetProgramUpdate;
      }

      export interface MoneyGroupsSummaryRequest {
        group: number;
        spendingPeriod: number;
      }

      export interface MoneyItemAssetsDeleteRequest {
        asset: number;
      }

      export interface MoneyItemAssetsDownloadRequest {
        asset: number;
      }

      export interface MoneyItemAssetsStoreRequest {
        item: number;
        file: Blob;
        name: string;
        description: string;
        type: MoneyItemAssetType;
      }

      export interface MoneyItemAssetsUpdateRequest {
        asset: number;
        file: Blob | null;
        name: string;
        description: string;
        type: MoneyItemAssetType;
      }

      export interface MoneyItemCommentsDeleteRequest {
        comment: number;
      }

      export interface MoneyItemCommentsStoreRequest {
        moneyItemCommentStore: MoneyItemCommentStore;
      }

      export interface MoneyItemCommentsUpdateRequest {
        comment: number;
        moneyItemCommentUpdate: MoneyItemCommentUpdate;
      }

      export interface MoneyItemGroupsDestroyRequest {
        itemGroup: number;
      }

      export interface MoneyItemGroupsShowRequest {
        itemGroup: number;
      }

      export interface MoneyItemGroupsStoreRequest {
        moneyItemGroupStore: MoneyItemGroupStore;
      }

      export interface MoneyItemGroupsUpdateRequest {
        itemGroup: number;
        moneyItemGroupUpdate: MoneyItemGroupUpdate;
      }

      export interface MoneyItemsDecideRequest {
        item: number;
        moneyItemDecide: MoneyItemDecide;
      }

      export interface MoneyItemsDestroyRequest {
        item: number;
      }

      export interface MoneyItemsFinalizeRequest {
        item: number;
        moneyItemFinalize: MoneyItemFinalize;
      }

      export interface MoneyItemsMoveRequest {
        item: number;
        moneyItemMove: MoneyItemMove;
      }

      export interface MoneyItemsSearchRequest {
        perPage: number;
        currentPage: number;
        searchTerm: string;
      }

      export interface MoneyItemsShowRequest {
        item: number;
      }

      export interface MoneyItemsStoreRequest {
        moneyItemStore: MoneyItemStore;
      }

      export interface MoneyItemsUpdateRequest {
        item: number;
        moneyItemUpdate: MoneyItemUpdate;
      }

      export interface MoneyOpenTendersDeleteRequest {
        tender: number;
      }

      export interface MoneyOpenTendersShowRequest {
        tender: number;
      }

      export interface MoneyOpenTendersStoreRequest {
        moneyOpenTenderStore: MoneyOpenTenderStore;
      }

      export interface MoneyOpenTendersUpdateRequest {
        tender: number;
        moneyOpenTenderUpdate: MoneyOpenTenderUpdate;
      }

      export interface MoneySpendingEventsCashInRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsCashOutRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsOtherEventRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsSpendingSettledRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsTransferRegisteredRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsTransferRequestedRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingEventsTransferSentRequest {
        item: number;
        moneySpendingItemEvent: MoneySpendingItemEvent;
      }

      export interface MoneySpendingItemsDeleteRequest {
        item: number;
      }

      export interface MoneySpendingItemsShowRequest {
        item: number;
      }

      export interface MoneySpendingItemsStoreRequest {
        moneySpendingItemStore: MoneySpendingItemStore;
      }

      export interface MoneySpendingItemsUpdateRequest {
        item: number;
        moneySpendingItemUpdate: MoneySpendingItemUpdate;
      }

      export interface MoneySpendingPeriodsBudgetRequest {
        spendingPeriod: number;
      }

      export interface MoneySpendingPeriodsShowRequest {
        spendingPeriod: number;
      }

      export interface MoneySpendingPeriodsStoreRequest {
        moneySpendingPeriodStore: MoneySpendingPeriodStore;
      }

      export interface MoneySpendingPeriodsSummaryRequest {
        spendingPeriod: number;
      }

      export interface MoneyUtilitiesAccountNumberRequest {
        moneyAccountCheck: MoneyAccountCheck;
      }

      export class MoneyApi extends runtime.BaseAPI {

      private async budgetCategoriesDeleteRaw(requestParameters: MoneyBudgetCategoriesDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.category === null || requestParameters.category === undefined) {
          throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling budgetCategoriesDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/category/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters.category))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async budgetCategoriesDelete(category: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetCategoriesDeleteRaw({ category: category }, options);
          return await response.value();
        }

      private async budgetCategoriesShowRaw(requestParameters: MoneyBudgetCategoriesShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetCategoryResponse>> {
          if (requestParameters.category === null || requestParameters.category === undefined) {
          throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling budgetCategoriesShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/category/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters.category))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetCategoryResponseFromJSON(jsonValue));
      }

        static async budgetCategoriesShow(category: number, options?: {form?: FormRef}): Promise<{data: MoneyBudgetCategoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetCategoriesShowRaw({ category: category }, options);
          return await response.value();
        }

      private async budgetCategoriesStoreRaw(requestParameters: MoneyBudgetCategoriesStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyExtendedBudgetCategoryResponse>> {
          if (requestParameters.moneyBudgetCategoryStore === null || requestParameters.moneyBudgetCategoryStore === undefined) {
          throw new runtime.RequiredError('moneyBudgetCategoryStore','Required parameter requestParameters.moneyBudgetCategoryStore was null or undefined when calling budgetCategoriesStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/category`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetCategoryStoreToJSON(requestParameters.moneyBudgetCategoryStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyExtendedBudgetCategoryResponseFromJSON(jsonValue));
      }

        static async budgetCategoriesStore(moneyBudgetCategoryStore: MoneyBudgetCategoryStore, options?: {form?: FormRef}): Promise<{data: MoneyExtendedBudgetCategoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetCategoriesStoreRaw({ moneyBudgetCategoryStore: moneyBudgetCategoryStore }, options);
          return await response.value();
        }

      private async budgetCategoriesUpdateRaw(requestParameters: MoneyBudgetCategoriesUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyExtendedBudgetCategoryResponse>> {
          if (requestParameters.category === null || requestParameters.category === undefined) {
          throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling budgetCategoriesUpdate.');
          }

          if (requestParameters.moneyBudgetCategoryUpdate === null || requestParameters.moneyBudgetCategoryUpdate === undefined) {
          throw new runtime.RequiredError('moneyBudgetCategoryUpdate','Required parameter requestParameters.moneyBudgetCategoryUpdate was null or undefined when calling budgetCategoriesUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/category/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters.category))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetCategoryUpdateToJSON(requestParameters.moneyBudgetCategoryUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyExtendedBudgetCategoryResponseFromJSON(jsonValue));
      }

        static async budgetCategoriesUpdate(category: number, moneyBudgetCategoryUpdate: MoneyBudgetCategoryUpdate, options?: {form?: FormRef}): Promise<{data: MoneyExtendedBudgetCategoryResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetCategoriesUpdateRaw({ category: category, moneyBudgetCategoryUpdate: moneyBudgetCategoryUpdate }, options);
          return await response.value();
        }

      private async budgetItemsDeleteRaw(requestParameters: MoneyBudgetItemsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling budgetItemsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async budgetItemsDelete(item: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsDeleteRaw({ item: item }, options);
          return await response.value();
        }

      private async budgetItemsGetOpenTendersRaw(requestParameters: MoneyBudgetItemsGetOpenTendersRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyOpenTenderListResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling budgetItemsGetOpenTenders.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/items/{item}/openTenders`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyOpenTenderListResponseFromJSON(jsonValue));
      }

        static async budgetItemsGetOpenTenders(item: number, options?: {form?: FormRef}): Promise<{data: MoneyOpenTenderListResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsGetOpenTendersRaw({ item: item }, options);
          return await response.value();
        }

      private async budgetItemsGetProgramsRaw(requestParameters: MoneyBudgetItemsGetProgramsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramListResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling budgetItemsGetPrograms.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/items/{item}/programs`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramListResponseFromJSON(jsonValue));
      }

        static async budgetItemsGetPrograms(item: number, options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramListResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsGetProgramsRaw({ item: item }, options);
          return await response.value();
        }

      private async budgetItemsShowRaw(requestParameters: MoneyBudgetItemsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling budgetItemsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetItemResponseFromJSON(jsonValue));
      }

        static async budgetItemsShow(item: number, options?: {form?: FormRef}): Promise<{data: MoneyBudgetItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsShowRaw({ item: item }, options);
          return await response.value();
        }

      private async budgetItemsStoreRaw(requestParameters: MoneyBudgetItemsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetItemResponse>> {
          if (requestParameters.moneyBudgetItemStore === null || requestParameters.moneyBudgetItemStore === undefined) {
          throw new runtime.RequiredError('moneyBudgetItemStore','Required parameter requestParameters.moneyBudgetItemStore was null or undefined when calling budgetItemsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/items`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetItemStoreToJSON(requestParameters.moneyBudgetItemStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetItemResponseFromJSON(jsonValue));
      }

        static async budgetItemsStore(moneyBudgetItemStore: MoneyBudgetItemStore, options?: {form?: FormRef}): Promise<{data: MoneyBudgetItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsStoreRaw({ moneyBudgetItemStore: moneyBudgetItemStore }, options);
          return await response.value();
        }

      private async budgetItemsUpdateRaw(requestParameters: MoneyBudgetItemsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling budgetItemsUpdate.');
          }

          if (requestParameters.moneyBudgetItemUpdate === null || requestParameters.moneyBudgetItemUpdate === undefined) {
          throw new runtime.RequiredError('moneyBudgetItemUpdate','Required parameter requestParameters.moneyBudgetItemUpdate was null or undefined when calling budgetItemsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetItemUpdateToJSON(requestParameters.moneyBudgetItemUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetItemResponseFromJSON(jsonValue));
      }

        static async budgetItemsUpdate(item: number, moneyBudgetItemUpdate: MoneyBudgetItemUpdate, options?: {form?: FormRef}): Promise<{data: MoneyBudgetItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetItemsUpdateRaw({ item: item, moneyBudgetItemUpdate: moneyBudgetItemUpdate }, options);
          return await response.value();
        }

      private async budgetProgramsAddMilestoneRaw(requestParameters: MoneyBudgetProgramsAddMilestoneRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramResponse>> {
          if (requestParameters.program === null || requestParameters.program === undefined) {
          throw new runtime.RequiredError('program','Required parameter requestParameters.program was null or undefined when calling budgetProgramsAddMilestone.');
          }

          if (requestParameters.moneyBudgetProgramMilestoneAdd === null || requestParameters.moneyBudgetProgramMilestoneAdd === undefined) {
          throw new runtime.RequiredError('moneyBudgetProgramMilestoneAdd','Required parameter requestParameters.moneyBudgetProgramMilestoneAdd was null or undefined when calling budgetProgramsAddMilestone.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/programs/{program}/milestone`.replace(`{${"program"}}`, encodeURIComponent(String(requestParameters.program))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetProgramMilestoneAddToJSON(requestParameters.moneyBudgetProgramMilestoneAdd),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramResponseFromJSON(jsonValue));
      }

        static async budgetProgramsAddMilestone(program: number, moneyBudgetProgramMilestoneAdd: MoneyBudgetProgramMilestoneAdd, options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsAddMilestoneRaw({ program: program, moneyBudgetProgramMilestoneAdd: moneyBudgetProgramMilestoneAdd }, options);
          return await response.value();
        }

      private async budgetProgramsCreateItemGroupRaw(requestParameters: MoneyBudgetProgramsCreateItemGroupRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemGroupWithItemsResponse>> {
          if (requestParameters.program === null || requestParameters.program === undefined) {
          throw new runtime.RequiredError('program','Required parameter requestParameters.program was null or undefined when calling budgetProgramsCreateItemGroup.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/programs/{program}/createItemGroup`.replace(`{${"program"}}`, encodeURIComponent(String(requestParameters.program))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemGroupWithItemsResponseFromJSON(jsonValue));
      }

        static async budgetProgramsCreateItemGroup(program: number, options?: {form?: FormRef}): Promise<{data: MoneyItemGroupWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsCreateItemGroupRaw({ program: program }, options);
          return await response.value();
        }

      private async budgetProgramsDeleteRaw(requestParameters: MoneyBudgetProgramsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.program === null || requestParameters.program === undefined) {
          throw new runtime.RequiredError('program','Required parameter requestParameters.program was null or undefined when calling budgetProgramsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/programs/{program}`.replace(`{${"program"}}`, encodeURIComponent(String(requestParameters.program))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async budgetProgramsDelete(program: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsDeleteRaw({ program: program }, options);
          return await response.value();
        }

      private async budgetProgramsRelevantRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/programs/relevant`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramListResponseFromJSON(jsonValue));
      }

        static async budgetProgramsRelevant(options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramListResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsRelevantRaw(options);
          return await response.value();
        }

      private async budgetProgramsShowRaw(requestParameters: MoneyBudgetProgramsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramResponse>> {
          if (requestParameters.program === null || requestParameters.program === undefined) {
          throw new runtime.RequiredError('program','Required parameter requestParameters.program was null or undefined when calling budgetProgramsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/budget/programs/{program}`.replace(`{${"program"}}`, encodeURIComponent(String(requestParameters.program))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramResponseFromJSON(jsonValue));
      }

        static async budgetProgramsShow(program: number, options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsShowRaw({ program: program }, options);
          return await response.value();
        }

      private async budgetProgramsStoreRaw(requestParameters: MoneyBudgetProgramsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramResponse>> {
          if (requestParameters.moneyBudgetProgramStore === null || requestParameters.moneyBudgetProgramStore === undefined) {
          throw new runtime.RequiredError('moneyBudgetProgramStore','Required parameter requestParameters.moneyBudgetProgramStore was null or undefined when calling budgetProgramsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/budget/programs`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetProgramStoreToJSON(requestParameters.moneyBudgetProgramStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramResponseFromJSON(jsonValue));
      }

        static async budgetProgramsStore(moneyBudgetProgramStore: MoneyBudgetProgramStore, options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsStoreRaw({ moneyBudgetProgramStore: moneyBudgetProgramStore }, options);
          return await response.value();
        }

      private async budgetProgramsUpdateRaw(requestParameters: MoneyBudgetProgramsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetProgramResponse>> {
          if (requestParameters.program === null || requestParameters.program === undefined) {
          throw new runtime.RequiredError('program','Required parameter requestParameters.program was null or undefined when calling budgetProgramsUpdate.');
          }

          if (requestParameters.moneyBudgetProgramUpdate === null || requestParameters.moneyBudgetProgramUpdate === undefined) {
          throw new runtime.RequiredError('moneyBudgetProgramUpdate','Required parameter requestParameters.moneyBudgetProgramUpdate was null or undefined when calling budgetProgramsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/budget/programs/{program}`.replace(`{${"program"}}`, encodeURIComponent(String(requestParameters.program))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyBudgetProgramUpdateToJSON(requestParameters.moneyBudgetProgramUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetProgramResponseFromJSON(jsonValue));
      }

        static async budgetProgramsUpdate(program: number, moneyBudgetProgramUpdate: MoneyBudgetProgramUpdate, options?: {form?: FormRef}): Promise<{data: MoneyBudgetProgramResponse, raw: Response}> {
          const api = new this();
          const response = await api.budgetProgramsUpdateRaw({ program: program, moneyBudgetProgramUpdate: moneyBudgetProgramUpdate }, options);
          return await response.value();
        }

      private async flagsAllRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyFlagListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/flags`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyFlagListResponseFromJSON(jsonValue));
      }

        static async flagsAll(options?: {form?: FormRef}): Promise<{data: MoneyFlagListResponse, raw: Response}> {
          const api = new this();
          const response = await api.flagsAllRaw(options);
          return await response.value();
        }

      private async groupsSummaryRaw(requestParameters: MoneyGroupsSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyMoneyGroupSummary>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsSummary.');
          }

          if (requestParameters.spendingPeriod === null || requestParameters.spendingPeriod === undefined) {
          throw new runtime.RequiredError('spendingPeriod','Required parameter requestParameters.spendingPeriod was null or undefined when calling groupsSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/group/{group}/summary/{spendingPeriod}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"spendingPeriod"}}`, encodeURIComponent(String(requestParameters.spendingPeriod))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyMoneyGroupSummaryFromJSON(jsonValue));
      }

        static async groupsSummary(group: number, spendingPeriod: number, options?: {form?: FormRef}): Promise<{data: MoneyMoneyGroupSummary, raw: Response}> {
          const api = new this();
          const response = await api.groupsSummaryRaw({ group: group, spendingPeriod: spendingPeriod }, options);
          return await response.value();
        }

      private async itemAssetsDeleteRaw(requestParameters: MoneyItemAssetsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.asset === null || requestParameters.asset === undefined) {
          throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling itemAssetsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/itemsAssets/{asset}`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemAssetsDelete(asset: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemAssetsDeleteRaw({ asset: asset }, options);
          return await response.value();
        }

      private async itemAssetsDownloadRaw(requestParameters: MoneyItemAssetsDownloadRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.asset === null || requestParameters.asset === undefined) {
          throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling itemAssetsDownload.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/itemsAssets/{asset}`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async itemAssetsDownload(asset: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.itemAssetsDownloadRaw({ asset: asset }, options);
          return await response.value();
        }

      private async itemAssetsStoreRaw(requestParameters: MoneyItemAssetsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemAssetResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemAssetsStore.');
          }

          if (requestParameters.file === null || requestParameters.file === undefined) {
          throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling itemAssetsStore.');
          }

          if (requestParameters.name === null || requestParameters.name === undefined) {
          throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling itemAssetsStore.');
          }

          if (requestParameters.description === null || requestParameters.description === undefined) {
          throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling itemAssetsStore.');
          }

          if (requestParameters.type === null || requestParameters.type === undefined) {
          throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling itemAssetsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
          { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
        if (useForm) {
        formParams = new FormData();
        } else {
        formParams = new URLSearchParams();
        }

            if (requestParameters.item !== undefined) {
              formParams.append('item', requestParameters.item as any);
            }

            if (requestParameters.file !== undefined) {
              formParams.append('file', requestParameters.file as any);
            }

            if (requestParameters.name !== undefined) {
              formParams.append('name', requestParameters.name as any);
            }

            if (requestParameters.description !== undefined) {
              formParams.append('description', requestParameters.description as any);
            }

            if (requestParameters.type !== undefined) {
              formParams.append('type', requestParameters.type as any);
            }

      const response = await this.request({
      path: `/api/money/itemsAssets`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
        body: formParams,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemAssetResponseFromJSON(jsonValue));
      }

        static async itemAssetsStore(item: number, file: Blob, name: string, description: string, type: MoneyItemAssetType, options?: {form?: FormRef}): Promise<{data: MoneyItemAssetResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemAssetsStoreRaw({ item: item, file: file, name: name, description: description, type: type }, options);
          return await response.value();
        }

      private async itemAssetsUpdateRaw(requestParameters: MoneyItemAssetsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemAssetResponse>> {
          if (requestParameters.asset === null || requestParameters.asset === undefined) {
          throw new runtime.RequiredError('asset','Required parameter requestParameters.asset was null or undefined when calling itemAssetsUpdate.');
          }

          if (requestParameters.name === null || requestParameters.name === undefined) {
          throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling itemAssetsUpdate.');
          }

          if (requestParameters.description === null || requestParameters.description === undefined) {
          throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling itemAssetsUpdate.');
          }

          if (requestParameters.type === null || requestParameters.type === undefined) {
          throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling itemAssetsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
          { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
        if (useForm) {
        formParams = new FormData();
        } else {
        formParams = new URLSearchParams();
        }

            if (requestParameters.file !== undefined) {
              formParams.append('file', requestParameters.file as any);
            }

            if (requestParameters.name !== undefined) {
              formParams.append('name', requestParameters.name as any);
            }

            if (requestParameters.description !== undefined) {
              formParams.append('description', requestParameters.description as any);
            }

            if (requestParameters.type !== undefined) {
              formParams.append('type', requestParameters.type as any);
            }

      const response = await this.request({
      path: `/api/money/itemsAssets/{asset}`.replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters.asset))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
        body: formParams,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemAssetResponseFromJSON(jsonValue));
      }

        static async itemAssetsUpdate(asset: number, file: Blob | null, name: string, description: string, type: MoneyItemAssetType, options?: {form?: FormRef}): Promise<{data: MoneyItemAssetResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemAssetsUpdateRaw({ asset: asset, file: file, name: name, description: description, type: type }, options);
          return await response.value();
        }

      private async itemCommentsDeleteRaw(requestParameters: MoneyItemCommentsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.comment === null || requestParameters.comment === undefined) {
          throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling itemCommentsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/itemComments/{comment}`.replace(`{${"comment"}}`, encodeURIComponent(String(requestParameters.comment))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemCommentsDelete(comment: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemCommentsDeleteRaw({ comment: comment }, options);
          return await response.value();
        }

      private async itemCommentsStoreRaw(requestParameters: MoneyItemCommentsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemCommentResponse>> {
          if (requestParameters.moneyItemCommentStore === null || requestParameters.moneyItemCommentStore === undefined) {
          throw new runtime.RequiredError('moneyItemCommentStore','Required parameter requestParameters.moneyItemCommentStore was null or undefined when calling itemCommentsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/itemComments`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemCommentStoreToJSON(requestParameters.moneyItemCommentStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemCommentResponseFromJSON(jsonValue));
      }

        static async itemCommentsStore(moneyItemCommentStore: MoneyItemCommentStore, options?: {form?: FormRef}): Promise<{data: MoneyItemCommentResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemCommentsStoreRaw({ moneyItemCommentStore: moneyItemCommentStore }, options);
          return await response.value();
        }

      private async itemCommentsUpdateRaw(requestParameters: MoneyItemCommentsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemCommentResponse>> {
          if (requestParameters.comment === null || requestParameters.comment === undefined) {
          throw new runtime.RequiredError('comment','Required parameter requestParameters.comment was null or undefined when calling itemCommentsUpdate.');
          }

          if (requestParameters.moneyItemCommentUpdate === null || requestParameters.moneyItemCommentUpdate === undefined) {
          throw new runtime.RequiredError('moneyItemCommentUpdate','Required parameter requestParameters.moneyItemCommentUpdate was null or undefined when calling itemCommentsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/itemComments/{comment}`.replace(`{${"comment"}}`, encodeURIComponent(String(requestParameters.comment))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemCommentUpdateToJSON(requestParameters.moneyItemCommentUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemCommentResponseFromJSON(jsonValue));
      }

        static async itemCommentsUpdate(comment: number, moneyItemCommentUpdate: MoneyItemCommentUpdate, options?: {form?: FormRef}): Promise<{data: MoneyItemCommentResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemCommentsUpdateRaw({ comment: comment, moneyItemCommentUpdate: moneyItemCommentUpdate }, options);
          return await response.value();
        }

      private async itemGroupsDestroyRaw(requestParameters: MoneyItemGroupsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemGroupsDestroy(itemGroup: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsDestroyRaw({ itemGroup: itemGroup }, options);
          return await response.value();
        }

      private async itemGroupsShowRaw(requestParameters: MoneyItemGroupsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemGroupWithItemsResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemGroupWithItemsResponseFromJSON(jsonValue));
      }

        static async itemGroupsShow(itemGroup: number, options?: {form?: FormRef}): Promise<{data: MoneyItemGroupWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsShowRaw({ itemGroup: itemGroup }, options);
          return await response.value();
        }

      private async itemGroupsStoreRaw(requestParameters: MoneyItemGroupsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemGroupWithItemsResponse>> {
          if (requestParameters.moneyItemGroupStore === null || requestParameters.moneyItemGroupStore === undefined) {
          throw new runtime.RequiredError('moneyItemGroupStore','Required parameter requestParameters.moneyItemGroupStore was null or undefined when calling itemGroupsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/itemGroups`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemGroupStoreToJSON(requestParameters.moneyItemGroupStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemGroupWithItemsResponseFromJSON(jsonValue));
      }

        static async itemGroupsStore(moneyItemGroupStore: MoneyItemGroupStore, options?: {form?: FormRef}): Promise<{data: MoneyItemGroupWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsStoreRaw({ moneyItemGroupStore: moneyItemGroupStore }, options);
          return await response.value();
        }

      private async itemGroupsUpdateRaw(requestParameters: MoneyItemGroupsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemGroupWithItemsResponse>> {
          if (requestParameters.itemGroup === null || requestParameters.itemGroup === undefined) {
          throw new runtime.RequiredError('itemGroup','Required parameter requestParameters.itemGroup was null or undefined when calling itemGroupsUpdate.');
          }

          if (requestParameters.moneyItemGroupUpdate === null || requestParameters.moneyItemGroupUpdate === undefined) {
          throw new runtime.RequiredError('moneyItemGroupUpdate','Required parameter requestParameters.moneyItemGroupUpdate was null or undefined when calling itemGroupsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/itemGroups/{itemGroup}`.replace(`{${"itemGroup"}}`, encodeURIComponent(String(requestParameters.itemGroup))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemGroupUpdateToJSON(requestParameters.moneyItemGroupUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemGroupWithItemsResponseFromJSON(jsonValue));
      }

        static async itemGroupsUpdate(itemGroup: number, moneyItemGroupUpdate: MoneyItemGroupUpdate, options?: {form?: FormRef}): Promise<{data: MoneyItemGroupWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemGroupsUpdateRaw({ itemGroup: itemGroup, moneyItemGroupUpdate: moneyItemGroupUpdate }, options);
          return await response.value();
        }

      private async itemsDecideRaw(requestParameters: MoneyItemsDecideRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemsUpdateDefaultResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsDecide.');
          }

          if (requestParameters.moneyItemDecide === null || requestParameters.moneyItemDecide === undefined) {
          throw new runtime.RequiredError('moneyItemDecide','Required parameter requestParameters.moneyItemDecide was null or undefined when calling itemsDecide.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/items/{item}/decide`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemDecideToJSON(requestParameters.moneyItemDecide),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemsUpdateDefaultResponseFromJSON(jsonValue));
      }

        static async itemsDecide(item: number, moneyItemDecide: MoneyItemDecide, options?: {form?: FormRef}): Promise<{data: MoneyItemsUpdateDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsDecideRaw({ item: item, moneyItemDecide: moneyItemDecide }, options);
          return await response.value();
        }

      private async itemsDestroyRaw(requestParameters: MoneyItemsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async itemsDestroy(item: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsDestroyRaw({ item: item }, options);
          return await response.value();
        }

      private async itemsFinalizeRaw(requestParameters: MoneyItemsFinalizeRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemsUpdateDefaultResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsFinalize.');
          }

          if (requestParameters.moneyItemFinalize === null || requestParameters.moneyItemFinalize === undefined) {
          throw new runtime.RequiredError('moneyItemFinalize','Required parameter requestParameters.moneyItemFinalize was null or undefined when calling itemsFinalize.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/items/{item}/finalize`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemFinalizeToJSON(requestParameters.moneyItemFinalize),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemsUpdateDefaultResponseFromJSON(jsonValue));
      }

        static async itemsFinalize(item: number, moneyItemFinalize: MoneyItemFinalize, options?: {form?: FormRef}): Promise<{data: MoneyItemsUpdateDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsFinalizeRaw({ item: item, moneyItemFinalize: moneyItemFinalize }, options);
          return await response.value();
        }

      private async itemsMoveRaw(requestParameters: MoneyItemsMoveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemsUpdateDefaultResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsMove.');
          }

          if (requestParameters.moneyItemMove === null || requestParameters.moneyItemMove === undefined) {
          throw new runtime.RequiredError('moneyItemMove','Required parameter requestParameters.moneyItemMove was null or undefined when calling itemsMove.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/items/{item}/move`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemMoveToJSON(requestParameters.moneyItemMove),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemsUpdateDefaultResponseFromJSON(jsonValue));
      }

        static async itemsMove(item: number, moneyItemMove: MoneyItemMove, options?: {form?: FormRef}): Promise<{data: MoneyItemsUpdateDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsMoveRaw({ item: item, moneyItemMove: moneyItemMove }, options);
          return await response.value();
        }

      private async itemsSearchRaw(requestParameters: MoneyItemsSearchRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemPaginationResponse>> {
          if (requestParameters.perPage === null || requestParameters.perPage === undefined) {
          throw new runtime.RequiredError('perPage','Required parameter requestParameters.perPage was null or undefined when calling itemsSearch.');
          }

          if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
          throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling itemsSearch.');
          }

          if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
          throw new runtime.RequiredError('searchTerm','Required parameter requestParameters.searchTerm was null or undefined when calling itemsSearch.');
          }

      const queryParameters: any = {};

          if (requestParameters.perPage !== undefined) {
              queryParameters['perPage'] = requestParameters.perPage;
          }

          if (requestParameters.currentPage !== undefined) {
              queryParameters['currentPage'] = requestParameters.currentPage;
          }

          if (requestParameters.searchTerm !== undefined) {
              queryParameters['searchTerm'] = requestParameters.searchTerm;
          }

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/items/search`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemPaginationResponseFromJSON(jsonValue));
      }

        static async itemsSearch(perPage: number, currentPage: number, searchTerm: string, options?: {form?: FormRef}): Promise<{data: MoneyItemPaginationResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsSearchRaw({ perPage: perPage, currentPage: currentPage, searchTerm: searchTerm }, options);
          return await response.value();
        }

      private async itemsShowRaw(requestParameters: MoneyItemsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemResponseFromJSON(jsonValue));
      }

        static async itemsShow(item: number, options?: {form?: FormRef}): Promise<{data: MoneyItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsShowRaw({ item: item }, options);
          return await response.value();
        }

      private async itemsStoreRaw(requestParameters: MoneyItemsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemsUpdateDefaultResponse>> {
          if (requestParameters.moneyItemStore === null || requestParameters.moneyItemStore === undefined) {
          throw new runtime.RequiredError('moneyItemStore','Required parameter requestParameters.moneyItemStore was null or undefined when calling itemsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/items`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemStoreToJSON(requestParameters.moneyItemStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemsUpdateDefaultResponseFromJSON(jsonValue));
      }

        static async itemsStore(moneyItemStore: MoneyItemStore, options?: {form?: FormRef}): Promise<{data: MoneyItemsUpdateDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsStoreRaw({ moneyItemStore: moneyItemStore }, options);
          return await response.value();
        }

      private async itemsUpdateRaw(requestParameters: MoneyItemsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemsUpdateDefaultResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling itemsUpdate.');
          }

          if (requestParameters.moneyItemUpdate === null || requestParameters.moneyItemUpdate === undefined) {
          throw new runtime.RequiredError('moneyItemUpdate','Required parameter requestParameters.moneyItemUpdate was null or undefined when calling itemsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/items/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyItemUpdateToJSON(requestParameters.moneyItemUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemsUpdateDefaultResponseFromJSON(jsonValue));
      }

        static async itemsUpdate(item: number, moneyItemUpdate: MoneyItemUpdate, options?: {form?: FormRef}): Promise<{data: MoneyItemsUpdateDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.itemsUpdateRaw({ item: item, moneyItemUpdate: moneyItemUpdate }, options);
          return await response.value();
        }

      private async openTendersDeleteRaw(requestParameters: MoneyOpenTendersDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling openTendersDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/openTenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async openTendersDelete(tender: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.openTendersDeleteRaw({ tender: tender }, options);
          return await response.value();
        }

      private async openTendersShowRaw(requestParameters: MoneyOpenTendersShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyOpenTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling openTendersShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/openTenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyOpenTenderResponseFromJSON(jsonValue));
      }

        static async openTendersShow(tender: number, options?: {form?: FormRef}): Promise<{data: MoneyOpenTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.openTendersShowRaw({ tender: tender }, options);
          return await response.value();
        }

      private async openTendersStoreRaw(requestParameters: MoneyOpenTendersStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyOpenTenderResponse>> {
          if (requestParameters.moneyOpenTenderStore === null || requestParameters.moneyOpenTenderStore === undefined) {
          throw new runtime.RequiredError('moneyOpenTenderStore','Required parameter requestParameters.moneyOpenTenderStore was null or undefined when calling openTendersStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/openTenders`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyOpenTenderStoreToJSON(requestParameters.moneyOpenTenderStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyOpenTenderResponseFromJSON(jsonValue));
      }

        static async openTendersStore(moneyOpenTenderStore: MoneyOpenTenderStore, options?: {form?: FormRef}): Promise<{data: MoneyOpenTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.openTendersStoreRaw({ moneyOpenTenderStore: moneyOpenTenderStore }, options);
          return await response.value();
        }

      private async openTendersUpdateRaw(requestParameters: MoneyOpenTendersUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyOpenTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling openTendersUpdate.');
          }

          if (requestParameters.moneyOpenTenderUpdate === null || requestParameters.moneyOpenTenderUpdate === undefined) {
          throw new runtime.RequiredError('moneyOpenTenderUpdate','Required parameter requestParameters.moneyOpenTenderUpdate was null or undefined when calling openTendersUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/openTenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyOpenTenderUpdateToJSON(requestParameters.moneyOpenTenderUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyOpenTenderResponseFromJSON(jsonValue));
      }

        static async openTendersUpdate(tender: number, moneyOpenTenderUpdate: MoneyOpenTenderUpdate, options?: {form?: FormRef}): Promise<{data: MoneyOpenTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.openTendersUpdateRaw({ tender: tender, moneyOpenTenderUpdate: moneyOpenTenderUpdate }, options);
          return await response.value();
        }

      private async spendingEventsCashInRaw(requestParameters: MoneySpendingEventsCashInRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsCashIn.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsCashIn.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/cashIn`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsCashIn(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsCashInRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsCashOutRaw(requestParameters: MoneySpendingEventsCashOutRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsCashOut.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsCashOut.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/cashOut`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsCashOut(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsCashOutRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsOtherEventRaw(requestParameters: MoneySpendingEventsOtherEventRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsOtherEvent.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsOtherEvent.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/otherEvent`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsOtherEvent(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsOtherEventRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsSpendingSettledRaw(requestParameters: MoneySpendingEventsSpendingSettledRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsSpendingSettled.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsSpendingSettled.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/spendingSettled`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsSpendingSettled(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsSpendingSettledRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsTransferRegisteredRaw(requestParameters: MoneySpendingEventsTransferRegisteredRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsTransferRegistered.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsTransferRegistered.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/transferRegistered`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsTransferRegistered(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsTransferRegisteredRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsTransferRequestedRaw(requestParameters: MoneySpendingEventsTransferRequestedRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsTransferRequested.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsTransferRequested.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/transferRequested`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsTransferRequested(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsTransferRequestedRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingEventsTransferSentRaw(requestParameters: MoneySpendingEventsTransferSentRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemEventResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingEventsTransferSent.');
          }

          if (requestParameters.moneySpendingItemEvent === null || requestParameters.moneySpendingItemEvent === undefined) {
          throw new runtime.RequiredError('moneySpendingItemEvent','Required parameter requestParameters.moneySpendingItemEvent was null or undefined when calling spendingEventsTransferSent.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}/transferSent`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemEventToJSON(requestParameters.moneySpendingItemEvent),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemEventResponseFromJSON(jsonValue));
      }

        static async spendingEventsTransferSent(item: number, moneySpendingItemEvent: MoneySpendingItemEvent, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingEventsTransferSentRaw({ item: item, moneySpendingItemEvent: moneySpendingItemEvent }, options);
          return await response.value();
        }

      private async spendingItemsDeleteRaw(requestParameters: MoneySpendingItemsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingItemsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingItems/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async spendingItemsDelete(item: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingItemsDeleteRaw({ item: item }, options);
          return await response.value();
        }

      private async spendingItemsShowRaw(requestParameters: MoneySpendingItemsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingItemsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingItems/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemResponseFromJSON(jsonValue));
      }

        static async spendingItemsShow(item: number, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingItemsShowRaw({ item: item }, options);
          return await response.value();
        }

      private async spendingItemsStoreRaw(requestParameters: MoneySpendingItemsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemResponse>> {
          if (requestParameters.moneySpendingItemStore === null || requestParameters.moneySpendingItemStore === undefined) {
          throw new runtime.RequiredError('moneySpendingItemStore','Required parameter requestParameters.moneySpendingItemStore was null or undefined when calling spendingItemsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemStoreToJSON(requestParameters.moneySpendingItemStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemResponseFromJSON(jsonValue));
      }

        static async spendingItemsStore(moneySpendingItemStore: MoneySpendingItemStore, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingItemsStoreRaw({ moneySpendingItemStore: moneySpendingItemStore }, options);
          return await response.value();
        }

      private async spendingItemsUpdateRaw(requestParameters: MoneySpendingItemsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneySpendingItemResponse>> {
          if (requestParameters.item === null || requestParameters.item === undefined) {
          throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling spendingItemsUpdate.');
          }

          if (requestParameters.moneySpendingItemUpdate === null || requestParameters.moneySpendingItemUpdate === undefined) {
          throw new runtime.RequiredError('moneySpendingItemUpdate','Required parameter requestParameters.moneySpendingItemUpdate was null or undefined when calling spendingItemsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingItems/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingItemUpdateToJSON(requestParameters.moneySpendingItemUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneySpendingItemResponseFromJSON(jsonValue));
      }

        static async spendingItemsUpdate(item: number, moneySpendingItemUpdate: MoneySpendingItemUpdate, options?: {form?: FormRef}): Promise<{data: MoneySpendingItemResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingItemsUpdateRaw({ item: item, moneySpendingItemUpdate: moneySpendingItemUpdate }, options);
          return await response.value();
        }

      private async spendingPeriodsBudgetRaw(requestParameters: MoneySpendingPeriodsBudgetRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyBudgetCategoryListResponse>> {
          if (requestParameters.spendingPeriod === null || requestParameters.spendingPeriod === undefined) {
          throw new runtime.RequiredError('spendingPeriod','Required parameter requestParameters.spendingPeriod was null or undefined when calling spendingPeriodsBudget.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingPeriods/{spendingPeriod}/budget`.replace(`{${"spendingPeriod"}}`, encodeURIComponent(String(requestParameters.spendingPeriod))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyBudgetCategoryListResponseFromJSON(jsonValue));
      }

        static async spendingPeriodsBudget(spendingPeriod: number, options?: {form?: FormRef}): Promise<{data: MoneyBudgetCategoryListResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingPeriodsBudgetRaw({ spendingPeriod: spendingPeriod }, options);
          return await response.value();
        }

      private async spendingPeriodsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtSpendingPeriodsResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingPeriods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtSpendingPeriodsResponseFromJSON(jsonValue));
      }

        static async spendingPeriodsIndex(options?: {form?: FormRef}): Promise<{data: RvtSpendingPeriodsResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingPeriodsIndexRaw(options);
          return await response.value();
        }

      private async spendingPeriodsShowRaw(requestParameters: MoneySpendingPeriodsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtSpendingPeriodResponse>> {
          if (requestParameters.spendingPeriod === null || requestParameters.spendingPeriod === undefined) {
          throw new runtime.RequiredError('spendingPeriod','Required parameter requestParameters.spendingPeriod was null or undefined when calling spendingPeriodsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingPeriods/{spendingPeriod}`.replace(`{${"spendingPeriod"}}`, encodeURIComponent(String(requestParameters.spendingPeriod))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtSpendingPeriodResponseFromJSON(jsonValue));
      }

        static async spendingPeriodsShow(spendingPeriod: number, options?: {form?: FormRef}): Promise<{data: RvtSpendingPeriodResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingPeriodsShowRaw({ spendingPeriod: spendingPeriod }, options);
          return await response.value();
        }

      private async spendingPeriodsStoreRaw(requestParameters: MoneySpendingPeriodsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtSpendingPeriodResponse>> {
          if (requestParameters.moneySpendingPeriodStore === null || requestParameters.moneySpendingPeriodStore === undefined) {
          throw new runtime.RequiredError('moneySpendingPeriodStore','Required parameter requestParameters.moneySpendingPeriodStore was null or undefined when calling spendingPeriodsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/spendingPeriods`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneySpendingPeriodStoreToJSON(requestParameters.moneySpendingPeriodStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtSpendingPeriodResponseFromJSON(jsonValue));
      }

        static async spendingPeriodsStore(moneySpendingPeriodStore: MoneySpendingPeriodStore, options?: {form?: FormRef}): Promise<{data: RvtSpendingPeriodResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingPeriodsStoreRaw({ moneySpendingPeriodStore: moneySpendingPeriodStore }, options);
          return await response.value();
        }

      private async spendingPeriodsSummaryRaw(requestParameters: MoneySpendingPeriodsSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtSpendingPeriodSummaryResponse>> {
          if (requestParameters.spendingPeriod === null || requestParameters.spendingPeriod === undefined) {
          throw new runtime.RequiredError('spendingPeriod','Required parameter requestParameters.spendingPeriod was null or undefined when calling spendingPeriodsSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/money/spendingPeriods/{spendingPeriod}/summary`.replace(`{${"spendingPeriod"}}`, encodeURIComponent(String(requestParameters.spendingPeriod))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtSpendingPeriodSummaryResponseFromJSON(jsonValue));
      }

        static async spendingPeriodsSummary(spendingPeriod: number, options?: {form?: FormRef}): Promise<{data: RvtSpendingPeriodSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.spendingPeriodsSummaryRaw({ spendingPeriod: spendingPeriod }, options);
          return await response.value();
        }

      private async utilitiesAccountNumberRaw(requestParameters: MoneyUtilitiesAccountNumberRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyAccountCheckResponse>> {
          if (requestParameters.moneyAccountCheck === null || requestParameters.moneyAccountCheck === undefined) {
          throw new runtime.RequiredError('moneyAccountCheck','Required parameter requestParameters.moneyAccountCheck was null or undefined when calling utilitiesAccountNumber.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/money/utilities/accountNumber`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: MoneyAccountCheckToJSON(requestParameters.moneyAccountCheck),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyAccountCheckResponseFromJSON(jsonValue));
      }

        static async utilitiesAccountNumber(moneyAccountCheck: MoneyAccountCheck, options?: {form?: FormRef}): Promise<{data: MoneyAccountCheckResponse, raw: Response}> {
          const api = new this();
          const response = await api.utilitiesAccountNumberRaw({ moneyAccountCheck: moneyAccountCheck }, options);
          return await response.value();
        }

    }
