<template>
    <PegasusHeading>{{ period.name }}</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Kapcsolódó KPR pályázat">
                <dl v-if="tenderSummary">
                    <dt>
                        <PegasusLink :params="{tender: tenderSummary.tender}" to="rvt.kpr.tender.show">
                            {{ tenderSummary.tender.displayName }}
                        </PegasusLink>
                    </dt>
                    <dt>Leadott pályázatok száma</dt>
                    <dd>{{ tenderSummary.applicationsCount }} db</dd>

                    <dt>KPR módosító kérvények (elfogadott / elutasított)</dt>
                    <dd>{{ tenderSummary.successfulAmends }} / {{ tenderSummary.declinedAmends }}
                        (
                        <PegasusLink :params="{tender: tenderSummary.tender}" to="rvt.kpr.tender.amends">
                            Megtekintés
                        </PegasusLink>
                        )
                    </dd>

                    <dt>RVT keretigények (elfogadott / elutasított)</dt>
                    <dd>{{ tenderSummary.successfulClaims }} / {{ tenderSummary.declinedClaims }}
                        (
                        <PegasusLink :params="{tender: tenderSummary.tender}" to="rvt.kpr.tender.claims">
                            Megtekintés
                        </PegasusLink>
                        )
                    </dd>
                </dl>
                <dl v-else>
                    <dt>Még nincsen pályázat létrehozva</dt>
                </dl>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="RVT keret statisztika">
                <dl v-if="tenderSummary">
                    <dt>RVT keret</dt>
                    <dd>{{ tenderSummary.claimsBudget.huf() }}</dd>

                    <dt>KPR módosítások egyenlege</dt>
                    <dd v-if="tenderSummary">{{ tenderSummary.amendsBalance.huf() }}</dd>
                    <dd v-else>nincs KPR pályázat</dd>

                    <dt>Keretigények összege</dt>
                    <dd>{{ tenderSummary.claimsSum.huf() }}</dd>

                    <dt>Elköltetlen keret</dt>
                    <dd>{{ tenderSummary.claimsBalance.huf() }}</dd>
                </dl>
                <dl v-else>
                    <dt>Még nincsen pályázat létrehozva</dt>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költési időszak</title>

<script lang="ts" setup>
import {TenderSummary} from '@Models/rvt/kpr/TenderSummary';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusLink from '@Components/base/PegasusLink.vue';
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';
import {useKprTenders} from '@/queries/kpr/useKprTenders';

const {periodId} = defineProps<{
    periodId: number
}>();

useAuthStore().requireUser();

const {periods} = await useSpendingPeriods().promise.value;
const kprTenderStore = await useKprTenders().promise.value;

const period = periods[periodId];

const tender = kprTenderStore.getBySpendingPeriodId(periodId);

const tenderSummary = tender ? await TenderSummary.getById(tender.id) : null;
</script>
