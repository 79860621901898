<template>
    <PegasusModal header="Új hozzáadása" width="900px">
        <div class="row">
            <div v-for="(name, id) in EventsServiceCategoryValues" :key="id" class="col-lg-4">
                <h4 class="text-center">{{ name }}</h4>

                <div v-for="service in servicesByCategories()[id]" :key="service.id" class="w-100 p-1">
                    <PegasusButton
                        :disabled="!isEmpty(event.services[service.id]) || !service.isAllowedFor(event)"
                        :popover="popover(service, !isEmpty(event.services[service.id]))"
                        block
                        variant="primary"
                        @click="create(service)"
                    >{{ service.name }}
                    </PegasusButton>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-4">
                <h5 class="text-center">Információk</h5>
                <p>
                    Ezeket az igényeket az egyetemnek és a kollégiumi üzemeltetésnek is jóvá kell hagynia, ezért szigorú
                    határidőkkel rendelkeznek. Amint az igény "Elfogadva" státuszba kerül jelenti azt, hogy a porta is
                    megkapta az információt.
                </p>
            </div>
            <div class="col-lg-4">
                <h5 class="text-center">Információk</h5>
                <p>
                    Ezeket a szolgáltatásokat a Ház körei nyújtják. Az "Elfogadva" állapot jelenti azt, hogy a kör
                    visszajelzett és tudják vállalni a felkérést. Különleges kérések esetében ajánlott a kört
                    közvetlenül megkeresni.
                </p>
            </div>
            <div class="col-lg-4">
                <h5 class="text-center">Információk</h5>
                <p>
                    Ezeket az eszközöket az aktuális hetifőnökkel egyeztetett módon lehet átvenni. Az "Elfogadva"
                    állapot jelenti azt, hogy az eszköz rendelkezésre fog állni a rendezvény alatt.
                </p>
            </div>
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {ServiceConfig} from '@Models/events/ServiceConfig';
import Event from '@Models/events/Event';
import {EventsServiceCategory, EventsServiceCategoryValues, EventsServiceType} from '@/api/models';
import {isEmpty} from 'lodash-es';
import {groupBy} from '@Models/Utils';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import type {DateTime} from '@Utils/DateTime';
import {useEventServiceConfigs} from '@/queries/events/useEventServiceConfigs';

const {event, deadlines} = defineProps<{
    event: Event
    deadlines: Record<EventsServiceType, DateTime>
}>();

const emit = defineEmits<{
    create: [type: EventsServiceType]
}>();

const services = await useEventServiceConfigs().promise.value;

function servicesByCategories(): Record<EventsServiceCategory, ServiceConfig[]> {
    return groupBy(services, type => type.category);
}

function create(type: ServiceConfig) {
    emit('create', type.id);
}

function popover(service: ServiceConfig, added: boolean): string | undefined {
    if (added) {
        return undefined;
    }

    if (service.id in deadlines) {
        return 'Határidő: ' + deadlines[service.id].datetime();
    }

    return undefined;
}
</script>
