<template>
    <PegasusHeading>Leltár tétel kereső</PegasusHeading>

    <div class="row">
        <div class="col-lg-3">
            <GroupSelectorField
                v-model="group"
                :disabled="!user.hasPermission(BaseGlobalPermission.INVENTORY_READ)"
                :initial-value="number(params.group)"
                :schema="{name: 'group', label: 'Felelős kör', required: false, nullable: true, type: 'number'}"
                clearable
            ></GroupSelectorField>
        </div>

        <div class="col-lg-3">
            <RoomSelectorField
                :initial-value="string(params.location)"
                :model-value="location ?? null"
                :schema="{name: 'location', label: 'Helyiség', required: false, nullable: true, type: 'number'}"
                clearable
                @update:model-value="value => location = value ?? undefined"
            ></RoomSelectorField>
        </div>

        <div class="col-lg-3">
            <OwnerSelector
                v-model="owner"
                :initial-value="number(params.owner)"
            ></OwnerSelector>
        </div>

        <div class="col-lg-3">
            <SingleTagSelector
                v-model="tag"
                :initial-value="number(params.tag)"
            ></SingleTagSelector>
        </div>
    </div>

    <PegasusTable :data="data" :per-page="10" search>
        <template #header>
            <SelectField
                v-if="groupSummary"
                v-model="itemGroup"
                :options="dictObject(groupSummary.allItemGroups(), itemGroup => itemGroup.name)"
                :schema="InventoryItemDataUpdateSchema.itemGroup"
                inline
            ></SelectField>
        </template>
        <Column field="idAsText" header="Szám"></Column>

        <Column field="name" header="Név"></Column>

        <Column field="count" header="Darabszám"></Column>

        <Column header="Műveletek">
            <template #body="{data: row}">
                <PegasusButton :params="{code: row.idAsText}" to="inventory.items.default" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Schönherz Leltár</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {ref, watch} from 'vue';
import {Item} from '@Models/inventory/Item';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';
import {first, isArray} from 'lodash-es';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import {Group} from '@Models/base/Group';
import type {Room} from '@Models/map/Room';
import RoomSelectorField from '@Components/base/form/types/custom/RoomSelectorField.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {dictObject} from '@Models/Utils';
import type {Owner} from '@Models/inventory/Owner';
import type {Tag} from '@Models/inventory/Tag';
import {BaseGlobalPermission, InventoryItemDataUpdateSchema} from '@/api/models/index';
import type {ItemGroup} from '@Models/inventory/ItemGroup';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import {useUrlSearchParams, whenever} from '@vueuse/core';
import SingleTagSelector from '@Components/inventory/tag/SingleTagSelector.vue';
import OwnerSelector from '@Components/inventory/owners/OwnerSelector.vue';

type Parameters = {
    group?: string;
    itemGroup?: string;
    location?: string;
    owner?: string;
    tag?: string;
};

const user = useAuthStore().requireUser();

const params = useUrlSearchParams('hash-params');

const group = ref<Group | undefined>(undefined);
const groupSummary = ref<InventoryGroupSummary | undefined>(undefined);
watch(group, newValue => {
    groupSummary.value = undefined;

    if (newValue) {
        params.group = newValue.id.toString();
        InventoryGroupSummary.getByGroup(newValue.id).then(summary => {
            groupSummary.value = summary;
        });
    } else {
        delete params.group;
    }
});

const itemGroup = ref<ItemGroup | undefined>(undefined);
watch(itemGroup, newValue => {
    if (newValue) {
        params.itemGroup = newValue.id.toString();
    } else {
        delete params.itemGroup;
    }
});

const location = ref<Room | undefined>(undefined);
watch(location, newValue => {
    if (newValue) {
        params.location = newValue.displayNumber;
    } else {
        delete params.location;
    }
});

const owner = ref<Owner | undefined>(undefined);
watch(owner, newValue => {
    if (newValue) {
        params.owner = newValue.id.toString();
    } else {
        delete params.owner;
    }
});

const tag = ref<Tag | undefined>(undefined);
watch(tag, newValue => {
    if (newValue) {
        params.tag = newValue.id.toString();
    } else {
        delete params.tag;
    }
});

watch(params, newParams => {
    data.value = getFilterFunction(newParams);
});

if ('itemGroup' in params) {
    const itemGroupId = number(params.itemGroup);
    if (itemGroupId) {
        whenever(() => groupSummary.value, value => {
            itemGroup.value = value.allItemGroups()[itemGroupId];
        });
    }
}

const data = ref(getFilterFunction(params));

function getFilterFunction(parameters: Parameters) {
    return async function data(page: number, perPage: number, filter: string | undefined) {
        return await Item.filter({
            page,
            perPage,
            text: string(filter),
            group: number(parameters.group),
            itemGroup: parameters.itemGroup ? number(parameters.itemGroup) : undefined,
            roomNumber: parameters.location,
            tag: number(parameters.tag),
            owner: number(parameters.owner),
        });
    };
}

function string(param: string | string[] | undefined) {
    if (param === undefined) {
        return undefined;
    }

    const string = isArray(param) ? first(param) : param;

    if (!string || string.length === 0) {
        return undefined;
    }

    return string;
}

function number(param: string | string[] | undefined) {
    const parsed = string(param);

    return parsed
        ? parseInt(parsed)
        : undefined;
}
</script>
