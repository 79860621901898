<template>
    <h2 class="text-center">Villanykari Közösségi Ösztöndíj - {{ title }}
        <span class="position-absolute text-end" style="right: 0">
            <PegasusButton :variant="filters.global.value === false ? 'success' : 'danger'" @click="filterToggle">
                Készek szürése
            </PegasusButton>

            <br v-if="groupId !== null"/>

            <PegasusButton variant="warning" @click="refreshCalc()">
                Rendezés frissítése
            </PegasusButton>

            <br v-if="groupId !== null"/>

            <PegasusButton v-if="summary.moderator" :variant="displayAvg ? 'success' : 'danger'" @click="displayAvg = !displayAvg">
                Átlag megjelenítése
            </PegasusButton>
        </span>
    </h2>

    <DataTable
        v-model:filters="filters"
        :global-filter-fields="[(item: Application) => item.id in dones] as unknown as string[]"
        :rows="10"
        :value="toArray(summary.applications)"
        data-key="id"
        paginator
        row-hover
    >
        <Column column-key="done" header="Kész">
            <template #body="{data}: {data: Application}">
                <CheckboxField
                    v-model="dones[data.id]"
                    :disabled="!summary.canEdit"
                    :schema="simpleCheckboxSchema()"
                    without-group
                    @update:model-value="done(data, $event)"
                ></CheckboxField>
            </template>
        </Column>

        <Column :sortable="groupId !== null" field="user.name" header="Név">
            <template #body="{data}: {data: Application}">
                <a href="javascript:void(0)" @click="open(data)">
                    {{ data.user.name }}
                </a>

                <span v-if="data.user.nick">
                    ({{ data.user.nick }})
                </span>

                <a v-if="data.user.pek" :id="'pek' + data.id" :href="'https://pek.sch.bme.hu/profiles/' + data.user.pek" class="float-end" target="_blank">
                    <img alt="PéK" src="@Assets/images/pek_logo.png" style="height:1rem;">
                </a>

                <small v-for="group in data.user.leaderships" :key="group.id" class="d-block">
                    {{ group }} körvezető
                </small>
            </template>
        </Column>

        <Column v-for="reviewer in getAllReviewer()" :key="reviewer.id" :column-key="'reviewer-' + reviewer.id">
            <template #header>
                <div class="w-100 text-center">{{ reviewer.nick }}</div>
            </template>
            <template #body="{data}">
                <Review
                    v-if="data.reviews[reviewer.id]"
                    :can-edit="summary.canEdit"
                    :dark="false"
                    :group-id="groupId"
                    :review="data.reviews[reviewer.id]"
                ></Review>

                <span v-else class="text-center">
                    <i class="fas fa-times text-red"></i>
                </span>
            </template>
        </Column>

        <Column :field="item => aggregates[item.id].dev.toFixed(2)" header="DEV" sortable></Column>

        <Column v-if="displayAvg" :field="item => aggregates[item.id].avg.toFixed(2)" column-key="avg" header="AVG" sortable></Column>
    </DataTable>
</template>

<title>VIKÖ bíráló felület</title>

<script lang="ts" setup>
import Review from "@Components/viko/reviewer/Review.vue";
import {Application} from "@Models/viko/Application";
import {ReviewSummary} from "@Models/viko/ReviewSummary";
import {computed, ref} from "vue";
import DataTable, {type DataTableFilterMetaData} from "primevue/datatable";
import {each, filter, forEach, map, max, min, sum, toArray} from "lodash-es";
import CheckboxField from "@Components/base/form/types/CheckboxField.vue";
import type {User} from "@Models/base/User";
import {FilterMatchMode} from "primevue/api";
import Column from "primevue/column";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useFullWidth} from "@Utils/composables";
import {simpleCheckboxSchema} from '@Models/Utils';
import {useDialog} from '@Utils/dialog';
import ApplicationDialog from '@Components/viko/reviewer/ApplicationDialog.vue';

const {tenderId, groupId = null} = defineProps<{
    tenderId: number,
    groupId?: number
}>();

const dialog = useDialog();

const summary = ref(await ReviewSummary.get(tenderId));

if (groupId === null) {
    useFullWidth();
}

const filters = ref<{global: DataTableFilterMetaData}>({
    global: {value: null, matchMode: FilterMatchMode.EQUALS},
});

const aggregates = ref<Record<number, {avg: number, dev: number, diff: number}>>({});
const dones = ref<Record<number, true>>({});

const displayAvg = ref(false);

// eslint-disable-next-line vue/no-ref-object-reactivity-loss
forEach(summary.value.applications, application => {
    if (groupId === null && application.done) {
        dones.value[application.id] = true;
    }

    if (groupId !== null && application.groupDones.includes(groupId)) {
        dones.value[application.id] = true;
    }

    refreshApplication(application);
});

function refreshApplication(application: Application) {
    const relevantUserIds = getAllReviewer().map(reviewer => reviewer.id);
    const reviews = filter(application.reviews, review => relevantUserIds.includes(review.userId));
    const values = map(reviews, review => review.calculatedPoint);

    const avg = sum(values) / values.length;

    const diff = (max(values) ?? 0) - (min(values) ?? 0);

    const dev = Math.sqrt(
        values
            .map(x => Math.pow(x - avg, 2))
            .reduce((a, b) => a + b)
        / values.length
    );

    aggregates.value[application.id] = {
        avg: avg,
        dev: dev,
        diff: diff,
    };
}

function refreshCalc() {
    forEach(summary.value.applications, application => {
        refreshApplication(application);
    });
}

function filterToggle() {
    if (filters.value.global.value === false) {
        filters.value.global.value = null;
    } else {
        filters.value.global.value = false;
    }
}

function open(application: Application) {
    dialog.open(ApplicationDialog, {
        application: application,
        groupId: groupId,
        reviewer: null,
        reviewers: getAllReviewer()
    });
}

function done(application: Application, done: boolean) {
    application.setDone(groupId, done);
}

function getAllReviewer(): User[] {
    let reviewers: User[] = [];

    each(summary.value.reviewerGroups, group => {
        if (groupId === null || groupId === group.id) {
            reviewers = reviewers.concat(toArray(group.reviewers));
        }
    });

    return reviewers;
}

const title = computed(() => {
    if (groupId === null) {
        return 'Közös bírálás';
    }

    return 'Szervezeti bírálás - ' + summary.value.reviewerGroups[groupId].name;
});
</script>
