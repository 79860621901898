/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupPermissionResponse } from './BaseGroupPermissionResponse';
    import {
    BaseGroupPermissionResponseFromJSON,
    BaseGroupPermissionResponseFromJSONTyped,
    BaseGroupPermissionResponseToJSON,
    BaseGroupPermissionResponseCreateEmpty,
    } from './BaseGroupPermissionResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupPermissionListResponse {
  groupPermissions: Array<BaseGroupPermissionResponse>;
}

export const BaseGroupPermissionListResponseSchema = {
  'groupPermissions': {
    name: 'groupPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupPermissionListResponse(value: object): value is BaseGroupPermissionListResponse {
let isInstance = true;
    isInstance = isInstance && "groupPermissions" in value;

return isInstance;
}

export function BaseGroupPermissionListResponseFromJSON(json: any): BaseGroupPermissionListResponse {
return BaseGroupPermissionListResponseFromJSONTyped(json, false);
}

export function BaseGroupPermissionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupPermissionListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'groupPermissions': ((json['groupPermissions'] as Array<any>).map(BaseGroupPermissionResponseFromJSON)),
    };
  }

  export function BaseGroupPermissionListResponseToJSON(value?: BaseGroupPermissionListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'groupPermissions': ((value.groupPermissions as Array<any>).map(BaseGroupPermissionResponseToJSON)),
    };
  }

  export function BaseGroupPermissionListResponseCreateEmpty(): BaseGroupPermissionListResponse {
  return {
      'groupPermissions': [],
  };
  }

