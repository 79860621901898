<template>
    <PegasusHeading>E-mail sablonok</PegasusHeading>

    <PegasusPanel header="Sablonok">
        <h5>Amit a rendezvényt bejelentő közélő kap</h5>

        <PegasusButton variant="danger" @click="generate('EventsRejected')">
            Rendezvény visszautasítva
        </PegasusButton>
        <PegasusButton variant="success" @click="generate('EventsApproved')">
            Rendezvény jóváhagyva
        </PegasusButton>
        <br/>

        <PegasusButton variant="danger" @click="generate('EventsRequestRejected')">
            Rendezvényen körös felkérés elutasítva
        </PegasusButton>
        <PegasusButton variant="success" @click="generate('EventsRequestApproved')">
            Rendezvényen körös felkérés elfogadva
        </PegasusButton>
        <br/>

        <PegasusButton variant="danger" @click="generate('EventsClearanceRejected')">
            Rendezvényen üzemeltetéses igény elutasítva
        </PegasusButton>
        <PegasusButton variant="success" @click="generate('EventsClearanceApproved')">
            Rendezvényen üzemeltetéses igény elfogadva
        </PegasusButton>

        <h5>Amit a körök kapnak</h5>
        <PegasusButton @click="generate('EventsRequestReceived')">
            Felkérést kap a kör
        </PegasusButton>
        <PegasusButton @click="generate('EventssClosuresNotify')">
            Első emeleti körök lezáratás értesítő
        </PegasusButton>

        <h5>Admin e-mailek</h5>
        <PegasusButton @click="generate('EventsClearanceReceived')">
            Üzemeltetési igény érkezett
        </PegasusButton>
    </PegasusPanel>
</template>

<title>E-mail sablonok</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {useDialog} from '@Utils/dialog';
import EmailPreviewDialog from '@Components/system/emails/EmailPreviewDialog.vue';

useAuthStore().requireUser();

const dialog = useDialog();

async function generate(template: string) {
    dialog.open(EmailPreviewDialog, {template});
}
</script>
