<template>
    <div>
        <i :class="icons[update.type][0] + ' text-white ' + icons[update.type][1]"></i>
        <div class="timeline-item">
            <span class="time">
                <i class="fas fa-user"></i> {{ update.creator.name }}
                <i class="fas fa-clock"></i> {{ update.createdAt.datetime() }}
            </span>
            <h3 class="timeline-header">{{ update.getName() }}</h3>

            <PegasusOverlay
                v-for="text in update.text"
                :key="text.key"
                :content="text.value"
                :header="text.key"
            >
                <div class="timeline-body">
                    <i class="fas fa-circle-info"></i> {{ text.key }}: {{ text.value.limit(30) }}
                </div>
            </PegasusOverlay>

            <PegasusOverlay
                v-if="update.comment"
                :content="update.comment"
                header="Megjegyzés"
            >
                <div class="timeline-body">
                    <i class="fas fa-comment"></i> {{ update.comment.limit(40) }}
                </div>
            </PegasusOverlay>

            <div v-if="update instanceof DataChange" class="timeline-body"
            >
                <a class="cursor-pointer" @click="dialog.open(ModificationViewingDialog, {update})">
                    <i class="fas fa-user-edit"></i> Módosítások áttekintése
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ItemUpdate} from "@Models/inventory/ItemUpdate";
import {DataChange} from "@Models/inventory/updates/DataChange";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import {useDialog} from '@Utils/dialog';
import ModificationViewingDialog from '@Components/inventory/item/dialogs/ModificationViewingDialog.vue';

const dialog = useDialog();

const icons = ItemUpdate.getIcons();

const {update} = defineProps<{
    update: ItemUpdate
}>();
</script>
