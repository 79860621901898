<template>
    <PegasusHeading>{{ group.name }}</PegasusHeading>

    <PegasusPanel header="Beszámolók">
        <TabView :active-index="yearNumbers.indexOf(selectedYear)" @tab-change="tab">
            <TabPanel v-for="year in yearNumbers" :key="year" :header="year.toString()">

                <div class="list-group">
                    <div v-for="month in years[year]" :key="month.id" class="list-group-item">
                        <b>{{ month.monthName.ucfirst() }}</b>

                        <span v-if="canEdit" class="float-end">
                            <PegasusButton size="xs" @click="edit(month)">
                                <i class="fa fa-edit"></i>
                            </PegasusButton>
                        </span>

                        <div v-if="month.statement" class="whitespace">
                            <hr>
                            {{ month.statement.text }}
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </PegasusPanel>

    <PegasusButton :params="{group}" to="rvt.groups.show" variant="primary">
        Vissza a kör oldalára
    </PegasusButton>
</template>

<title>Kör havi beszámolók</title>

<script lang="ts" setup>
import {Group} from "@Models/base/Group";
import {GroupStatement} from "@Models/rvt/statements/GroupStatement";
import {DateTime} from "@Utils/DateTime";
import {StatementMonth} from "@Models/rvt/statements/StatementMonth";
import {computed, ref} from "vue";
import {clone, groupBy, keyBy, mapValues, range} from "lodash-es";
import {useRouter} from "vue-router";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import TabView, {type TabViewChangeEvent} from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import {useAuthStore} from "@/stores/auth";
import {BaseGroupSpecificPermission} from "@/api/models";
import {useDialog} from '@Utils/dialog';
import StatementDialog from '@Components/rvt/statements/StatementDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {groupId, selectedYear = DateTime.now().year} = defineProps<{
    groupId: number,
    selectedYear?: number,
}>();

const router = useRouter();

const user = useAuthStore().requireUser();

const dialog = useDialog();

const group = Group.getBySingleId(groupId);

const yearNumbers = range(DateTime.now().year - 8, DateTime.now().year + 1);

if (!yearNumbers.includes(selectedYear)) {
    throw new NotFoundError('Érvénytelen évszám');
}

let statements = ref(await GroupStatement.getByGroup(group, selectedYear));

const canEdit = computed(() => user.value.hasGroupPermission(group, BaseGroupSpecificPermission.MANAGE_STATEMENT));

const years = computed(() => {
    let mappedStatements = mapValues(
        groupBy(statements.value, (statement: GroupStatement) => statement.year),
        statements => keyBy(statements, statement => statement.month)
    );

    let mappedYears: Record<number, Record<number, StatementMonth>> = {};
    yearNumbers.forEach(year => {
        mappedYears[year] = {};
        range(1, 12 + 1).forEach(month => {
            mappedYears[year][month] = new StatementMonth(year, month, group, mappedStatements[year]?.[month] ?? null);
        });
    });

    return mappedYears;
});

function tab(event: TabViewChangeEvent) {
    const year = yearNumbers[event.index];

    if (selectedYear === year) {
        return;
    }

    const params = clone(router.currentRoute.value.params);

    params.year = year.toString();

    router.replace({
        name: router.currentRoute.value.name ?? '',
        params: params,
    });
}

function edit(month: StatementMonth) {
    const statement = month.statement ?? month.createStatement();

    dialog.open(StatementDialog, {statement, canEdit}).then(result => {
        if (result) {
            statements.value[statement.id] = statement;
        }
    });
}
</script>
