import {BaseEntity} from '@Models/BaseEntity';
import {BaseSeverity, type InventoryTagResponse} from '@/api/api';
import type {Variants} from '@/vue';

export class Tag extends BaseEntity {
    identifier: string;

    name: string;

    description: string;

    severity: BaseSeverity;

    system: boolean;

    constructor(data: InventoryTagResponse) {
        super();
        this.id = data.id;
        this.identifier = data.identifier;
        this.name = data.name;
        this.description = data.description;
        this.severity = data.severity;
        this.system = data.system;
    }

    get primeSeverity(): Variants {
        switch (this.severity) {
            case BaseSeverity.PRIMARY:
            case BaseSeverity.SECONDARY:
            case BaseSeverity.INFO:
                return 'info';
            case BaseSeverity.SUCCESS:
                return 'success';
            case BaseSeverity.WARNING:
                return 'warning';
            case BaseSeverity.DANGER:
                return 'danger';
            case BaseSeverity.LIGHT:
            case BaseSeverity.DARK:
                return 'info';
        }
    }

    get icon(): string {
        switch (this.severity) {
            case BaseSeverity.PRIMARY:
                return 'fa fa-tag';
            case BaseSeverity.SECONDARY:
                return 'fa fa-';
            case BaseSeverity.SUCCESS:
                return 'fa fa-check';
            case BaseSeverity.INFO:
                return 'fa fa-circle-info';
            case BaseSeverity.WARNING:
                return 'fa fa-triangle-exclamation';
            case BaseSeverity.DANGER:
                return 'fa fa-triangle-exclamation';
            case BaseSeverity.LIGHT:
                return 'fa';
            case BaseSeverity.DARK:
                return 'fa';
        }
    }
}
