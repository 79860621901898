<template>
    <PegasusHeading>VIKÖ hosszútávú statisztikák</PegasusHeading>

    <PegasusTable :data="statistics">
        <Column field="tender.displayName" header="Időszak" sortable></Column>
        <Column field="applicationsCount" header="Leadott pályázatok száma"></Column>
        <Column
            :field="data => (data.reportsCount / data.applicationsCount).toFixed(3)"
            header="Átlag beszámoló / pályázat"
        ></Column>
        <Column field="avgCharacterCount" header="Átlag beszámoló karaktermennyiség"></Column>
    </PegasusTable>
</template>

<title>VIKÖ bírálási statisztika</title>

<script lang="ts" setup>
import {ref} from 'vue';
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {TenderSummary} from '@Models/viko/TenderSummary';

useAuthStore().requireUser();

const statistics = ref(await TenderSummary.getAll());
</script>
