<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <CascadeSelect
                    v-model="modelValue"
                    :class="{'p-invalid': isValid === false}"
                    :disabled="props.disabled"
                    :input-id="id"
                    :options="resorts"
                    :pt="{input: {'data-input-name': schema.name}}"
                    class="w-100"
                    option-group-children="children"
                    option-group-label="group.name"
                    option-label="group.name"
                    option-value="group"
                ></CascadeSelect>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from '@Components/base/form/SmartField.vue';
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import type {Group} from '@Models/base/Group';
import CascadeSelect from 'primevue/cascadeselect';
import {useGroups} from '@/queries/base/useGroups';

const modelValue = defineModel<Group | undefined>({required: true});

const props = defineProps<{
    disabled?: boolean;
    clearable?: boolean; // TODO https://github.com/primefaces/primevue/issues/6654, should update to v4 for this
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
    initialValue?: number;
}>();

const groupsStore = await useGroups().promise.value;
const resorts = groupsStore.getCalculated();

if (props.initialValue !== undefined) {
    const initialGroup = groupsStore.groups[props.initialValue];
    if (initialGroup) {
        modelValue.value = initialGroup;
    }
}
</script>
