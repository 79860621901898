<template>
    <PegasusHeading>Rendezvények - {{ year }}</PegasusHeading>

    <div class="row">
        <div v-for="month in 12" :key="month" class="col-lg-4">
            <PegasusPanel :header="new Date(year, month - 1).toLocaleString('hu', {month: 'long'})">
                <p v-for="event in months[month]" :key="event.id">
                    {{ event.start.day.toString().padStart(2, '0') }} :
                    <a @click="dialog.open(ShortEventDialog, {eventData: event})">
                        {{ event.name }}
                    </a>

                    <span v-if="event.decision === null" class="badge bg-light" title="Ellenőrzésre vár">
                        <i class="fa fa-hourglass-start"></i>
                    </span>
                </p>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Rendezvénynaptár</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {useAuthStore} from "@/stores/auth";
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {year} = defineProps<{
    year: number
}>();

useAuthStore().requireUser();

const dialog = useDialog();

let promises = [];
const months: Record<number, Record<number, Event>> = {};

for (let i = 1; i <= 12; i++) {
    promises.push(Event.getByMonth(year, i).then(events => {
        months[i] = events;
    }));
}

await Promise.all(promises);
</script>
