/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { EventsServiceConfigResponse } from './EventsServiceConfigResponse';
    import {
    EventsServiceConfigResponseFromJSON,
    EventsServiceConfigResponseFromJSONTyped,
    EventsServiceConfigResponseToJSON,
    EventsServiceConfigResponseCreateEmpty,
    } from './EventsServiceConfigResponse';
import {DateTime} from '@Utils/DateTime';

export interface EventsServiceConfigListResponse {
  services: Array<EventsServiceConfigResponse>;
}

export const EventsServiceConfigListResponseSchema = {
  'services': {
    name: 'services',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfEventsServiceConfigListResponse(value: object): value is EventsServiceConfigListResponse {
let isInstance = true;
    isInstance = isInstance && "services" in value;

return isInstance;
}

export function EventsServiceConfigListResponseFromJSON(json: any): EventsServiceConfigListResponse {
return EventsServiceConfigListResponseFromJSONTyped(json, false);
}

export function EventsServiceConfigListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceConfigListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'services': ((json['services'] as Array<any>).map(EventsServiceConfigResponseFromJSON)),
    };
  }

  export function EventsServiceConfigListResponseToJSON(value?: EventsServiceConfigListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'services': ((value.services as Array<any>).map(EventsServiceConfigResponseToJSON)),
    };
  }

  export function EventsServiceConfigListResponseCreateEmpty(): EventsServiceConfigListResponse {
  return {
      'services': [],
  };
  }

