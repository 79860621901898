import {useQuery} from '@tanstack/vue-query';
import {EventsApi} from '@/api/apis/index';
import {Location} from '@Models/events/Location';
import {filter, first, map} from 'lodash-es';
import type PublicEvent from '@Models/events/PublicEvent';

class LocationStore {
    constructor(
        public readonly locations: Record<number, Location>,
    ) {
    }

    getEnabledLocations(): Location[] {
        return filter(this.locations, location => location.enabled);
    }

    getDefaultLocation(): Location {
        const location = first(this.getEnabledLocations());

        if (location === undefined) {
            throw new Error('Must have at least one location');
        }

        return location;
    }

    getDisplayLocations(event: PublicEvent): string {
        return map(event.locations, locationId => this.locations[locationId].name).join(', ');
    }

    getDisplayLongLocations(event: PublicEvent): string {
        return map(event.locations, locationId => this.locations[locationId].longName).join(', ');
    }
}

export function useEventLocations() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['eventLocations'],
        queryFn: async (): Promise<LocationStore> => {
            const {data} = await EventsApi.locationsIndex();

            return new LocationStore(
                Location.newRecords(data.locations, Location.parseResponse),
            );
        },
    });
}
