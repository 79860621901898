<template>
    <div class="wrapper">
        <Navbar>
            <template #button>
                <PegasusLink class="navbar-brand" to="events.index">
                    <img
                        alt="bejelentes.sch"
                        height="30px"
                        src="/src/assets/images/logo/navbar/events.svg"
                        style="color: white"
                    />
                </PegasusLink>
            </template>

            <template #navbar-extra>
                <li v-if="user.hasPermission(BaseGlobalPermission.EVENTS_PLANNING)" class="nav-item">
                    <PegasusLink class="nav-link" to="events.planning.index">
                        Nagyteremosztó
                    </PegasusLink>
                </li>

                <li v-if="!isEmpty(editableServices)" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        Felkéréskezelő
                    </a>

                    <ul class="dropdown-menu border-0 shadow">
                        <li v-for="service in editableServices" :key="service.id">
                            <PegasusLink
                                :params="{service: service.id}"
                                class="dropdown-item"
                                to="events.services.index"
                            >
                                {{ service.name }}
                            </PegasusLink>
                        </li>
                    </ul>
                </li>
            </template>
        </Navbar>

        <MainContent></MainContent>

        <CommonFooter></CommonFooter>
    </div>
</template>

<script lang="ts" setup>
import Navbar from '@Pages/Navbar.vue';
import CommonFooter from '@Pages/CommonFooter.vue';
import {BaseGlobalPermission} from '@/api/models';
import {filter, isEmpty} from 'lodash-es';
import {computed} from 'vue';
import {useAuthStore} from '@/stores/auth';
import PegasusLink from '@Components/base/PegasusLink.vue';
import MainContent from '@Components/MainContent.vue';
import {useEventServiceConfigs} from '@/queries/events/useEventServiceConfigs';

const user = useAuthStore().requireUser();

const services = await useEventServiceConfigs().promise.value;

const editableServices = computed(() => filter(services, service => service.canRead(user.value)));
</script>
