<template>
    <SelectField
        v-model="value"
        :options="dictObject(tags, tag => tag.name)"
        :schema="schema"
        clearable
    ></SelectField>
</template>

<script lang="ts" setup>
import {dictObject} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {useInventoryTags} from '@/queries/inventory/useInventoryTags';
import type {Tag} from '@Models/inventory/Tag';
import type {FieldSchema} from '@Components/base/form/FieldProperties';

const value = defineModel<Tag | undefined>({required: true});

const {initialValue} = defineProps<{
    initialValue?: number
}>();

const {tags} = await useInventoryTags().promise.value;

const schema = {
    name: 'tag',
    label: 'Cimke',
    required: false,
    nullable: true,
    type: 'number',
} satisfies FieldSchema;

if (initialValue !== undefined) {
    const initialTag = tags[initialValue];
    if (initialTag) {
        value.value = initialTag;
    }
}
</script>
