/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseEventsConstantsResponse } from './BaseEventsConstantsResponse';
    import {
    BaseEventsConstantsResponseFromJSON,
    BaseEventsConstantsResponseFromJSONTyped,
    BaseEventsConstantsResponseToJSON,
    BaseEventsConstantsResponseCreateEmpty,
    } from './BaseEventsConstantsResponse';
    import type { BaseGroupsConstantsResponse } from './BaseGroupsConstantsResponse';
    import {
    BaseGroupsConstantsResponseFromJSON,
    BaseGroupsConstantsResponseFromJSONTyped,
    BaseGroupsConstantsResponseToJSON,
    BaseGroupsConstantsResponseCreateEmpty,
    } from './BaseGroupsConstantsResponse';
    import type { BaseKprConstantsResponse } from './BaseKprConstantsResponse';
    import {
    BaseKprConstantsResponseFromJSON,
    BaseKprConstantsResponseFromJSONTyped,
    BaseKprConstantsResponseToJSON,
    BaseKprConstantsResponseCreateEmpty,
    } from './BaseKprConstantsResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseConstantsResponse {
  events: BaseEventsConstantsResponse;
  kpr: BaseKprConstantsResponse;
  groups: BaseGroupsConstantsResponse;
}

export const BaseConstantsResponseSchema = {
  'events': {
    name: 'events',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'kpr': {
    name: 'kpr',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'groups': {
    name: 'groups',
    required: true,
    nullable: false,
      type: 'embedded',
  },
}
export function instanceOfBaseConstantsResponse(value: object): value is BaseConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "kpr" in value;
    isInstance = isInstance && "groups" in value;

return isInstance;
}

export function BaseConstantsResponseFromJSON(json: any): BaseConstantsResponse {
return BaseConstantsResponseFromJSONTyped(json, false);
}

export function BaseConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'events': BaseEventsConstantsResponseFromJSON(json['events']),
              'kpr': BaseKprConstantsResponseFromJSON(json['kpr']),
              'groups': BaseGroupsConstantsResponseFromJSON(json['groups']),
    };
  }

  export function BaseConstantsResponseToJSON(value?: BaseConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'events': BaseEventsConstantsResponseToJSON(value.events),
                'kpr': BaseKprConstantsResponseToJSON(value.kpr),
                'groups': BaseGroupsConstantsResponseToJSON(value.groups),
    };
  }

  export function BaseConstantsResponseCreateEmpty(): BaseConstantsResponse {
  return {
          'events': BaseEventsConstantsResponseCreateEmpty(),
          'kpr': BaseKprConstantsResponseCreateEmpty(),
          'groups': BaseGroupsConstantsResponseCreateEmpty(),
  };
  }

