<template>
    <PegasusHeading>Főrendezői tanfolyam</PegasusHeading>

    <p v-if="isEmpty(relevantTrainings)">Jelenleg nincsen tanfolyam kiírva</p>

    <PegasusPanel
        v-for="training in relevantTrainings"
        :key="training.id"
        :header="training.dateHuman + ' - ' + locations[training.locationId].longName"
        class="mb-3"
    >
        <span v-if="training.id in participations">
            Sikeres regisztráció

            <PegasusButton v-if="training.id in participations" variant="danger" @click="unregister(training)">
                Lejelentkezés
            </PegasusButton>
        </span>

        <span v-else>
            <PegasusButton variant="success" @click="register(training)">
                Regisztráció
            </PegasusButton>
        </span>
    </PegasusPanel>
</template>

<title>Főrendezői tanfolyam</title>

<script lang="ts" setup>
import {Training} from "@Models/events/Training";
import {TrainingParticipation} from "@Models/events/TrainingParticipation";
import {promiseAll} from "@Utils/misc";
import {useAuthStore} from "@/stores/auth";
import {computed, ref} from "vue";
import {filter, isEmpty, mapKeys} from "lodash-es";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import {BaseGlobalPermission} from "@/api/models";
import {AccessDeniedError} from "@Utils/errors/AccessDeniedError";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.EVENTS_PLANNING)) {
    throw new AccessDeniedError('Főrendezői tanfolyam részvételhez legalább egy körben tagság szükséges');
}

const {locations} = await useEventLocations().promise.value;

const data = await promiseAll({
    trainings: Training.getAll(),
    participations: Training.getParticipationsForUser(user.value.id),
});

const trainings = ref(data.trainings);
const participations = ref(mapKeys(data.participations, participation => participation.training.id));

const relevantTrainings = computed(() => {
    return filter(trainings.value, training => training.date.isFuture());
});

async function register(training: Training) {
    if (!await toast.confirm('Biztosan regisztrálni szeretnél erre az alkalomra?')) {
        return;
    }

    let participation = TrainingParticipation.createNew(training, user.value);

    let request = participation.store().then(() => {
        participations.value[training.id] = participation;
    });

    toast.loading(request, 'Regisztráció');
}

async function unregister(training: Training) {
    if (!await toast.confirm('Biztosan le szeretnél jelentkezni erről az alkalomról?')) {
        return;
    }

    let request = participations.value[training.id].delete().then(() => {
        delete participations.value[training.id];
    });

    toast.loading(request, 'Lejelentkezés');
}
</script>
