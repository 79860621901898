<template>
    <PegasusHeading>{{ resort.name }}</PegasusHeading>

    <div class="row">
        <div v-for="groupSummary in resortSummary.groups" :key="groupSummary.group.id" class="col-lg-6 mb-2">
            <PegasusPanel :header="groupSummary.group.name">
                <GroupLinks :group="groupSummary.group"></GroupLinks>

                <dl v-if="groupSummary.events">
                    <template v-if="!isEmpty(groupSummary.events.drafting)">
                        <dt>Szerkesztés alatt álló események</dt>
                        <dd v-for="event in groupSummary.events.drafting" :key="event.id">
                            {{ event.name }}
                            <a class="ms-1" @click="dialog.open(ShortEventDialog, {eventData: event})">
                                <i class="fa fa-eye"></i>
                            </a>
                        </dd>
                    </template>

                    <template v-if="!isEmpty(groupSummary.events.waiting)">
                        <dt>Bírálás alatt álló események</dt>
                        <dd v-for="event in groupSummary.events.waiting" :key="event.id">
                            {{ event.name }}
                            <a class="ms-1" @click="dialog.open(ShortEventDialog, {eventData: event})">
                                <i class="fa fa-eye"></i>
                            </a>
                        </dd>
                    </template>

                    <template v-if="!isEmpty(groupSummary.events.future)">
                        <dt>Közelgő események</dt>
                        <dd v-for="event in groupSummary.events.future" :key="event.id">
                            {{ event.name }}
                            <a class="ms-1" @click="dialog.open(ShortEventDialog, {eventData: event})">
                                <i class="fa fa-eye"></i>
                            </a>
                        </dd>
                    </template>
                </dl>

                <dl v-if="groupSummary.inventory">
                    <dt>Leltár állapot</dt>
                    <dd>
                        <GroupStatus :group-summary="groupSummary.inventory"></GroupStatus>
                    </dd>
                </dl>

                <dl v-for="period in groupSummary.periods" :key="period.period.id">
                    <dt v-if="!isEmpty(period.pendingAmends)">
                        Reszortvezető váró módosító kérvények
                    </dt>
                    <dd v-for="amend in period.pendingAmends" :key="amend.id">
                        <PegasusLink :params="{amend}" to="rvt.amends.show">
                            #{{ amend.id }} {{ amend.objective }}
                        </PegasusLink>
                    </dd>

                    <dt v-if="!isEmpty(period.waitingAmends)">
                        RVT-re váró módosító kérvények
                    </dt>
                    <dd v-for="amend in period.waitingAmends" :key="amend.id">
                        <PegasusLink :params="{amend}" to="rvt.amends.show">
                            #{{ amend.id }} {{ amend.objective }}
                        </PegasusLink>
                    </dd>

                    <dt v-if="!isEmpty(period.pendingClaims)">
                        Reszortvezető váró keretigények
                    </dt>
                    <dd v-for="claim in period.pendingClaims" :key="claim.id">
                        <PegasusLink :params="{claim}" to="rvt.claims.show">
                            #{{ claim.id }} {{ claim.objective }}
                        </PegasusLink>
                    </dd>

                    <dt v-if="!isEmpty(period.waitingClaims)">
                        RVT-re váró keretigények
                    </dt>
                    <dd v-for="claim in period.waitingClaims" :key="claim.id">
                        <PegasusLink :params="{claim}" to="rvt.claims.show">
                            #{{ claim.id }} {{ claim.objective }}
                        </PegasusLink>
                    </dd>
                </dl>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Reszort adatlap</title>

<script lang="ts" setup>
import {Group} from '@Models/base/Group';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {ResortSummary} from '@Models/rvt/ResortSummary';
import {isEmpty} from "lodash-es";
import PegasusLink from '@Components/base/PegasusLink.vue';
import GroupLinks from '@Components/rvt/GroupLinks.vue';
import {useDialog} from '@Utils/dialog';
import ShortEventDialog from '@Components/events/ShortEventDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import GroupStatus from '@Components/inventory/group/GroupStatus.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

const dialog = useDialog();

const resort = Group.getBySingleId(groupId);

const resortSummary = await ResortSummary.getById(groupId);
</script>
