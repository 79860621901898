<template>
    <TagChip
        v-for="tagId in item.tags"
        :key="tagId"
        :tag="tags[tagId]"
    ></TagChip>
</template>

<script lang="ts" setup>
import type {Item} from '@Models/inventory/Item';
import TagChip from '@Components/inventory/item/tags/TagChip.vue';
import {useInventoryTags} from '@/queries/inventory/useInventoryTags';

const {item} = defineProps<{
    item: Item
}>();

const {tags} = await useInventoryTags().promise.value;
</script>
