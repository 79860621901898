import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {Claim} from "@Models/rvt/requests/Claim";
import {Tender} from "@Models/rvt/kpr/Tender";
import {Application} from "@Models/rvt/kpr/Application";
import {Amend} from "@Models/rvt/requests/Amend";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import type {RvtGroupPeriodSummaryResponse} from "@/api/api";
import {MainApi} from "@/api/api";

export class GroupPeriodSummary extends Base {
    group: Group;

    period: SpendingPeriod;

    claims: Record<number, Claim>;

    tender: Tender | null;

    application: Application | null;

    amends: Record<number, Amend>;

    static getById(groupId: number, periodId: number): Promise<GroupPeriodSummary> {
        return MainApi.groupsPeriod(groupId, periodId).then(response => {
            return GroupPeriodSummary.newSingle(response.data, this.parseResponse);
        });
    }

    static parseResponse(summary: GroupPeriodSummary, data: RvtGroupPeriodSummaryResponse): GroupPeriodSummary {
        summary.group = Group.getBySingleId(data.groupId);
        summary.period = new SpendingPeriod(data.period);
        summary.claims = Claim.newRecords(data.claims, Claim.parseResponse);
        summary.tender = data.tender ? new Tender(data.tender) : null;
        summary.application = Application.newSingleNullable(data.application, Application.parseWithItems);
        summary.amends = Amend.newRecords(data.amends, Amend.parseResponse);

        return summary;
    }
}
