<template>
    <PegasusTable :data="event.closures" empty-text="Nem tartozik lezáratás a rendezvényhez">
        <Column :field="(closure: Closure) => store.closureTypes[closure.type].name" header="Típus"></Column>

        <Column field="hours" header="Hossz"></Column>

        <Column :field="(closure: Closure) => closure.getPrice(store.closureTypes[closure.type]).huf()">
            <template #header>
                <span class="mx-1">Összeg</span>
                <PegasusHelp title="Lezáratás költsége">
                    (Hossz órában + 2) * óradíj
                </PegasusHelp>
            </template>
        </Column>

        <Column field="" header="Műveletek">
            <template #body="{data}">
                <PegasusButton variant="primary" @click="open(data)">
                    <i v-if="event.canEdit" class="fa fa-edit"></i>
                    <i v-else class="fa fa-eye"></i>
                </PegasusButton>

                <PegasusButton v-if="event.canEdit" variant="danger" @click="remove(data)">
                    <i class="fa fa-trash"></i>
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>

    <PegasusButton v-if="event.canEdit" variant="success" @click="addClosure">
        Új hozzáadása
    </PegasusButton>

    <hr class="m-2">

    <span>Lezáratások összköltsége: {{ sum.huf() }}</span>

    <p>Ez a pénz fedezéséhez a kör esetén szükséges alkalmas elfogadott KPR tétel, elfogadott módosító vagy
        elfogadott RVT keretigény.
        <b>Ez alól pultigénylés megléte sem ad kivételt!</b>
    </p>

    <p>Magánrendezvény esetén egyeztetés szükséges a <a href="mailto:bejelentes@sch.bme.hu">bejelentes@sch.bme.hu</a>
        levelezőlistán!</p>

    <div v-for="closure in disruptingClosures()" :key="closure.id">
        A fenti {{ store.closureTypes[closure.type].name }} lezáratás előtti kiplakátolandó
        <PegasusButton size="sm" variant="primary" @click="pr(closure)">PDF</PegasusButton>
    </div>
</template>

<script lang="ts" setup>
import {Closure} from '@Models/events/Closure';
import Event from '@Models/events/Event';
import toast from '@Utils/toast';
import {computed, ref} from 'vue';
import {each, filter, values} from 'lodash-es';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import {useDialog} from '@Utils/dialog';
import ClosureDialog from '@Components/events/closures/ClosureDialog.vue';
import {useEventClosureTypes} from '@/queries/events/useEventClosureTypes';

const {event} = defineProps<{
    event: Event
}>();

const emit = defineEmits<{
    dirty: [],
    check: []
}>();

const dialog = useDialog();

const maps = ref<Record<number, string[]>>({});

const sum = computed(() => {
    let returns = 0;

    for (let closure of values(event.closures)) {
        returns += closure.getPrice(store.closureTypes[closure.type]);
    }

    return returns;
});

const store = await useEventClosureTypes().promise.value;

each(store.closureTypes, type => {
    type.getMap().then(map => {
        maps.value[type.id] = map;
    });
});

function addClosure() {
    dialog.open(ClosureDialog, {
        closure: Closure.createEmptyFromEvent(event, store.getDefaultClosureType().id),
        event,
        maps,
    }).then(value => {
        if (value) {
            emit('check');
        }
    });
}

function open(closure: Closure) {
    dialog.open(ClosureDialog, {closure, event, maps}).then(value => {
        if (value) {
            emit('check');
        }
    });
}

async function remove(closure: Closure) {
    if (!await toast.confirm('Biztosan törölni szeretnéd ezt az lezáratást?')) {
        return;
    }

    const request = closure.delete().then(() => {
        delete event.closures[closure.id];
    });

    toast.loading(request, 'Törlés');
}

function disruptingClosures() {
    return filter(event.closures, closure => store.closureTypes[closure.type].disrupts);
}

function pr(closure: Closure) {
    let request = closure.pr().then(file => file.download());

    toast.loading(request, 'Generálás');
}
</script>
