import {useQuery} from '@tanstack/vue-query';
import {EventsApi} from '@/api/apis/index';
import {ServiceConfig} from '@Models/events/ServiceConfig';
import type {EventsServiceType} from '@/api/models/index';

export function useEventServiceConfigs() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['eventServiceConfigurations'],
        queryFn: async (): Promise<Record<EventsServiceType, ServiceConfig>> => {
            const {data} = await EventsApi.serviceManagementConfigurations();

            return ServiceConfig.newRecordsBy(data.services, ServiceConfig.parseResponse, config => config.id);
        },
    });
}
