import {useQuery} from '@tanstack/vue-query';
import {Building} from '@Models/map/Building';

export function useMapBuilding() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['mapBuilding'],
        queryFn: async () => {
            return await Building.getBuilding();
        },
    });
}
