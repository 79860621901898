<template>
    <dt>Név</dt>
    <dd>{{ item.name }}</dd>

    <dt>Hivatalos név</dt>
    <dd>{{ item.officialName }}</dd>

    <dt>Leírás</dt>
    <dd class="whitespace">{{ item.description }}</dd>

    <dt>Egyedi azonosító</dt>
    <dd>{{ item.uniqueIdentifier }}</dd>

    <dt>Darabszám</dt>
    <dd>{{ item.count }}</dd>

    <dt>Érték</dt>
    <dd v-if="item.price !== null">{{ item.price.huf() }}</dd>
    <dd v-else>Ismeretlen</dd>

    <dt>Tárgyi eszköz</dt>
    <dd v-if="item.material">Igen</dd>
    <dd v-else>Nem</dd>

    <dt>Helyiség</dt>
    <dd v-if="item.location">{{ item.location.displayName }}</dd>
    <dd v-else>Ismeretlen</dd>

    <dt>Felelős kör</dt>
    <dd>{{ item.responsible.name }}</dd>

    <dt>Tulajdonos szervezet</dt>
    <dd v-if="item.ownerId">{{ owners[item.ownerId].name }}</dd>
    <dd v-else>Ismeretlen</dd>

    <dt>Kategória</dt>
    <dd v-if="item.itemGroupId">{{ summary.allItemGroups()[item.itemGroupId].name }}</dd>
    <dd v-else>-</dd>
</template>

<script lang="ts" setup>
import {Item} from '@Models/inventory/Item';
import type {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import {useInventoryOwners} from '@/queries/inventory/useInventoryOwners';

const {item} = defineProps<{
    item: Item,
    summary: InventoryGroupSummary
}>();

const owners = await useInventoryOwners().promise.value;
</script>
