<template>
    <PegasusHeading>Költési időszakok</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Időszakok">
                <PegasusTable :data="periods">
                    <Column field="name" header="Név"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-6"></div>
    </div>
</template>

<title>Költségvetési tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

useAuthStore().requireUser();

const {periods} = await useSpendingPeriods().promise.value;
</script>
