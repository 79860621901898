import {useQuery} from '@tanstack/vue-query';
import {MoneyApi} from '@/api/apis/index';
import {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {filter, find} from 'lodash-es';
import {DateTime} from '@Utils/DateTime';

class SpendingPeriodsStore {
    constructor(
        public readonly periods: Record<number, SpendingPeriod>,
    ) {
    }

    public getByYear(year: number): SpendingPeriod | null {
        return find(this.periods, period => period.year === year) ?? null;
    }

    public getRelevant() {
        return filter(this.periods, period => period.year >= DateTime.now().year);
    }
}

export function useSpendingPeriods() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['money.spendingPeriods'],
        queryFn: async (): Promise<SpendingPeriodsStore> => {
            const {data} = await MoneyApi.spendingPeriodsIndex();

            return new SpendingPeriodsStore(
                data.periods
                    .map(period => new SpendingPeriod(period))
                    .keyBy(period => period.id),
            );
        },
    });
}
