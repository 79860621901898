<template>
    <PegasusButton
        :params="{group}"
        size="sm"
        title="Adatlap"
        to="rvt.groups.show"
        variant="primary"
    >
        <i class="fa fa-circle-info"></i> Adatlap
    </PegasusButton>

    <PegasusButton
        :params="{group, year}"
        size="sm"
        title="Beszámolók"
        to="rvt.groups.statements"
        variant="primary"
    >
        <i class="fa fa-file-word"></i> Beszámolók
    </PegasusButton>

    <template
        v-if="user.hasGroupPermission(group, [BaseGroupSpecificPermission.MANAGE_MONEY, BaseGroupSpecificPermission.READ_MONEY])">
        <PegasusButton
            v-for="period in periods"
            :key="period.id"
            :params="{group, period: period}"
            :title="period.name"
            size="sm"
            to="rvt.groups.money"
            variant="success"
        >
            <i class="fa fa-sack-dollar"></i> {{ period.name }}
        </PegasusButton>
    </template>

    <PegasusButton
        v-if="user.hasGroupPermission(group, BaseGroupSpecificPermission.MANAGE_EVENTS)"
        :params="{group}"
        size="sm"
        title="Körös rendezvények"
        to="events.group"
        variant="info"
    >
        <i class="fa fa-calendar-days"></i> Rendezvények
    </PegasusButton>

    <PegasusButton
        v-if="user.hasGroupPermission(group, [BaseGroupSpecificPermission.MANAGE_INVENTORY, BaseGroupSpecificPermission.READ_INVENTORY])"
        :params="{group}"
        size="sm"
        title="Leltár"
        to="inventory.groups.index"
        variant="info"
    >
        <i class="fa fa-book"></i> Leltár
    </PegasusButton>
</template>

<script lang="ts" setup>
import {BaseGroupSpecificPermission} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {useAuthStore} from '@/stores/auth';
import type {Group} from '@Models/base/Group';
import {DateTime} from '@Utils/DateTime';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

const {group} = defineProps<{
    group: Group
}>();

const user = useAuthStore().requireUser();

const periodsStore = await useSpendingPeriods().promise.value;

const periods = periodsStore.getRelevant();

const year = DateTime.now().year;
</script>
