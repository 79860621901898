/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryOwnerResponse } from './InventoryOwnerResponse';
    import {
    InventoryOwnerResponseFromJSON,
    InventoryOwnerResponseFromJSONTyped,
    InventoryOwnerResponseToJSON,
    InventoryOwnerResponseCreateEmpty,
    } from './InventoryOwnerResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryOwnerListResponse {
  owners: Array<InventoryOwnerResponse>;
}

export const InventoryOwnerListResponseSchema = {
  'owners': {
    name: 'owners',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfInventoryOwnerListResponse(value: object): value is InventoryOwnerListResponse {
let isInstance = true;
    isInstance = isInstance && "owners" in value;

return isInstance;
}

export function InventoryOwnerListResponseFromJSON(json: any): InventoryOwnerListResponse {
return InventoryOwnerListResponseFromJSONTyped(json, false);
}

export function InventoryOwnerListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryOwnerListResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'owners': ((json['owners'] as Array<any>).map(InventoryOwnerResponseFromJSON)),
    };
  }

  export function InventoryOwnerListResponseToJSON(value?: InventoryOwnerListResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'owners': ((value.owners as Array<any>).map(InventoryOwnerResponseToJSON)),
    };
  }

  export function InventoryOwnerListResponseCreateEmpty(): InventoryOwnerListResponse {
  return {
      'owners': [],
  };
  }

