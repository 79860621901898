import {BaseEntity} from '@Models/BaseEntity';
import {DateTime} from '@Utils/DateTime';
import {Location} from '@Models/events/Location';
import {User} from '@Models/base/User';
import {TrainingParticipation} from '@Models/events/TrainingParticipation';
import type {EventsTrainingResponse, EventsTrainingStore, EventsTrainingUpdate} from '@/api/api';
import {EventsApi} from '@/api/api';
import type {FormRef} from '@/vue';

export class Training extends BaseEntity {
    date: DateTime;

    locationId: number;

    active: boolean;

    participantCount: number;

    static getTrainingMembersForGroup(groupId: number): Promise<Record<number, User>> {
        return EventsApi.groupsTrainingMembers(groupId).then(response => {
            return User.newRecords(response.data.users, User.parseBaseResponse);
        });
    }

    static getParticipationsForUser(userId: string): Promise<Record<number, TrainingParticipation>> {
        return EventsApi.usersTrainingsParticipations(userId).then(response => {
            return TrainingParticipation.newRecords(response.data.participations, TrainingParticipation.parseResponse);
        });
    }

    static getAll(): Promise<Record<number, Training>> {
        return EventsApi.trainingsIndex().then(response => {
            return Training.newRecords(response.data.trainings, Training.parseResponse);
        });
    }

    static getById(id: number): Promise<Training> {
        return EventsApi.trainingsShow(id).then(response => {
            return Training.newSingle(response.data, Training.parseResponse);
        });
    }

    static createNew(location: Location): Training {
        const training = new Training();

        training.date = DateTime.now();
        training.locationId = location.id;
        training.active = true;
        training.participantCount = 0;

        return training;
    }

    static parseResponse(training: Training, data: EventsTrainingResponse): Training {
        training.id = data.id;
        training.date = data.date;
        training.locationId = data.locationId;
        training.active = data.active;
        training.participantCount = data.participantCount;

        return training;
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsTrainingUpdate = {
            date: this.date,
            location: this.locationId,
            active: this.active,
        };

        return EventsApi.trainingsUpdate(this.id, data, {form}).then(response => {
            Training.parseResponse(this, response.data);
        });
    }

    public store(form: FormRef): Promise<Training> {
        const data: EventsTrainingStore = {
            date: this.date,
            location: this.locationId,
            active: this.active,
        };

        return EventsApi.trainingsStore(data, {form}).then(response => {
            return Training.parseResponse(this, response.data);
        });
    }

    get dateHuman(): string {
        return this.date.datetimeShort();
    }
}
