<template>
    <PegasusModal header="Költségvetés létrehozása">
        <PegasusForm ref="form">
            <TextField
                v-model="program.name"
                :schema="MoneyBudgetProgramStoreSchema.name"
            ></TextField>

            <GroupSelectorField
                v-model="program.group"
                :schema="MoneyBudgetProgramStoreSchema.group"
            ></GroupSelectorField>

            <PegasusButton variant="success" @click="save">
                <i class="fa fa-save"></i> Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import {MoneyBudgetProgramStoreSchema} from '@/api/models';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import {BudgetItem} from '@Models/money/BudgetItem';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import {redirect} from '@/router';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: BudgetItem,
}>();

const form = ref<FormRef>(null);

const returnValue = useReturn();

const program = ref(BudgetProgram.createNew(item));

function save() {
    const request = program.value.store(item.id, form.value).then(async () => {
        await redirect({
            name: 'money.budget.program.show',
            params: {
                program: program.value.id
            }
        });

        returnValue(true);
    });

    toast.loading(request);
}
</script>
