<template>
    <Suspense @pending="pending" @resolve="resolve">
        <RouterView></RouterView>
    </Suspense>
</template>

<script lang="ts" setup>
import {RouterView} from 'vue-router';

function resolve() {
    document.body.classList.remove('loading-body');
}

function pending() {
    document.body.classList.add('loading-body');
}
</script>
