<template>
    <PegasusHeading>VIKÖ problémás pályázatok</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Elbírálatlan beszámolók">
                <PegasusTable :data="problems.unreviewedReports">
                    <Column field="user.name"></Column>
                    <Column field="group.name"></Column>
                    <Column header="Műveletek">
                        <template #body="{data}: {data: Report}">
                            <PegasusButton size="sm" variant="primary" @click="openReport(data)">
                                <i class="fas fa-gavel"></i> Bírálás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Visszautasított beszámolók">
                <PegasusTable :data="problems.rejectedReports">
                    <Column field="user.name"></Column>
                    <Column field="group.name"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Report}">
                            <PegasusButton size="sm" variant="primary" @click="openReport(data)">
                                <i class="fas fa-gavel"></i> Bírálás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>

    <PegasusButton to="viko.admin.tender" :params="{tender: problems.tender}" variant="primary">
        <i class="fas fa-fast-backward"></i> Vissza
    </PegasusButton>
</template>

<title>VIKÖ problémás pályázatok</title>

<script lang="ts" setup>
import {ref} from "vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {TenderProblems} from '@Models/viko/TenderProblems';
import {useDialog} from '@Utils/dialog';
import ReportDialog from '@Components/viko/application/ReportDialog.vue';
import {BaseGlobalPermission} from '@/api/models';
import {AccessDeniedError} from '@Utils/errors/AccessDeniedError';
import {Report} from '@Models/viko/Report';

const {tenderId} = defineProps<{
    tenderId: number
}>();

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.VIKO_TENDER)) {
    throw new AccessDeniedError();
}

const dialog = useDialog();

const problems = ref(await TenderProblems.getByTender(tenderId));

function openReport(report: Report) {
    dialog.open(ReportDialog, {report, admin: true}).then(decision => {
        if (decision !== null) {
            delete problems.value.unreviewedReports[report.id];

            if (!decision) {
                problems.value.rejectedReports[report.id] = report;
            }
        }
    });
}
</script>
