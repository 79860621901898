<template>
    <PegasusHeading>Admin</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="KPR pályázatok">
                <PegasusTable :data="tenders" :per-page="5" default-sort="displayName">
                    <Column field="displayName" header="Név" sortable></Column>

                    <Column field="status" header="Státusz"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: Tender}">
                            <PegasusButton :params="{tender: data}" to="rvt.kpr.tender.admin">
                                <i class="fa fa-gears"></i> Adminisztráció
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton variant="success" @click="createKpr">
                    <i class="fa fa-plus"></i> Új pályázat
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="E-mail sablonok">
                <h5>Reszortvezető kapja</h5>

                <PegasusButton variant="danger" @click="generate('RvtRequestReceived')">
                    Kérvény elbírálásra vár
                </PegasusButton>
                <br/>

                <h5>Kérvény leadó kapja</h5>
                <PegasusButton variant="success" @click="generate('RvtRequestPreApproved')">
                    Reszortvezető által jóváhagyva
                </PegasusButton>
                <PegasusButton variant="danger" @click="generate('RvtRequestPreRejected')">
                    Reszortvezető által visszautasítva
                </PegasusButton>
                <br/>
                <PegasusButton variant="success" @click="generate('RvtRequestApproved')">
                    RVT által elfogadva
                </PegasusButton>
                <PegasusButton variant="danger" @click="generate('RvtRequestRejected')">
                    RVT által elutasítva
                </PegasusButton>
                <br/>

                <h5>Admin e-mailek</h5>
                <PegasusButton @click="generate('RvtRequestProcess')">
                    Kérvény jóváhagyva
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Admin</title>

<script lang="ts" setup>
import {useAuthStore} from '@/stores/auth';
import {BaseGlobalPermission} from '@/api/models';
import {AccessDeniedError} from '@Utils/errors/AccessDeniedError';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {useDialog} from '@Utils/dialog';
import EmailPreviewDialog from '@Components/system/emails/EmailPreviewDialog.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import {Tender} from '@Models/rvt/kpr/Tender';
import CreateTenderDialog from '@/components/kpr/tender/CreateTenderDialog.vue';
import {useKprTenders} from '@/queries/kpr/useKprTenders';

const {tenders} = await useKprTenders().promise.value;

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.ADMIN_ACL)) {
    throw new AccessDeniedError();
}

const dialog = useDialog();

async function generate(template: string) {
    return dialog.open(EmailPreviewDialog, {template});
}

function createKpr() {
    dialog.open(CreateTenderDialog);
}
</script>
