import {useQuery} from '@tanstack/vue-query';
import {MainApi} from '@/api/apis/index';
import {GroupPermission} from '@Models/base/GroupPermission';
import {BaseGroupSpecificPermission} from '@/api/models/index';
import {AssignedGroupPermission} from '@Models/base/AssignedGroupPermission';

class GroupPermissionStore {
    constructor(
        public readonly groupPermissions: Record<string, GroupPermission>,
    ) {
    }

    public permissionNames(permissions: BaseGroupSpecificPermission[]): string {
        return permissions.map(permission => this.groupPermissions[permission].name).join(', ');
    }

    public filterAssignable(permissions: AssignedGroupPermission[]): AssignedGroupPermission[] {
        return permissions.filter(permission => this.groupPermissions[permission.permission].canBeAssigned);
    }
}

export function useGroupPermissions() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['auth.groupPermissions'],
        queryFn: async (): Promise<GroupPermissionStore> => {
            const {data} = await MainApi.groupPermissionsIndex();

            return new GroupPermissionStore(
                GroupPermission.newRecords(data.groupPermissions, GroupPermission.parseResponse),
            );
        },
    });
}
