/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseSuccessResponse,
    BaseUserListResponse,
    BaseValidationBagResponse,
    EventsCheckListResponse,
    EventsClosureResponse,
    EventsClosureStore,
    EventsClosureTypeListResponse,
    EventsClosureTypeResponse,
    EventsClosureTypeSummaryResponse,
    EventsClosureUpdate,
    EventsDeadlinesResponse,
    EventsDownloadReport,
    EventsEventListResponse,
    EventsEventStore,
    EventsEventUpdate,
    EventsEventsShowDefaultResponse,
    EventsExtendedEventResponse,
    EventsLocationListResponse,
    EventsLocationResponse,
    EventsLocationUpdate,
    EventsManagerAssignmentsResponse,
    EventsManagerListResponse,
    EventsManagerResponse,
    EventsManagerStore,
    EventsManagerSummaryResponse,
    EventsManagerUpdate,
    EventsManagersAssignmentUpdate,
    EventsMilestoneAdd,
    EventsMinimalEventResponse,
    EventsReservationListResponse,
    EventsReservationPeriodListResponse,
    EventsReservationPeriodResponse,
    EventsReservationPeriodSummaryResponse,
    EventsReservationPeriodUpdate,
    EventsReservationReassign,
    EventsReservationResponse,
    EventsReservationStore,
    EventsReservationUpdate,
    EventsServiceConfigListResponse,
    EventsServiceDecide,
    EventsServiceListResponse,
    EventsServiceRemote,
    EventsServiceResponse,
    EventsServiceStore,
    EventsServiceUpdate,
    EventsTrainingListResponse,
    EventsTrainingParticipationListResponse,
    EventsTrainingParticipationResponse,
    EventsTrainingParticipationUpdate,
    EventsTrainingResponse,
    EventsTrainingStore,
    EventsTrainingUpdate,
    EventsUpdateClosureType,
  } from '../models/index';
    import {
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseUserListResponseFromJSON,
      BaseUserListResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      EventsCheckListResponseFromJSON,
      EventsCheckListResponseToJSON,
      EventsClosureResponseFromJSON,
      EventsClosureResponseToJSON,
      EventsClosureStoreFromJSON,
      EventsClosureStoreToJSON,
      EventsClosureTypeListResponseFromJSON,
      EventsClosureTypeListResponseToJSON,
      EventsClosureTypeResponseFromJSON,
      EventsClosureTypeResponseToJSON,
      EventsClosureTypeSummaryResponseFromJSON,
      EventsClosureTypeSummaryResponseToJSON,
      EventsClosureUpdateFromJSON,
      EventsClosureUpdateToJSON,
      EventsDeadlinesResponseFromJSON,
      EventsDeadlinesResponseToJSON,
      EventsDownloadReportFromJSON,
      EventsDownloadReportToJSON,
      EventsEventListResponseFromJSON,
      EventsEventListResponseToJSON,
      EventsEventStoreFromJSON,
      EventsEventStoreToJSON,
      EventsEventUpdateFromJSON,
      EventsEventUpdateToJSON,
      EventsEventsShowDefaultResponseFromJSON,
      EventsEventsShowDefaultResponseToJSON,
      EventsExtendedEventResponseFromJSON,
      EventsExtendedEventResponseToJSON,
      EventsLocationListResponseFromJSON,
      EventsLocationListResponseToJSON,
      EventsLocationResponseFromJSON,
      EventsLocationResponseToJSON,
      EventsLocationUpdateFromJSON,
      EventsLocationUpdateToJSON,
      EventsManagerAssignmentsResponseFromJSON,
      EventsManagerAssignmentsResponseToJSON,
      EventsManagerListResponseFromJSON,
      EventsManagerListResponseToJSON,
      EventsManagerResponseFromJSON,
      EventsManagerResponseToJSON,
      EventsManagerStoreFromJSON,
      EventsManagerStoreToJSON,
      EventsManagerSummaryResponseFromJSON,
      EventsManagerSummaryResponseToJSON,
      EventsManagerUpdateFromJSON,
      EventsManagerUpdateToJSON,
      EventsManagersAssignmentUpdateFromJSON,
      EventsManagersAssignmentUpdateToJSON,
      EventsMilestoneAddFromJSON,
      EventsMilestoneAddToJSON,
      EventsMinimalEventResponseFromJSON,
      EventsMinimalEventResponseToJSON,
      EventsReservationListResponseFromJSON,
      EventsReservationListResponseToJSON,
      EventsReservationPeriodListResponseFromJSON,
      EventsReservationPeriodListResponseToJSON,
      EventsReservationPeriodResponseFromJSON,
      EventsReservationPeriodResponseToJSON,
      EventsReservationPeriodSummaryResponseFromJSON,
      EventsReservationPeriodSummaryResponseToJSON,
      EventsReservationPeriodUpdateFromJSON,
      EventsReservationPeriodUpdateToJSON,
      EventsReservationReassignFromJSON,
      EventsReservationReassignToJSON,
      EventsReservationResponseFromJSON,
      EventsReservationResponseToJSON,
      EventsReservationStoreFromJSON,
      EventsReservationStoreToJSON,
      EventsReservationUpdateFromJSON,
      EventsReservationUpdateToJSON,
      EventsServiceConfigListResponseFromJSON,
      EventsServiceConfigListResponseToJSON,
      EventsServiceDecideFromJSON,
      EventsServiceDecideToJSON,
      EventsServiceListResponseFromJSON,
      EventsServiceListResponseToJSON,
      EventsServiceRemoteFromJSON,
      EventsServiceRemoteToJSON,
      EventsServiceResponseFromJSON,
      EventsServiceResponseToJSON,
      EventsServiceStoreFromJSON,
      EventsServiceStoreToJSON,
      EventsServiceUpdateFromJSON,
      EventsServiceUpdateToJSON,
      EventsTrainingListResponseFromJSON,
      EventsTrainingListResponseToJSON,
      EventsTrainingParticipationListResponseFromJSON,
      EventsTrainingParticipationListResponseToJSON,
      EventsTrainingParticipationResponseFromJSON,
      EventsTrainingParticipationResponseToJSON,
      EventsTrainingParticipationUpdateFromJSON,
      EventsTrainingParticipationUpdateToJSON,
      EventsTrainingResponseFromJSON,
      EventsTrainingResponseToJSON,
      EventsTrainingStoreFromJSON,
      EventsTrainingStoreToJSON,
      EventsTrainingUpdateFromJSON,
      EventsTrainingUpdateToJSON,
      EventsUpdateClosureTypeFromJSON,
      EventsUpdateClosureTypeToJSON,
    } from '../models/index';

      export interface EventsClosureTypesDeleteRequest {
        closureType: number;
      }

      export interface EventsClosureTypesShowRequest {
        closureType: number;
      }

      export interface EventsClosureTypesUpdateRequest {
        closureType: number;
        eventsUpdateClosureType: EventsUpdateClosureType;
      }

      export interface EventsClosuresDestroyRequest {
        closure: number;
      }

      export interface EventsClosuresPrRequest {
        closure: number;
      }

      export interface EventsClosuresStoreRequest {
        eventsClosureStore: EventsClosureStore;
      }

      export interface EventsClosuresUpdateRequest {
        closure: number;
        eventsClosureUpdate: EventsClosureUpdate;
      }

      export interface EventsEventsByMonthRequest {
        year: number;
        month: number;
      }

      export interface EventsEventsCheckRequest {
        event: number;
      }

      export interface EventsEventsDeadlinesRequest {
        event: number;
      }

      export interface EventsEventsReportRequest {
        event: number;
      }

      export interface EventsEventsShortRequest {
        event: number;
      }

      export interface EventsEventsShowRequest {
        event: number;
      }

      export interface EventsEventsStoreRequest {
        eventsEventStore: EventsEventStore;
      }

      export interface EventsEventsUpdateRequest {
        event: number;
        eventsEventUpdate: EventsEventUpdate;
      }

      export interface EventsExportsPermitRequest {
        event: number;
      }

      export interface EventsExportsReportRequest {
        eventsDownloadReport: EventsDownloadReport;
      }

      export interface EventsGroupsArchiveRequest {
        group: number;
        year: number;
      }

      export interface EventsGroupsEventsRequest {
        group: number;
      }

      export interface EventsGroupsReservationsRequest {
        group: number;
      }

      export interface EventsGroupsTrainingMembersRequest {
        group: number;
      }

      export interface EventsLocationsUpdateRequest {
        location: number;
        eventsLocationUpdate: EventsLocationUpdate;
      }

      export interface EventsManagersImageRequest {
        manager: number;
      }

      export interface EventsManagersStoreRequest {
        eventsManagerStore: EventsManagerStore;
      }

      export interface EventsManagersUpdateRequest {
        manager: number;
        eventsManagerUpdate: EventsManagerUpdate;
      }

      export interface EventsManagersUpdateAssignmentRequest {
        eventsManagersAssignmentUpdate: EventsManagersAssignmentUpdate;
      }

      export interface EventsManagersUpdateImageRequest {
        manager: number;
        file: Blob;
      }

      export interface EventsMilestonesAddRequest {
        event: number;
        type: string;
        eventsMilestoneAdd: EventsMilestoneAdd;
      }

      export interface EventsReservationPeriodsExportRequest {
        period: number;
      }

      export interface EventsReservationPeriodsShowRequest {
        period: number;
      }

      export interface EventsReservationPeriodsUpdateRequest {
        period: number;
        eventsReservationPeriodUpdate: EventsReservationPeriodUpdate;
      }

      export interface EventsReservationsDeleteRequest {
        reservation: number;
      }

      export interface EventsReservationsIndexRequest {
        year: number;
        month: number;
      }

      export interface EventsReservationsReassignRequest {
        reservation: number;
        eventsReservationReassign: EventsReservationReassign;
      }

      export interface EventsReservationsShowRequest {
        reservation: number;
      }

      export interface EventsReservationsStoreRequest {
        eventsReservationStore: EventsReservationStore;
      }

      export interface EventsReservationsUpdateRequest {
        reservation: number;
        eventsReservationUpdate: EventsReservationUpdate;
      }

      export interface EventsServiceManagementPendingRequest {
        type: string;
      }

      export interface EventsServiceManagementUpcomingRequest {
        type: string;
      }

      export interface EventsServicesDecideRequest {
        service: number;
        eventsServiceDecide: EventsServiceDecide;
      }

      export interface EventsServicesDestroyRequest {
        service: number;
      }

      export interface EventsServicesExportRequest {
        service: number;
      }

      export interface EventsServicesRemoteRequest {
        service: number;
        eventsServiceRemote: EventsServiceRemote;
      }

      export interface EventsServicesSendRequest {
        service: number;
      }

      export interface EventsServicesStoreRequest {
        eventsServiceStore: EventsServiceStore;
      }

      export interface EventsServicesUpdateRequest {
        service: number;
        eventsServiceUpdate: EventsServiceUpdate;
      }

      export interface EventsTrainingParticipationsDeleteRequest {
        training: number;
        user: string;
      }

      export interface EventsTrainingParticipationsIndexRequest {
        training: number;
      }

      export interface EventsTrainingParticipationsShowRequest {
        training: number;
        user: string;
      }

      export interface EventsTrainingParticipationsStoreRequest {
        training: number;
        user: string;
      }

      export interface EventsTrainingParticipationsUpdateRequest {
        training: number;
        user: string;
        eventsTrainingParticipationUpdate: EventsTrainingParticipationUpdate;
      }

      export interface EventsTrainingsDestroyRequest {
        training: number;
      }

      export interface EventsTrainingsShowRequest {
        training: number;
      }

      export interface EventsTrainingsStoreRequest {
        eventsTrainingStore: EventsTrainingStore;
      }

      export interface EventsTrainingsUpdateRequest {
        training: number;
        eventsTrainingUpdate: EventsTrainingUpdate;
      }

      export interface EventsUsersAllEventsRequest {
        user: string;
      }

      export interface EventsUsersRelevantEventsRequest {
        user: string;
      }

      export interface EventsUsersReservationsRequest {
        user: string;
      }

      export interface EventsUsersTrainingsParticipationsRequest {
        user: string;
      }

      export class EventsApi extends runtime.BaseAPI {

      private async closureTypesDeleteRaw(requestParameters: EventsClosureTypesDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.closureType === null || requestParameters.closureType === undefined) {
          throw new runtime.RequiredError('closureType','Required parameter requestParameters.closureType was null or undefined when calling closureTypesDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/closureTypes/{closureType}`.replace(`{${"closureType"}}`, encodeURIComponent(String(requestParameters.closureType))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async closureTypesDelete(closureType: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.closureTypesDeleteRaw({ closureType: closureType }, options);
          return await response.value();
        }

      private async closureTypesIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsClosureTypeListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/closureTypes`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsClosureTypeListResponseFromJSON(jsonValue));
      }

        static async closureTypesIndex(options?: {form?: FormRef}): Promise<{data: EventsClosureTypeListResponse, raw: Response}> {
          const api = new this();
          const response = await api.closureTypesIndexRaw(options);
          return await response.value();
        }

      private async closureTypesShowRaw(requestParameters: EventsClosureTypesShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsClosureTypeSummaryResponse>> {
          if (requestParameters.closureType === null || requestParameters.closureType === undefined) {
          throw new runtime.RequiredError('closureType','Required parameter requestParameters.closureType was null or undefined when calling closureTypesShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/closureTypes/{closureType}`.replace(`{${"closureType"}}`, encodeURIComponent(String(requestParameters.closureType))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsClosureTypeSummaryResponseFromJSON(jsonValue));
      }

        static async closureTypesShow(closureType: number, options?: {form?: FormRef}): Promise<{data: EventsClosureTypeSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.closureTypesShowRaw({ closureType: closureType }, options);
          return await response.value();
        }

      private async closureTypesUpdateRaw(requestParameters: EventsClosureTypesUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsClosureTypeResponse>> {
          if (requestParameters.closureType === null || requestParameters.closureType === undefined) {
          throw new runtime.RequiredError('closureType','Required parameter requestParameters.closureType was null or undefined when calling closureTypesUpdate.');
          }

          if (requestParameters.eventsUpdateClosureType === null || requestParameters.eventsUpdateClosureType === undefined) {
          throw new runtime.RequiredError('eventsUpdateClosureType','Required parameter requestParameters.eventsUpdateClosureType was null or undefined when calling closureTypesUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/closureTypes/{closureType}`.replace(`{${"closureType"}}`, encodeURIComponent(String(requestParameters.closureType))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsUpdateClosureTypeToJSON(requestParameters.eventsUpdateClosureType),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsClosureTypeResponseFromJSON(jsonValue));
      }

        static async closureTypesUpdate(closureType: number, eventsUpdateClosureType: EventsUpdateClosureType, options?: {form?: FormRef}): Promise<{data: EventsClosureTypeResponse, raw: Response}> {
          const api = new this();
          const response = await api.closureTypesUpdateRaw({ closureType: closureType, eventsUpdateClosureType: eventsUpdateClosureType }, options);
          return await response.value();
        }

      private async closuresDestroyRaw(requestParameters: EventsClosuresDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.closure === null || requestParameters.closure === undefined) {
          throw new runtime.RequiredError('closure','Required parameter requestParameters.closure was null or undefined when calling closuresDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/closures/{closure}`.replace(`{${"closure"}}`, encodeURIComponent(String(requestParameters.closure))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async closuresDestroy(closure: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.closuresDestroyRaw({ closure: closure }, options);
          return await response.value();
        }

      private async closuresPrRaw(requestParameters: EventsClosuresPrRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.closure === null || requestParameters.closure === undefined) {
          throw new runtime.RequiredError('closure','Required parameter requestParameters.closure was null or undefined when calling closuresPr.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/closures/{closure}/pr`.replace(`{${"closure"}}`, encodeURIComponent(String(requestParameters.closure))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async closuresPr(closure: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.closuresPrRaw({ closure: closure }, options);
          return await response.value();
        }

      private async closuresStoreRaw(requestParameters: EventsClosuresStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsClosureResponse>> {
          if (requestParameters.eventsClosureStore === null || requestParameters.eventsClosureStore === undefined) {
          throw new runtime.RequiredError('eventsClosureStore','Required parameter requestParameters.eventsClosureStore was null or undefined when calling closuresStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/closures`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsClosureStoreToJSON(requestParameters.eventsClosureStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsClosureResponseFromJSON(jsonValue));
      }

        static async closuresStore(eventsClosureStore: EventsClosureStore, options?: {form?: FormRef}): Promise<{data: EventsClosureResponse, raw: Response}> {
          const api = new this();
          const response = await api.closuresStoreRaw({ eventsClosureStore: eventsClosureStore }, options);
          return await response.value();
        }

      private async closuresUpdateRaw(requestParameters: EventsClosuresUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsClosureResponse>> {
          if (requestParameters.closure === null || requestParameters.closure === undefined) {
          throw new runtime.RequiredError('closure','Required parameter requestParameters.closure was null or undefined when calling closuresUpdate.');
          }

          if (requestParameters.eventsClosureUpdate === null || requestParameters.eventsClosureUpdate === undefined) {
          throw new runtime.RequiredError('eventsClosureUpdate','Required parameter requestParameters.eventsClosureUpdate was null or undefined when calling closuresUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/closures/{closure}`.replace(`{${"closure"}}`, encodeURIComponent(String(requestParameters.closure))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsClosureUpdateToJSON(requestParameters.eventsClosureUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsClosureResponseFromJSON(jsonValue));
      }

        static async closuresUpdate(closure: number, eventsClosureUpdate: EventsClosureUpdate, options?: {form?: FormRef}): Promise<{data: EventsClosureResponse, raw: Response}> {
          const api = new this();
          const response = await api.closuresUpdateRaw({ closure: closure, eventsClosureUpdate: eventsClosureUpdate }, options);
          return await response.value();
        }

      private async eventsByMonthRaw(requestParameters: EventsEventsByMonthRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling eventsByMonth.');
          }

          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling eventsByMonth.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/month/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async eventsByMonth(year: number, month: number, options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsByMonthRaw({ year: year, month: month }, options);
          return await response.value();
        }

      private async eventsCheckRaw(requestParameters: EventsEventsCheckRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsCheckListResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsCheck.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}/check`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsCheckListResponseFromJSON(jsonValue));
      }

        static async eventsCheck(event: number, options?: {form?: FormRef}): Promise<{data: EventsCheckListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsCheckRaw({ event: event }, options);
          return await response.value();
        }

      private async eventsDeadlinesRaw(requestParameters: EventsEventsDeadlinesRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsDeadlinesResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsDeadlines.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}/deadlines`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsDeadlinesResponseFromJSON(jsonValue));
      }

        static async eventsDeadlines(event: number, options?: {form?: FormRef}): Promise<{data: EventsDeadlinesResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsDeadlinesRaw({ event: event }, options);
          return await response.value();
        }

      private async eventsNextRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/next`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async eventsNext(options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsNextRaw(options);
          return await response.value();
        }

      private async eventsReportRaw(requestParameters: EventsEventsReportRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsExtendedEventResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsReport.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}/report`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsExtendedEventResponseFromJSON(jsonValue));
      }

        static async eventsReport(event: number, options?: {form?: FormRef}): Promise<{data: EventsExtendedEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsReportRaw({ event: event }, options);
          return await response.value();
        }

      private async eventsShortRaw(requestParameters: EventsEventsShortRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsMinimalEventResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsShort.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}/short`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsMinimalEventResponseFromJSON(jsonValue));
      }

        static async eventsShort(event: number, options?: {form?: FormRef}): Promise<{data: EventsMinimalEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsShortRaw({ event: event }, options);
          return await response.value();
        }

      private async eventsShowRaw(requestParameters: EventsEventsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventsShowDefaultResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventsShowDefaultResponseFromJSON(jsonValue));
      }

        static async eventsShow(event: number, options?: {form?: FormRef}): Promise<{data: EventsEventsShowDefaultResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsShowRaw({ event: event }, options);
          return await response.value();
        }

      private async eventsStoreRaw(requestParameters: EventsEventsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsExtendedEventResponse>> {
          if (requestParameters.eventsEventStore === null || requestParameters.eventsEventStore === undefined) {
          throw new runtime.RequiredError('eventsEventStore','Required parameter requestParameters.eventsEventStore was null or undefined when calling eventsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/event`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsEventStoreToJSON(requestParameters.eventsEventStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsExtendedEventResponseFromJSON(jsonValue));
      }

        static async eventsStore(eventsEventStore: EventsEventStore, options?: {form?: FormRef}): Promise<{data: EventsExtendedEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsStoreRaw({ eventsEventStore: eventsEventStore }, options);
          return await response.value();
        }

      private async eventsUpdateRaw(requestParameters: EventsEventsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsExtendedEventResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling eventsUpdate.');
          }

          if (requestParameters.eventsEventUpdate === null || requestParameters.eventsEventUpdate === undefined) {
          throw new runtime.RequiredError('eventsEventUpdate','Required parameter requestParameters.eventsEventUpdate was null or undefined when calling eventsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/event/{event}`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsEventUpdateToJSON(requestParameters.eventsEventUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsExtendedEventResponseFromJSON(jsonValue));
      }

        static async eventsUpdate(event: number, eventsEventUpdate: EventsEventUpdate, options?: {form?: FormRef}): Promise<{data: EventsExtendedEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsUpdateRaw({ event: event, eventsEventUpdate: eventsEventUpdate }, options);
          return await response.value();
        }

      private async eventsWaitingApproveRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/waitingApprove`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async eventsWaitingApprove(options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsWaitingApproveRaw(options);
          return await response.value();
        }

      private async eventsWaitingReportRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/waitingReport`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async eventsWaitingReport(options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsWaitingReportRaw(options);
          return await response.value();
        }

      private async eventsWaitingReportAckRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/waitingReportAck`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async eventsWaitingReportAck(options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.eventsWaitingReportAckRaw(options);
          return await response.value();
        }

      private async exportsPermitRaw(requestParameters: EventsExportsPermitRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling exportsPermit.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/event/{event}/export/permit`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async exportsPermit(event: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.exportsPermitRaw({ event: event }, options);
          return await response.value();
        }

      private async exportsReportRaw(requestParameters: EventsExportsReportRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.eventsDownloadReport === null || requestParameters.eventsDownloadReport === undefined) {
          throw new runtime.RequiredError('eventsDownloadReport','Required parameter requestParameters.eventsDownloadReport was null or undefined when calling exportsReport.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/event/downloadReport`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsDownloadReportToJSON(requestParameters.eventsDownloadReport),
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async exportsReport(eventsDownloadReport: EventsDownloadReport, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.exportsReportRaw({ eventsDownloadReport: eventsDownloadReport }, options);
          return await response.value();
        }

      private async groupsArchiveRaw(requestParameters: EventsGroupsArchiveRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsArchive.');
          }

          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling groupsArchive.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/group/{group}/events/archive/{year}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async groupsArchive(group: number, year: number, options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsArchiveRaw({ group: group, year: year }, options);
          return await response.value();
        }

      private async groupsEventsRaw(requestParameters: EventsGroupsEventsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsEvents.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/group/{group}/events`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async groupsEvents(group: number, options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsEventsRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsReservationsRaw(requestParameters: EventsGroupsReservationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsReservations.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/group/{group}/reservations`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationListResponseFromJSON(jsonValue));
      }

        static async groupsReservations(group: number, options?: {form?: FormRef}): Promise<{data: EventsReservationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsReservationsRaw({ group: group }, options);
          return await response.value();
        }

      private async groupsTrainingMembersRaw(requestParameters: EventsGroupsTrainingMembersRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseUserListResponse>> {
          if (requestParameters.group === null || requestParameters.group === undefined) {
          throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling groupsTrainingMembers.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/group/{group}/trainingMembers`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserListResponseFromJSON(jsonValue));
      }

        static async groupsTrainingMembers(group: number, options?: {form?: FormRef}): Promise<{data: BaseUserListResponse, raw: Response}> {
          const api = new this();
          const response = await api.groupsTrainingMembersRaw({ group: group }, options);
          return await response.value();
        }

      private async locationsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsLocationListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/locations`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsLocationListResponseFromJSON(jsonValue));
      }

        static async locationsIndex(options?: {form?: FormRef}): Promise<{data: EventsLocationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.locationsIndexRaw(options);
          return await response.value();
        }

      private async locationsUpdateRaw(requestParameters: EventsLocationsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsLocationResponse>> {
          if (requestParameters.location === null || requestParameters.location === undefined) {
          throw new runtime.RequiredError('location','Required parameter requestParameters.location was null or undefined when calling locationsUpdate.');
          }

          if (requestParameters.eventsLocationUpdate === null || requestParameters.eventsLocationUpdate === undefined) {
          throw new runtime.RequiredError('eventsLocationUpdate','Required parameter requestParameters.eventsLocationUpdate was null or undefined when calling locationsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/locations/{location}`.replace(`{${"location"}}`, encodeURIComponent(String(requestParameters.location))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsLocationUpdateToJSON(requestParameters.eventsLocationUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsLocationResponseFromJSON(jsonValue));
      }

        static async locationsUpdate(location: number, eventsLocationUpdate: EventsLocationUpdate, options?: {form?: FormRef}): Promise<{data: EventsLocationResponse, raw: Response}> {
          const api = new this();
          const response = await api.locationsUpdateRaw({ location: location, eventsLocationUpdate: eventsLocationUpdate }, options);
          return await response.value();
        }

      private async managersGetAssignmentsRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsManagerAssignmentsResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/managersAssignments`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsManagerAssignmentsResponseFromJSON(jsonValue));
      }

        static async managersGetAssignments(options?: {form?: FormRef}): Promise<{data: EventsManagerAssignmentsResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersGetAssignmentsRaw(options);
          return await response.value();
        }

      private async managersImageRaw(requestParameters: EventsManagersImageRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.manager === null || requestParameters.manager === undefined) {
          throw new runtime.RequiredError('manager','Required parameter requestParameters.manager was null or undefined when calling managersImage.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/managers/{manager}/image`.replace(`{${"manager"}}`, encodeURIComponent(String(requestParameters.manager))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async managersImage(manager: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.managersImageRaw({ manager: manager }, options);
          return await response.value();
        }

      private async managersIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsManagerListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/managers`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsManagerListResponseFromJSON(jsonValue));
      }

        static async managersIndex(options?: {form?: FormRef}): Promise<{data: EventsManagerListResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersIndexRaw(options);
          return await response.value();
        }

      private async managersStoreRaw(requestParameters: EventsManagersStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsManagerResponse>> {
          if (requestParameters.eventsManagerStore === null || requestParameters.eventsManagerStore === undefined) {
          throw new runtime.RequiredError('eventsManagerStore','Required parameter requestParameters.eventsManagerStore was null or undefined when calling managersStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/managers`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsManagerStoreToJSON(requestParameters.eventsManagerStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsManagerResponseFromJSON(jsonValue));
      }

        static async managersStore(eventsManagerStore: EventsManagerStore, options?: {form?: FormRef}): Promise<{data: EventsManagerResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersStoreRaw({ eventsManagerStore: eventsManagerStore }, options);
          return await response.value();
        }

      private async managersSummaryRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsManagerSummaryResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/managers/summary`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsManagerSummaryResponseFromJSON(jsonValue));
      }

        static async managersSummary(options?: {form?: FormRef}): Promise<{data: EventsManagerSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersSummaryRaw(options);
          return await response.value();
        }

      private async managersUpdateRaw(requestParameters: EventsManagersUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsManagerResponse>> {
          if (requestParameters.manager === null || requestParameters.manager === undefined) {
          throw new runtime.RequiredError('manager','Required parameter requestParameters.manager was null or undefined when calling managersUpdate.');
          }

          if (requestParameters.eventsManagerUpdate === null || requestParameters.eventsManagerUpdate === undefined) {
          throw new runtime.RequiredError('eventsManagerUpdate','Required parameter requestParameters.eventsManagerUpdate was null or undefined when calling managersUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/managers/{manager}`.replace(`{${"manager"}}`, encodeURIComponent(String(requestParameters.manager))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsManagerUpdateToJSON(requestParameters.eventsManagerUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsManagerResponseFromJSON(jsonValue));
      }

        static async managersUpdate(manager: number, eventsManagerUpdate: EventsManagerUpdate, options?: {form?: FormRef}): Promise<{data: EventsManagerResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersUpdateRaw({ manager: manager, eventsManagerUpdate: eventsManagerUpdate }, options);
          return await response.value();
        }

      private async managersUpdateAssignmentRaw(requestParameters: EventsManagersUpdateAssignmentRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.eventsManagersAssignmentUpdate === null || requestParameters.eventsManagersAssignmentUpdate === undefined) {
          throw new runtime.RequiredError('eventsManagersAssignmentUpdate','Required parameter requestParameters.eventsManagersAssignmentUpdate was null or undefined when calling managersUpdateAssignment.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/managersAssignments`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsManagersAssignmentUpdateToJSON(requestParameters.eventsManagersAssignmentUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async managersUpdateAssignment(eventsManagersAssignmentUpdate: EventsManagersAssignmentUpdate, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersUpdateAssignmentRaw({ eventsManagersAssignmentUpdate: eventsManagersAssignmentUpdate }, options);
          return await response.value();
        }

      private async managersUpdateImageRaw(requestParameters: EventsManagersUpdateImageRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.manager === null || requestParameters.manager === undefined) {
          throw new runtime.RequiredError('manager','Required parameter requestParameters.manager was null or undefined when calling managersUpdateImage.');
          }

          if (requestParameters.file === null || requestParameters.file === undefined) {
          throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling managersUpdateImage.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
          { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
        if (useForm) {
        formParams = new FormData();
        } else {
        formParams = new URLSearchParams();
        }

            if (requestParameters.file !== undefined) {
              formParams.append('file', requestParameters.file as any);
            }

      const response = await this.request({
      path: `/api/events/managers/{manager}/image`.replace(`{${"manager"}}`, encodeURIComponent(String(requestParameters.manager))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
        body: formParams,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async managersUpdateImage(manager: number, file: Blob, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.managersUpdateImageRaw({ manager: manager, file: file }, options);
          return await response.value();
        }

      private async milestonesAddRaw(requestParameters: EventsMilestonesAddRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsExtendedEventResponse>> {
          if (requestParameters.event === null || requestParameters.event === undefined) {
          throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling milestonesAdd.');
          }

          if (requestParameters.type === null || requestParameters.type === undefined) {
          throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling milestonesAdd.');
          }

          if (requestParameters.eventsMilestoneAdd === null || requestParameters.eventsMilestoneAdd === undefined) {
          throw new runtime.RequiredError('eventsMilestoneAdd','Required parameter requestParameters.eventsMilestoneAdd was null or undefined when calling milestonesAdd.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/event/{event}/milestones/{type}`.replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsMilestoneAddToJSON(requestParameters.eventsMilestoneAdd),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsExtendedEventResponseFromJSON(jsonValue));
      }

        static async milestonesAdd(event: number, type: string, eventsMilestoneAdd: EventsMilestoneAdd, options?: {form?: FormRef}): Promise<{data: EventsExtendedEventResponse, raw: Response}> {
          const api = new this();
          const response = await api.milestonesAddRaw({ event: event, type: type, eventsMilestoneAdd: eventsMilestoneAdd }, options);
          return await response.value();
        }

      private async reservationPeriodsExportRaw(requestParameters: EventsReservationPeriodsExportRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reservationPeriodsExport.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservationPeriods/{period}/export`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async reservationPeriodsExport(period: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.reservationPeriodsExportRaw({ period: period }, options);
          return await response.value();
        }

      private async reservationPeriodsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationPeriodListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservationPeriods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationPeriodListResponseFromJSON(jsonValue));
      }

        static async reservationPeriodsIndex(options?: {form?: FormRef}): Promise<{data: EventsReservationPeriodListResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationPeriodsIndexRaw(options);
          return await response.value();
        }

      private async reservationPeriodsShowRaw(requestParameters: EventsReservationPeriodsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationPeriodSummaryResponse>> {
          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reservationPeriodsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservationPeriods/{period}`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationPeriodSummaryResponseFromJSON(jsonValue));
      }

        static async reservationPeriodsShow(period: number, options?: {form?: FormRef}): Promise<{data: EventsReservationPeriodSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationPeriodsShowRaw({ period: period }, options);
          return await response.value();
        }

      private async reservationPeriodsUpdateRaw(requestParameters: EventsReservationPeriodsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationPeriodResponse>> {
          if (requestParameters.period === null || requestParameters.period === undefined) {
          throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling reservationPeriodsUpdate.');
          }

          if (requestParameters.eventsReservationPeriodUpdate === null || requestParameters.eventsReservationPeriodUpdate === undefined) {
          throw new runtime.RequiredError('eventsReservationPeriodUpdate','Required parameter requestParameters.eventsReservationPeriodUpdate was null or undefined when calling reservationPeriodsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/reservationPeriods/{period}`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsReservationPeriodUpdateToJSON(requestParameters.eventsReservationPeriodUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationPeriodResponseFromJSON(jsonValue));
      }

        static async reservationPeriodsUpdate(period: number, eventsReservationPeriodUpdate: EventsReservationPeriodUpdate, options?: {form?: FormRef}): Promise<{data: EventsReservationPeriodResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationPeriodsUpdateRaw({ period: period, eventsReservationPeriodUpdate: eventsReservationPeriodUpdate }, options);
          return await response.value();
        }

      private async reservationsDeleteRaw(requestParameters: EventsReservationsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
          throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservations/{reservation}`.replace(`{${"reservation"}}`, encodeURIComponent(String(requestParameters.reservation))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async reservationsDelete(reservation: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsDeleteRaw({ reservation: reservation }, options);
          return await response.value();
        }

      private async reservationsIndexRaw(requestParameters: EventsReservationsIndexRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationListResponse>> {
          if (requestParameters.year === null || requestParameters.year === undefined) {
          throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling reservationsIndex.');
          }

          if (requestParameters.month === null || requestParameters.month === undefined) {
          throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling reservationsIndex.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservations/month/{year}/{month}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationListResponseFromJSON(jsonValue));
      }

        static async reservationsIndex(year: number, month: number, options?: {form?: FormRef}): Promise<{data: EventsReservationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsIndexRaw({ year: year, month: month }, options);
          return await response.value();
        }

      private async reservationsReassignRaw(requestParameters: EventsReservationsReassignRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationResponse>> {
          if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
          throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationsReassign.');
          }

          if (requestParameters.eventsReservationReassign === null || requestParameters.eventsReservationReassign === undefined) {
          throw new runtime.RequiredError('eventsReservationReassign','Required parameter requestParameters.eventsReservationReassign was null or undefined when calling reservationsReassign.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/reservations/{reservation}/reassign`.replace(`{${"reservation"}}`, encodeURIComponent(String(requestParameters.reservation))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsReservationReassignToJSON(requestParameters.eventsReservationReassign),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationResponseFromJSON(jsonValue));
      }

        static async reservationsReassign(reservation: number, eventsReservationReassign: EventsReservationReassign, options?: {form?: FormRef}): Promise<{data: EventsReservationResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsReassignRaw({ reservation: reservation, eventsReservationReassign: eventsReservationReassign }, options);
          return await response.value();
        }

      private async reservationsShowRaw(requestParameters: EventsReservationsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationResponse>> {
          if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
          throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/reservations/{reservation}`.replace(`{${"reservation"}}`, encodeURIComponent(String(requestParameters.reservation))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationResponseFromJSON(jsonValue));
      }

        static async reservationsShow(reservation: number, options?: {form?: FormRef}): Promise<{data: EventsReservationResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsShowRaw({ reservation: reservation }, options);
          return await response.value();
        }

      private async reservationsStoreRaw(requestParameters: EventsReservationsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationResponse>> {
          if (requestParameters.eventsReservationStore === null || requestParameters.eventsReservationStore === undefined) {
          throw new runtime.RequiredError('eventsReservationStore','Required parameter requestParameters.eventsReservationStore was null or undefined when calling reservationsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/reservations`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsReservationStoreToJSON(requestParameters.eventsReservationStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationResponseFromJSON(jsonValue));
      }

        static async reservationsStore(eventsReservationStore: EventsReservationStore, options?: {form?: FormRef}): Promise<{data: EventsReservationResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsStoreRaw({ eventsReservationStore: eventsReservationStore }, options);
          return await response.value();
        }

      private async reservationsUpdateRaw(requestParameters: EventsReservationsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationResponse>> {
          if (requestParameters.reservation === null || requestParameters.reservation === undefined) {
          throw new runtime.RequiredError('reservation','Required parameter requestParameters.reservation was null or undefined when calling reservationsUpdate.');
          }

          if (requestParameters.eventsReservationUpdate === null || requestParameters.eventsReservationUpdate === undefined) {
          throw new runtime.RequiredError('eventsReservationUpdate','Required parameter requestParameters.eventsReservationUpdate was null or undefined when calling reservationsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/reservations/{reservation}`.replace(`{${"reservation"}}`, encodeURIComponent(String(requestParameters.reservation))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsReservationUpdateToJSON(requestParameters.eventsReservationUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationResponseFromJSON(jsonValue));
      }

        static async reservationsUpdate(reservation: number, eventsReservationUpdate: EventsReservationUpdate, options?: {form?: FormRef}): Promise<{data: EventsReservationResponse, raw: Response}> {
          const api = new this();
          const response = await api.reservationsUpdateRaw({ reservation: reservation, eventsReservationUpdate: eventsReservationUpdate }, options);
          return await response.value();
        }

      private async serviceManagementConfigurationsRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceConfigListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/serviceManagement`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceConfigListResponseFromJSON(jsonValue));
      }

        static async serviceManagementConfigurations(options?: {form?: FormRef}): Promise<{data: EventsServiceConfigListResponse, raw: Response}> {
          const api = new this();
          const response = await api.serviceManagementConfigurationsRaw(options);
          return await response.value();
        }

      private async serviceManagementPendingRaw(requestParameters: EventsServiceManagementPendingRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceListResponse>> {
          if (requestParameters.type === null || requestParameters.type === undefined) {
          throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling serviceManagementPending.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/serviceManagement/{type}/pending`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceListResponseFromJSON(jsonValue));
      }

        static async serviceManagementPending(type: string, options?: {form?: FormRef}): Promise<{data: EventsServiceListResponse, raw: Response}> {
          const api = new this();
          const response = await api.serviceManagementPendingRaw({ type: type }, options);
          return await response.value();
        }

      private async serviceManagementUpcomingRaw(requestParameters: EventsServiceManagementUpcomingRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceListResponse>> {
          if (requestParameters.type === null || requestParameters.type === undefined) {
          throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling serviceManagementUpcoming.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/serviceManagement/{type}/upcoming`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceListResponseFromJSON(jsonValue));
      }

        static async serviceManagementUpcoming(type: string, options?: {form?: FormRef}): Promise<{data: EventsServiceListResponse, raw: Response}> {
          const api = new this();
          const response = await api.serviceManagementUpcomingRaw({ type: type }, options);
          return await response.value();
        }

      private async servicesDecideRaw(requestParameters: EventsServicesDecideRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceResponse>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesDecide.');
          }

          if (requestParameters.eventsServiceDecide === null || requestParameters.eventsServiceDecide === undefined) {
          throw new runtime.RequiredError('eventsServiceDecide','Required parameter requestParameters.eventsServiceDecide was null or undefined when calling servicesDecide.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/services/{service}/decide`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsServiceDecideToJSON(requestParameters.eventsServiceDecide),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceResponseFromJSON(jsonValue));
      }

        static async servicesDecide(service: number, eventsServiceDecide: EventsServiceDecide, options?: {form?: FormRef}): Promise<{data: EventsServiceResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesDecideRaw({ service: service, eventsServiceDecide: eventsServiceDecide }, options);
          return await response.value();
        }

      private async servicesDestroyRaw(requestParameters: EventsServicesDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/services/{service}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async servicesDestroy(service: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesDestroyRaw({ service: service }, options);
          return await response.value();
        }

      private async servicesExportRaw(requestParameters: EventsServicesExportRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesExport.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/services/{service}/export`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async servicesExport(service: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.servicesExportRaw({ service: service }, options);
          return await response.value();
        }

      private async servicesRemoteRaw(requestParameters: EventsServicesRemoteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceResponse>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesRemote.');
          }

          if (requestParameters.eventsServiceRemote === null || requestParameters.eventsServiceRemote === undefined) {
          throw new runtime.RequiredError('eventsServiceRemote','Required parameter requestParameters.eventsServiceRemote was null or undefined when calling servicesRemote.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/services/{service}/remote`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsServiceRemoteToJSON(requestParameters.eventsServiceRemote),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceResponseFromJSON(jsonValue));
      }

        static async servicesRemote(service: number, eventsServiceRemote: EventsServiceRemote, options?: {form?: FormRef}): Promise<{data: EventsServiceResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesRemoteRaw({ service: service, eventsServiceRemote: eventsServiceRemote }, options);
          return await response.value();
        }

      private async servicesSendRaw(requestParameters: EventsServicesSendRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceResponse>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesSend.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/services/{service}/send`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceResponseFromJSON(jsonValue));
      }

        static async servicesSend(service: number, options?: {form?: FormRef}): Promise<{data: EventsServiceResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesSendRaw({ service: service }, options);
          return await response.value();
        }

      private async servicesStoreRaw(requestParameters: EventsServicesStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceResponse>> {
          if (requestParameters.eventsServiceStore === null || requestParameters.eventsServiceStore === undefined) {
          throw new runtime.RequiredError('eventsServiceStore','Required parameter requestParameters.eventsServiceStore was null or undefined when calling servicesStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/services`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsServiceStoreToJSON(requestParameters.eventsServiceStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceResponseFromJSON(jsonValue));
      }

        static async servicesStore(eventsServiceStore: EventsServiceStore, options?: {form?: FormRef}): Promise<{data: EventsServiceResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesStoreRaw({ eventsServiceStore: eventsServiceStore }, options);
          return await response.value();
        }

      private async servicesUpdateRaw(requestParameters: EventsServicesUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceResponse>> {
          if (requestParameters.service === null || requestParameters.service === undefined) {
          throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling servicesUpdate.');
          }

          if (requestParameters.eventsServiceUpdate === null || requestParameters.eventsServiceUpdate === undefined) {
          throw new runtime.RequiredError('eventsServiceUpdate','Required parameter requestParameters.eventsServiceUpdate was null or undefined when calling servicesUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/services/{service}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsServiceUpdateToJSON(requestParameters.eventsServiceUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceResponseFromJSON(jsonValue));
      }

        static async servicesUpdate(service: number, eventsServiceUpdate: EventsServiceUpdate, options?: {form?: FormRef}): Promise<{data: EventsServiceResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesUpdateRaw({ service: service, eventsServiceUpdate: eventsServiceUpdate }, options);
          return await response.value();
        }

      private async servicesWaitingAckRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/services/waitingAck`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceListResponseFromJSON(jsonValue));
      }

        static async servicesWaitingAck(options?: {form?: FormRef}): Promise<{data: EventsServiceListResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesWaitingAckRaw(options);
          return await response.value();
        }

      private async servicesWaitingClearanceRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsServiceListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/services/waiting`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsServiceListResponseFromJSON(jsonValue));
      }

        static async servicesWaitingClearance(options?: {form?: FormRef}): Promise<{data: EventsServiceListResponse, raw: Response}> {
          const api = new this();
          const response = await api.servicesWaitingClearanceRaw(options);
          return await response.value();
        }

      private async trainingParticipationsDeleteRaw(requestParameters: EventsTrainingParticipationsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingParticipationsDelete.');
          }

          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling trainingParticipationsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}/participations/{user}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))).replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async trainingParticipationsDelete(training: number, user: string, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingParticipationsDeleteRaw({ training: training, user: user }, options);
          return await response.value();
        }

      private async trainingParticipationsIndexRaw(requestParameters: EventsTrainingParticipationsIndexRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingParticipationListResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingParticipationsIndex.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}/participations`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingParticipationListResponseFromJSON(jsonValue));
      }

        static async trainingParticipationsIndex(training: number, options?: {form?: FormRef}): Promise<{data: EventsTrainingParticipationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingParticipationsIndexRaw({ training: training }, options);
          return await response.value();
        }

      private async trainingParticipationsShowRaw(requestParameters: EventsTrainingParticipationsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingParticipationResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingParticipationsShow.');
          }

          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling trainingParticipationsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}/participations/{user}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))).replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingParticipationResponseFromJSON(jsonValue));
      }

        static async trainingParticipationsShow(training: number, user: string, options?: {form?: FormRef}): Promise<{data: EventsTrainingParticipationResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingParticipationsShowRaw({ training: training, user: user }, options);
          return await response.value();
        }

      private async trainingParticipationsStoreRaw(requestParameters: EventsTrainingParticipationsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingParticipationResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingParticipationsStore.');
          }

          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling trainingParticipationsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}/participations/{user}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))).replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingParticipationResponseFromJSON(jsonValue));
      }

        static async trainingParticipationsStore(training: number, user: string, options?: {form?: FormRef}): Promise<{data: EventsTrainingParticipationResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingParticipationsStoreRaw({ training: training, user: user }, options);
          return await response.value();
        }

      private async trainingParticipationsUpdateRaw(requestParameters: EventsTrainingParticipationsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingParticipationResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingParticipationsUpdate.');
          }

          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling trainingParticipationsUpdate.');
          }

          if (requestParameters.eventsTrainingParticipationUpdate === null || requestParameters.eventsTrainingParticipationUpdate === undefined) {
          throw new runtime.RequiredError('eventsTrainingParticipationUpdate','Required parameter requestParameters.eventsTrainingParticipationUpdate was null or undefined when calling trainingParticipationsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/trainings/{training}/participations/{user}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))).replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsTrainingParticipationUpdateToJSON(requestParameters.eventsTrainingParticipationUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingParticipationResponseFromJSON(jsonValue));
      }

        static async trainingParticipationsUpdate(training: number, user: string, eventsTrainingParticipationUpdate: EventsTrainingParticipationUpdate, options?: {form?: FormRef}): Promise<{data: EventsTrainingParticipationResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingParticipationsUpdateRaw({ training: training, user: user, eventsTrainingParticipationUpdate: eventsTrainingParticipationUpdate }, options);
          return await response.value();
        }

      private async trainingsDestroyRaw(requestParameters: EventsTrainingsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async trainingsDestroy(training: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingsDestroyRaw({ training: training }, options);
          return await response.value();
        }

      private async trainingsIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingListResponseFromJSON(jsonValue));
      }

        static async trainingsIndex(options?: {form?: FormRef}): Promise<{data: EventsTrainingListResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingsIndexRaw(options);
          return await response.value();
        }

      private async trainingsShowRaw(requestParameters: EventsTrainingsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/trainings/{training}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingResponseFromJSON(jsonValue));
      }

        static async trainingsShow(training: number, options?: {form?: FormRef}): Promise<{data: EventsTrainingResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingsShowRaw({ training: training }, options);
          return await response.value();
        }

      private async trainingsStoreRaw(requestParameters: EventsTrainingsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingResponse>> {
          if (requestParameters.eventsTrainingStore === null || requestParameters.eventsTrainingStore === undefined) {
          throw new runtime.RequiredError('eventsTrainingStore','Required parameter requestParameters.eventsTrainingStore was null or undefined when calling trainingsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/trainings`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: EventsTrainingStoreToJSON(requestParameters.eventsTrainingStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingResponseFromJSON(jsonValue));
      }

        static async trainingsStore(eventsTrainingStore: EventsTrainingStore, options?: {form?: FormRef}): Promise<{data: EventsTrainingResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingsStoreRaw({ eventsTrainingStore: eventsTrainingStore }, options);
          return await response.value();
        }

      private async trainingsUpdateRaw(requestParameters: EventsTrainingsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingResponse>> {
          if (requestParameters.training === null || requestParameters.training === undefined) {
          throw new runtime.RequiredError('training','Required parameter requestParameters.training was null or undefined when calling trainingsUpdate.');
          }

          if (requestParameters.eventsTrainingUpdate === null || requestParameters.eventsTrainingUpdate === undefined) {
          throw new runtime.RequiredError('eventsTrainingUpdate','Required parameter requestParameters.eventsTrainingUpdate was null or undefined when calling trainingsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/events/trainings/{training}`.replace(`{${"training"}}`, encodeURIComponent(String(requestParameters.training))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: EventsTrainingUpdateToJSON(requestParameters.eventsTrainingUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingResponseFromJSON(jsonValue));
      }

        static async trainingsUpdate(training: number, eventsTrainingUpdate: EventsTrainingUpdate, options?: {form?: FormRef}): Promise<{data: EventsTrainingResponse, raw: Response}> {
          const api = new this();
          const response = await api.trainingsUpdateRaw({ training: training, eventsTrainingUpdate: eventsTrainingUpdate }, options);
          return await response.value();
        }

      private async usersAllEventsRaw(requestParameters: EventsUsersAllEventsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersAllEvents.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/users/{user}/events/all`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async usersAllEvents(user: string, options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersAllEventsRaw({ user: user }, options);
          return await response.value();
        }

      private async usersRelevantEventsRaw(requestParameters: EventsUsersRelevantEventsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsEventListResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersRelevantEvents.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/users/{user}/events/relevant`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsEventListResponseFromJSON(jsonValue));
      }

        static async usersRelevantEvents(user: string, options?: {form?: FormRef}): Promise<{data: EventsEventListResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersRelevantEventsRaw({ user: user }, options);
          return await response.value();
        }

      private async usersReservationsRaw(requestParameters: EventsUsersReservationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsReservationListResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersReservations.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/users/{user}/reservations`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsReservationListResponseFromJSON(jsonValue));
      }

        static async usersReservations(user: string, options?: {form?: FormRef}): Promise<{data: EventsReservationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersReservationsRaw({ user: user }, options);
          return await response.value();
        }

      private async usersTrainingsParticipationsRaw(requestParameters: EventsUsersTrainingsParticipationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<EventsTrainingParticipationListResponse>> {
          if (requestParameters.user === null || requestParameters.user === undefined) {
          throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling usersTrainingsParticipations.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/events/users/{user}/trainings`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => EventsTrainingParticipationListResponseFromJSON(jsonValue));
      }

        static async usersTrainingsParticipations(user: string, options?: {form?: FormRef}): Promise<{data: EventsTrainingParticipationListResponse, raw: Response}> {
          const api = new this();
          const response = await api.usersTrainingsParticipationsRaw({ user: user }, options);
          return await response.value();
        }

    }
