<template>
    <PegasusModal header="Gazdasági év létrehozása">
        <PegasusForm ref="form">
            <DatetimeField
                v-model="from"
                :schema="MoneySpendingPeriodStoreSchema.from"
            ></DatetimeField>

            <DatetimeField
                v-model="to"
                :schema="MoneySpendingPeriodStoreSchema.to"
            ></DatetimeField>

            <PegasusButton variant="success" @click="update">
                <i class="fa fa-save"></i> Mentés
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {MoneySpendingPeriodStoreSchema} from '@/api/models';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import PegasusModal from '@Components/base/PegasusModal.vue';
import toast from '@Utils/toast';
import {ref} from 'vue';
import type {FormRef} from '@/vue';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import {DateTime} from '@Utils/DateTime';
import {redirect} from '@/router';
import {useConstantsStore} from '@/stores/constants';
import {useReturn} from '@Utils/dialog';
import {SpendingPeriod} from '@Models/money/SpendingPeriod';

const form = ref<FormRef>(null);

const returnValue = useReturn();

const from = ref(DateTime.now().addYear().startOfYear());
const to = ref(DateTime.now().addYear().endOfYear());

function update() {
    toast.info('Mentés folyamatban');
    SpendingPeriod.store({
        from: from.value,
        to: to.value,
    }, form.value).then(async spendingPeriod => {
        toast.success('Létrehozás sikeres', 'Átirányítás folyamatban');

        await useConstantsStore().init(true);

        await redirect({
            name: 'money.spendingPeriods.show',
            params: {
                year: spendingPeriod.year,
            },
        });

        returnValue(true);
    });
}
</script>
