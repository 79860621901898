/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseValidationBagResponse,
    MapBuildingResponse,
    MapClosureTypeMapsResponse,
    MapFloorResponse,
    MapRoomResponse,
  } from '../models/index';
    import {
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      MapBuildingResponseFromJSON,
      MapBuildingResponseToJSON,
      MapClosureTypeMapsResponseFromJSON,
      MapClosureTypeMapsResponseToJSON,
      MapFloorResponseFromJSON,
      MapFloorResponseToJSON,
      MapRoomResponseFromJSON,
      MapRoomResponseToJSON,
    } from '../models/index';

      export interface CentralMapClosureTypeRequest {
        closureType: number;
      }

      export interface CentralMapFloorRequest {
        floor: number;
      }

      export interface CentralMapRoomByNumberRequest {
        number: string;
      }

      export class CentralApi extends runtime.BaseAPI {

      private async mapBuildingRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<MapBuildingResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/central/map/buildings}`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MapBuildingResponseFromJSON(jsonValue));
      }

        static async mapBuilding(options?: {form?: FormRef}): Promise<{data: MapBuildingResponse, raw: Response}> {
          const api = new this();
          const response = await api.mapBuildingRaw(options);
          return await response.value();
        }

      private async mapClosureTypeRaw(requestParameters: CentralMapClosureTypeRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MapClosureTypeMapsResponse>> {
          if (requestParameters.closureType === null || requestParameters.closureType === undefined) {
          throw new runtime.RequiredError('closureType','Required parameter requestParameters.closureType was null or undefined when calling mapClosureType.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/central/map/closureType/{closureType}`.replace(`{${"closureType"}}`, encodeURIComponent(String(requestParameters.closureType))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MapClosureTypeMapsResponseFromJSON(jsonValue));
      }

        static async mapClosureType(closureType: number, options?: {form?: FormRef}): Promise<{data: MapClosureTypeMapsResponse, raw: Response}> {
          const api = new this();
          const response = await api.mapClosureTypeRaw({ closureType: closureType }, options);
          return await response.value();
        }

      private async mapFloorRaw(requestParameters: CentralMapFloorRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MapFloorResponse>> {
          if (requestParameters.floor === null || requestParameters.floor === undefined) {
          throw new runtime.RequiredError('floor','Required parameter requestParameters.floor was null or undefined when calling mapFloor.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/central/map/floor/{floor}`.replace(`{${"floor"}}`, encodeURIComponent(String(requestParameters.floor))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MapFloorResponseFromJSON(jsonValue));
      }

        static async mapFloor(floor: number, options?: {form?: FormRef}): Promise<{data: MapFloorResponse, raw: Response}> {
          const api = new this();
          const response = await api.mapFloorRaw({ floor: floor }, options);
          return await response.value();
        }

      private async mapRoomByNumberRaw(requestParameters: CentralMapRoomByNumberRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MapRoomResponse>> {
          if (requestParameters.number === null || requestParameters.number === undefined) {
          throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling mapRoomByNumber.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/central/map/room/{number}`.replace(`{${"number"}}`, encodeURIComponent(String(requestParameters.number))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MapRoomResponseFromJSON(jsonValue));
      }

        static async mapRoomByNumber(number: string, options?: {form?: FormRef}): Promise<{data: MapRoomResponse, raw: Response}> {
          const api = new this();
          const response = await api.mapRoomByNumberRaw({ number: number }, options);
          return await response.value();
        }

    }
