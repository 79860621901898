import {Base} from '@Models/Base';
import {User} from '@Models/base/User';
import {
    BaseGlobalPermission,
    BaseGroupSpecificPermission,
    type EventsServiceCategory,
    type EventsServiceConfigResponse,
    EventsServiceType,
} from '@/api/api';
import type Event from '@Models/events/Event';

export class ServiceConfig extends Base {
    id: EventsServiceType;

    name: string;

    groupId: number | null;

    category: EventsServiceCategory;

    hasAssets: boolean;

    clearanceRequired: boolean;

    roomRequired: boolean;

    static parseResponse(config: ServiceConfig, data: EventsServiceConfigResponse): ServiceConfig {
        config.id = data.id;
        config.name = data.name;
        config.groupId = data.groupId;
        config.category = data.category;
        config.hasAssets = data.hasAssets;
        config.clearanceRequired = data.clearanceRequired;
        config.roomRequired = data.roomRequired;

        return config;
    }

    public canRead(user: User): boolean {
        if (this.id === EventsServiceType.KITCHEN_RESERVATION) {
            return user.hasPermission(BaseGlobalPermission.KITCHEN_RESERVATIONS);
        }

        if (this.groupId === null) {
            return user.hasPermission(BaseGlobalPermission.EVENTS_APPROVE);
        }

        return user.hasGroupPermission(this.groupId, [BaseGroupSpecificPermission.READ_SERVICES, BaseGroupSpecificPermission.MANAGE_SERVICES]);
    }

    public canEdit(user: User): boolean {
        if (this.id === EventsServiceType.KITCHEN_RESERVATION) { // TODO
            return user.hasPermission(BaseGlobalPermission.KITCHEN_RESERVATIONS);
        }

        if (this.groupId === null) {
            return user.hasPermission(BaseGlobalPermission.EVENTS_APPROVE);
        }

        return user.hasGroupPermission(this.groupId, BaseGroupSpecificPermission.MANAGE_SERVICES);
    }

    public canAdmin(user: User): boolean {
        if (this.groupId === null) {
            return false;
        }

        return user.hasGroupPermission(this.groupId, BaseGroupSpecificPermission.MANAGE_PERMISSIONS);
    }

    public isAllowedFor(event: Event): boolean {
        if (event.isExternal() && this.roomRequired) {
            return false;
        }

        return true;
    }

    get approvedText(): string {
        if (this.groupId) {
            return 'Kör által jóváhagyva';
        }

        if (this.clearanceRequired) {
            return 'Üzemeltetés által jóváhagyva';
        }

        if (this.id === EventsServiceType.TABLE_SET || this.id === EventsServiceType.EXTRA_TABLE) {
            return 'Visszaigazolva';
        }

        if (this.id === EventsServiceType.CALENDAR_EVENT) {
            return 'Naptárban megjelent';
        }

        return 'Elfogadva';
    }

    get rejectedText(): string {
        if (this.groupId) {
            return 'Kör által elutasítva';
        }

        if (this.clearanceRequired) {
            return 'Üzemeltetés által elutasítva';
        }

        if (this.id === EventsServiceType.TABLE_SET || this.id === EventsServiceType.EXTRA_TABLE) {
            return 'Elutasítva';
        }

        if (this.id === EventsServiceType.CALENDAR_EVENT) {
            return 'Naptár hiba';
        }

        return 'Elutasítva';
    }
}
