<template>
    <PegasusHeading>{{ actual.displayName }}</PegasusHeading>

    <h5>Körök aminek KPR-éhez hozzáférsz</h5>

    <ul>
        <li v-for="access in groups" :key="access.group.id" style="list-style: none">
            <PegasusButton :params="{group: access.group, period: actual.spendingPeriodId}" to="rvt.groups.money">
                {{ access.group.name }}
            </PegasusButton>
        </li>
    </ul>
</template>

<title>KPR</title>

<script lang="ts" setup>
import {useAuthStore} from '@/stores/auth';
import {computed, ref} from 'vue';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {filter, find} from 'lodash-es';
import {BaseGroupSpecificPermission} from '@/api/models';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useKprTenders} from '@/queries/kpr/useKprTenders';

const user = useAuthStore().requireUser();

const {tenders} = await useKprTenders().promise.value;

const actualRaw = find(tenders, tender => tender.hasDrafting());

if (!actualRaw) {
    throw new NotFoundError('Jelenleg nincsen KPR pályázat leadási időszak');
}

const actual = ref(actualRaw);

const groups = computed(() => {
    return filter(user.value.groupAccesses, access => access.hasPermission([
        BaseGroupSpecificPermission.READ_MONEY,
        BaseGroupSpecificPermission.MANAGE_MONEY,
    ]));
});
</script>
