<template>
    <SelectField
        v-model="value"
        :options="dictObject(locations, location => location.longName)"
        :schema="schema"
    ></SelectField>
</template>

<script lang="ts" setup>
import {dictObject} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {useEventLocations} from '@/queries/events/useEventLocations';
import type {Location} from '@Models/events/Location';
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import {watch} from 'vue';

const value = defineModel<Location | undefined>();

const identifier = defineModel<number | undefined>('identifier');

const {schema} = defineProps<{
    schema: FieldSchema
}>();

const {locations} = await useEventLocations().promise.value;

watch(value, newValue => {
    identifier.value = newValue?.id;
});
</script>
