<template>
    <PegasusHeading>VIKÖ admin</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Pályázati kiírások">
                <PegasusTable :data="tenders" :per-page="10">
                    <Column field="displayName" header="Időszak" sortable></Column>

                    <Column :field="item => item.active ? 'Aktív' : 'Archív'" header="Aktív"></Column>

                    <Column column-key="actions" header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton :params="{tender: data}" to="viko.admin.tender" variant="primary">
                                Beállítások
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton variant="success" @click="dialog.open(TenderCreationDialog)">
                    Új létrehozása
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="E-mail sablonok">
                <h5>Körvezető részére</h5>
                <PegasusButton @click="dialog.open(EmailPreviewDialog, {template: 'VikoReportSubmitted'});">
                    Elbírálandó beszámoló érkezett
                </PegasusButton>

                <h5>Pályázó részére</h5>
                <PegasusButton variant="danger" @click="dialog.open(EmailPreviewDialog, {template: 'VikoReportRejected'});">
                    Beszámoló elutasítva
                </PegasusButton>
                <PegasusButton variant="success" @click="dialog.open(EmailPreviewDialog, {template: 'VikoReportApproved'});">
                    Beszámoló jóváhagyva
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>VIKÖ adminisztráció</title>

<script lang="ts" setup>
import {Tender} from "@Models/viko/Tender";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useDialog} from '@Utils/dialog';
import TenderCreationDialog from '@Components/viko/tender/TenderCreationDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import EmailPreviewDialog from '@Components/system/emails/EmailPreviewDialog.vue';
import {useAuthStore} from '@/stores/auth';
import {BaseGlobalPermission} from '@/api/models';
import {AccessDeniedError} from '@Utils/errors/AccessDeniedError';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.VIKO_TENDER)) {
    throw new AccessDeniedError('Csak az RVT elnöknek van jogosultsága');
}

const dialog = useDialog();

const tenders = await Tender.getAll();
</script>
