<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <Dropdown
                    v-model="modelValue"

                    :class="{'p-invalid': isValid === false}"
                    :disabled="disabled"
                    :input-id="id"
                    :options="options"
                    :show-clear="clearable"
                    class="w-100"

                    filter
                    option-label="label"
                    option-value="value"
                    placeholder=""
                ></Dropdown>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import SmartField from '@Components/base/form/SmartField.vue';
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import {filter, map, sortBy, values} from 'lodash-es';
import type {Room} from '@Models/map/Room';
import {MapRoomType} from '@/api/models';
import Dropdown from 'primevue/dropdown';
import {useMapBuilding} from '@/queries/central/useMapBuilding';

const modelValue = defineModel<Room | null>({required: true});

const {
    allowedTypes = values(MapRoomType),
    withoutGroup,
    disabled,
    schema,
    inline,
    initialValue,
} = defineProps<{
    allowedTypes?: MapRoomType[]
    disabled?: boolean;
    clearable?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
    initialValue?: string
}>();

const building = await useMapBuilding().promise.value;

let rooms = filter(building.getAllRooms(), room => allowedTypes.includes(room.type));

rooms = sortBy(rooms, room => room.displayNumber);

const options = map(rooms, room => {
    return {
        value: room,
        label: room.displayNumber,
    };
});

if (initialValue && initialValue.length > 0) {
    setByRoomNumber(initialValue);
}

function setByRoomNumber(roomNumber: string) {
    const index = options.findIndex(option => option.value.displayNumber === roomNumber);

    if (index) {
        modelValue.value = options[index].value;
    }
}
</script>
