<template>
    <div class="wrapper">
        <Navbar>
            <template #button>
                <PegasusLink class="navbar-brand" to="rvt.index">
                    <img alt="rvt.sch" height="30px" src="/src/assets/images/logo/navbar/rvt.svg" style="color: white"/>
                </PegasusLink>
            </template>

            <template v-if="auth.user" #navbar-extra>
                <li v-if="auth.user.hasPermission(BaseGlobalPermission.RVT)" class="nav-item">
                    <PegasusLink class="nav-link" to="rvt.main">RVT aktuális</PegasusLink>
                </li>

                <li
                    v-if="auth.user.hasPermission([BaseGlobalPermission.RVT, BaseGlobalPermission.MONEY])"
                    class="nav-item"
                >
                    <PegasusLink class="nav-link" to="rvt.spendingPeriods.index">Költési időszakok</PegasusLink>
                </li>

                <template v-if="auth.user.hasPermission(BaseGlobalPermission.KPR)">
                    <li v-for="tender in relevantTenders" :key="tender.id" class="nav-item">
                        <PegasusLink :params="{tender}" class="nav-link" to="rvt.kpr.tender.show">
                            {{ tender.displayName }}
                        </PegasusLink>
                    </li>
                </template>

                <li v-for="resort in auth.user.resortLeaderships" :key="resort.id">
                    <PegasusLink :params="{group: resort.id}" class="nav-link" to="rvt.resorts.show">
                        Reszort adatlap
                    </PegasusLink>
                </li>
            </template>
        </Navbar>

        <MainContent></MainContent>

        <CommonFooter></CommonFooter>
    </div>
</template>

<script lang="ts" setup>
import Navbar from '@Pages/Navbar.vue';
import CommonFooter from '@Pages/CommonFooter.vue';
import {BaseGlobalPermission} from '@/api/models';
import PegasusLink from '@Components/base/PegasusLink.vue';
import MainContent from '@Components/MainContent.vue';
import {useAuthStore} from '@/stores/auth';
import {useKprTenders} from '@/queries/kpr/useKprTenders';

useAuthStore().requireUser();

const tendersStore = await useKprTenders().promise.value;

const relevantTenders = tendersStore.getRelevant();

const auth = useAuthStore();
</script>
