import {DateTime} from '@Utils/DateTime';
import {BaseEntity} from '@Models/BaseEntity';
import {File} from '@Models/File';
import PublicEvent from '@Models/events/PublicEvent';
import type {EventsServiceResponse, EventsServiceResponseData, EventsServiceResponseDataSimple} from '@/api/api';
import {
    EventsApi,
    EventsServiceResponseDataCreateEmptyByType,
    type EventsServiceStore,
    EventsServiceType,
} from '@/api/api';
import type {FormRef} from '@/vue';
import type {ServiceConfig} from '@Models/events/ServiceConfig';

export class Service extends BaseEntity {
    type: EventsServiceType;

    data: EventsServiceResponseDataSimple;

    comment: string;

    finalized: DateTime | null;

    notified: boolean;

    decision: boolean | null;

    decisionTime: DateTime | null;

    event: PublicEvent;

    remoteId: number | null;

    canEdit: boolean;

    content: string | null;

    createdAt: DateTime;

    static waiting(): Promise<Record<number, Service>> {
        return EventsApi.servicesWaitingClearance().then(response => {
            return Service.newRecords(response.data.services, Service.parseResponse);
        });
    }

    static waitingAck(): Promise<Record<number, Service>> {
        return EventsApi.servicesWaitingAck().then(response => {
            return Service.newRecords(response.data.services, Service.parseResponse);
        });
    }

    static upcoming(type: EventsServiceType): Promise<Record<number, Service>> {
        return EventsApi.serviceManagementUpcoming(type).then(response => {
            return Service.newRecords(response.data.services, Service.parseResponse);
        });
    }

    static pending(type: EventsServiceType): Promise<Record<number, Service>> {
        return EventsApi.serviceManagementPending(type).then(response => {
            return Service.newRecords(response.data.services, Service.parseResponse);
        });
    }

    static parseResponse(service: Service, data: EventsServiceResponse): Service {
        service.id = data.id;
        service.type = data.type;
        service.data = data.data;
        service.comment = data.comment;
        service.finalized = data.finalized;
        service.notified = data.notified;
        service.decision = data.decision;
        service.decisionTime = data.decisionTime;
        service.remoteId = data.remoteId;
        service.event = PublicEvent.newSingle(data.event, PublicEvent.parseResponse);
        service.canEdit = data.canEdit;
        service.content = data.content;
        service.createdAt = data.createdAt;

        return service;
    }

    static createEmptyForType(type: EventsServiceType, event: PublicEvent): Service {
        const service = new Service();

        service.type = type;
        service.data = EventsServiceResponseDataCreateEmptyByType(type);
        if ('from' in service.data) {
            service.data.from = event.start;
        }
        if ('to' in service.data) {
            service.data.to = event.finish;
        }

        service.comment = '';
        service.event = event;
        service.finalized = null;
        service.canEdit = true;

        return service;
    }

    static createServicesArray(data: EventsServiceResponse[]): Record<EventsServiceType, Service> {
        const result: Record<EventsServiceType, Service> = {} as Record<EventsServiceType, Service>;

        for (const on in data) {
            result[data[on].type] = this.parseResponse(new Service(), data[on]);
        }

        return result;
    }

    public store(data: EventsServiceResponseDataSimple, comment: string, form: FormRef): Promise<void> {
        const wireData = data as EventsServiceResponseData;
        wireData.serviceType = this.type;

        const request: EventsServiceStore = {
            event: this.event.id,
            type: this.type,
            data: wireData,
            comment: comment,
        };

        return EventsApi.servicesStore(request, {form}).then(response => {
            Service.parseResponse(this, response.data);
        });
    }

    public update(data: EventsServiceResponseDataSimple, comment: string, form: FormRef): Promise<void> {
        const wireData = data as EventsServiceResponseData;
        wireData.serviceType = this.type;

        return EventsApi.servicesUpdate(this.id, {
            data: wireData,
            type: this.type,
            comment,
        }, {form}).then(response => {
            Service.parseResponse(this, response.data);
        });
    }

    public send(form: FormRef): Promise<void> {
        return EventsApi.servicesSend(this.id, {form}).then(response => {
            Service.parseResponse(this, response.data);
        });
    }

    public decide(decision: boolean): Promise<void> {
        return EventsApi.servicesDecide(this.id, {decision: decision}).then(response => {
            Service.parseResponse(this, response.data);
        });
    }

    public remote(remoteId: number): Promise<void> {
        return EventsApi.servicesRemote(this.id, {remoteId: remoteId}).then(response => {
            Service.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return EventsApi.servicesDestroy(this.id).then();
    }

    public exportAsset(): Promise<File> {
        return EventsApi.servicesExport(this.id).then(response => File.createFromResponse(response));
    }

    public getStatus(config: ServiceConfig): string {
        if (!this.createdAt) {
            return 'Mentésre vár';
        }

        if (!this.finalized) {
            return 'Szerkesztés alatt, véglegesítésre vár';
        }

        if (this.decision !== null) {
            return this.decision
                ? config.approvedText
                : config.rejectedText;
        }

        if (config.groupId) {
            if (this.notified) {
                return 'Kör értesítve, visszaigazolásra vár';
            } else {
                return 'Kör értesítése folyamatban';
            }
        }

        if (config.clearanceRequired) {
            return 'Üzemeltetési jóváhagyásra vár';
        }

        if (this.type === EventsServiceType.TABLE_SET || this.type === EventsServiceType.EXTRA_TABLE) {
            return 'Ellenőrzésre vár';
        }

        if (this.type === EventsServiceType.CALENDAR_EVENT) {
            if (this.event.decision === null) {
                return 'Rendezvény elfogadására vár';
            }

            return 'Naptár feldolgozásra vár';
        }

        return '';
    }
}
