import {Base} from '@Models/Base';
import {Group} from '@Models/base/Group';
import {InventoryApi, type InventoryGroupStatisticsResponse} from '@/api/api';
import {map} from 'lodash-es';
import {Room} from '@Models/map/Room';

export type TagCount = {
    tagId: number;

    itemCount: number;

    sumPrice: number;
}

export type RoomCount = {
    room: Room;

    itemCount: number;

    sumPrice: number;
}

export type OwnerCount = {
    ownerId: number;

    itemCount: number;

    sumPrice: number;
}

export class InventoryGroupStatistics extends Base {
    group: Group;

    itemsWithoutGroup: number;

    tagCounts: TagCount[];

    roomStatistics: RoomCount[];

    ownerCounts: OwnerCount[];

    static parseResponse(summary: InventoryGroupStatistics, response: InventoryGroupStatisticsResponse): InventoryGroupStatistics {
        summary.group = Group.getBySingleId(response.groupId);

        summary.tagCounts = map(response.tagCounts, tagCount => {
            return {
                ...tagCount,
                tagId: tagCount.countedById,
            };
        });

        summary.roomStatistics = map(response.roomStatistics, data => {
            return {
                ...data,
                room: Room.newSingle(data.room, Room.parseResponse),
            };
        });

        summary.ownerCounts = map(response.ownerCounts, ownerCount => {
            return {
                ...ownerCount,
                ownerId: ownerCount.countedById,
            };
        });

        return summary;
    }

    static getByGroup(groupId: number): Promise<InventoryGroupStatistics> {
        return InventoryApi.groupsStatistics(groupId).then(response => {
            return InventoryGroupStatistics.newSingle(response.data, this.parseResponse);
        });
    }
}
