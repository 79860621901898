import {useQuery} from '@tanstack/vue-query';
import {InventoryApi} from '@/api/apis/index';
import {Owner} from '@Models/inventory/Owner';

export function useInventoryOwners() {
    return useQuery({
        staleTime: 60 * 60 * 1000, // 1 hour
        queryKey: ['inventory.owners'],
        queryFn: async (): Promise<Record<number, Owner>> => {
            const {data} = await InventoryApi.ownersAll();

            return Owner.newRecords(data.owners, Owner.parseResponse);
        },
    });
}
