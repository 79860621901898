import {Base} from "@Models/Base";
import {Group} from "@Models/base/Group";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {Tender} from "@Models/rvt/kpr/Tender";
import {Application} from "@Models/rvt/kpr/Application";
import {User} from "@Models/base/User";
import type {BaseGroupSummaryResponse} from "@/api/api";
import {MainApi} from "@/api/api";
import {map} from "lodash-es";
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';

export class GroupSummary extends Base {
    group: Group;

    periods: {
        period: SpendingPeriod,
        waitingClaims: number | null,
        pendingClaims: number | null,
        tender: Tender | null,
        application: Application | null,
        waitingAmends: number | null,
        pendingAmends: number | null,
    }[];

    viko: {
        name: string,
        waitingReports: number,
        doneReports: number,
    } | null = null;

    events: {
        drafting: number,
        waiting: number,
        future: number,
    } | null = null;

    inventory: InventoryGroupSummary | null;

    leader: User | null;

    money: User | null;

    static getById(id: number): Promise<GroupSummary> {
        return MainApi.groupsSummary(id).then(response => {
            return GroupSummary.newSingle(response.data, GroupSummary.parseResponse);
        });
    }

    static parseResponse(summary: GroupSummary, data: BaseGroupSummaryResponse): GroupSummary {
        summary.group = Group.getBySingleId(data.groupId);

        summary.periods = map(data.periods, periodData => {
            return {
                period: new SpendingPeriod(periodData.period),
                waitingClaims: periodData.waitingClaims,
                pendingClaims: periodData.pendingClaims,
                tender: periodData.tender ? new Tender(periodData.tender) : null,
                application: Application.newSingleNullable(periodData.application, Application.parseWithItems),
                waitingAmends: periodData.waitingAmends,
                pendingAmends: periodData.pendingAmends,
            };
        });

        if (data.viko) {
            summary.viko = {
                name: data.viko.tenderName,
                waitingReports: data.viko.waitingReports,
                doneReports: data.viko.doneReports,
            };
        }

        if (data.events) {
            summary.events = {
                drafting: data.events.drafting,
                waiting: data.events.waiting,
                future: data.events.future,
            };
        }

        if (data.inventory) {
            summary.inventory = InventoryGroupSummary.newSingle(data.inventory, InventoryGroupSummary.parseResponse);
        }

        summary.leader = User.newSingleNullable(data.leader, User.parseMinimalResponse);
        summary.money = User.newSingleNullable(data.money, User.parseMinimalResponse);

        return summary;
    }
}
