<template>
    <PegasusHeading>SGR Admin</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Gazdasági évek">
                <PegasusTable :data="periods" :per-page="5" default-sort="year">
                    <Column field="year" header="Év" sortable></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: SpendingPeriod}">
                            <PegasusButton :params="{year: data.year}" to="money.spendingPeriods.show">
                                <i class="fa fa-gears"></i> Adminisztráció
                            </PegasusButton>

                            <PegasusButton :params="{year: data.year}" to="money.budget.year.show" variant="success">
                                <i class="fa fa-money-bill"></i> Házszintű
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
                <PegasusButton @click="newPeriod">
                    <i class="fa fa-plus"></i> Új gazdasági év
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Folyamatban lévő költségvetések">
                <PegasusTable :data="programs" :per-page="5">
                    <Column field="name" header="Név" sortable></Column>
                    <Column field="statusText" header="Állapot" sortable></Column>

                    <Column header="Műveletek">
                        <template #body="{data}: {data: BudgetProgram}">
                            <PegasusButton :params="{program: data}" to="money.budget.program.show">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>SGR Admin</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import Column from 'primevue/column';
import PegasusButton from '@Components/base/PegasusButton.vue';
import type {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {useDialog} from '@Utils/dialog';
import SpendingPeriodCreateDialog from '@/components/money/spendingPeriod/SpendingPeriodCreateDialog.vue';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import {ref} from 'vue';
import {useAuthStore} from '@/stores/auth';
import {useSpendingPeriods} from '@/queries/money/useSpendingPeriods';

useAuthStore().requireUser();

const dialog = useDialog();

const {periods} = await useSpendingPeriods().promise.value;

const programs = ref(await BudgetProgram.getRelevant());

function newPeriod() {
    dialog.open(SpendingPeriodCreateDialog);
}
</script>
