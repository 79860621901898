/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { VikoTenderSummaryResponse } from './VikoTenderSummaryResponse';
    import {
    VikoTenderSummaryResponseFromJSON,
    VikoTenderSummaryResponseFromJSONTyped,
    VikoTenderSummaryResponseToJSON,
    VikoTenderSummaryResponseCreateEmpty,
    } from './VikoTenderSummaryResponse';
import {DateTime} from '@Utils/DateTime';

export interface VikoOverviewStatisticsResponse {
  tenders: Array<VikoTenderSummaryResponse>;
}

export const VikoOverviewStatisticsResponseSchema = {
  'tenders': {
    name: 'tenders',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfVikoOverviewStatisticsResponse(value: object): value is VikoOverviewStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "tenders" in value;

return isInstance;
}

export function VikoOverviewStatisticsResponseFromJSON(json: any): VikoOverviewStatisticsResponse {
return VikoOverviewStatisticsResponseFromJSONTyped(json, false);
}

export function VikoOverviewStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoOverviewStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'tenders': ((json['tenders'] as Array<any>).map(VikoTenderSummaryResponseFromJSON)),
    };
  }

  export function VikoOverviewStatisticsResponseToJSON(value?: VikoOverviewStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'tenders': ((value.tenders as Array<any>).map(VikoTenderSummaryResponseToJSON)),
    };
  }

  export function VikoOverviewStatisticsResponseCreateEmpty(): VikoOverviewStatisticsResponse {
  return {
      'tenders': [],
  };
  }

